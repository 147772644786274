import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  InputAdornment,
  CircularProgress,
  Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import { UserListToolbar, UserMoreMenu } from '../../../sections/@dashboard/user';
import { addEditApi, listApi } from 'src/apis/admin';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { APIs } from '../../../apis';
import { addEditPartnerSchema } from 'src/utils/validationSchemas';
import { useFormik, Form, FormikProvider } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import React from 'react';
import { DATE_FORMAT_TIME } from 'src/contants/contants';
import AsyncSelect from 'react-select/async';
import MoreMenu from 'src/components/table/MoreMenu';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';

//Styles for filter //
const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 56,
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

const TABLE_HEAD = [
  { id: '' },
  { id: 'user', label: 'User Data', alignRight: false },
  { id: 'reward', label: 'Reward Data', alignRight: false },
  { id: 'contest', label: 'Contest Data', alignRight: false },
  { id: 'redeem', label: 'Is Redeem', alignRight: false },
  { id: 'actionon', label: 'Action On', alignRight: false },
  { id: 'assignedon', label: 'Assigned On', alignRight: false },
  { id: 'expired', label: 'Expired On', alignRight: false },
];

export default function UserWallet() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [openModal, setModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userFilterSelect, setUserFilterSelect] = useState();
  const [contestFilterSelect, setContestFilterSelect] = useState();
  const user = useSelector((state) => state.auth);

  useEffect(() => {
    setLoading(true);

    let data = {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      search: searchFilter,
    };
    if (userFilterSelect) {
      data.userId = userFilterSelect?.value;
    }
    if (contestFilterSelect) {
      data.contestId = contestFilterSelect?.value;
    }

    listApi(APIs.LIST_WALLET_USERS, { ...data })
      .then((res) => {
        setUsers(res?.data);
        setTotalCount(res?.count);
      })
      .finally(() => setLoading(false));
  }, [refresh, rowsPerPage, searchFilter, success, page, userFilterSelect, contestFilterSelect]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  const handleClose = (_, reason) => {
    if (reason !== 'backdropClick') {
      setModalOpen(false);
    }
  };

  const deleteUsersWallet = (row) => {
    const deleteData = {
      id: row.id,
      type: 6,
    };
    confirmAlert({
      title: 'Confirm for Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            addEditApi(APIs.DELETE_REQUEST, deleteData).then((result) => {
              setSuccess((prev) => !prev);
              toast.success('Deleted Successfully');
            });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };
  const handleFormSubmit = (data, { resetForm }) => {
    addEditApi(APIs.ADD_EDIT_PARTNER, {
      ...data,
      phoneNumber: data?.phoneNumber.slice(data?.countryCode?.length),
    }).then(() => {
      resetForm();
      setModalOpen(false);
      setRefresh((prev) => !prev);
    });
  };

  const formik = useFormik({
    initialValues: {
      emailId: '',
      password: '',
      name: '',
      phoneNumber: '',
      countryCode: '',
      address: '',
    },
    validationSchema: addEditPartnerSchema,
    onSubmit: handleFormSubmit,
  });
  const { errors, touched, values, setFieldValue, handleSubmit, getFieldProps } = formik;

  /*User Filter Api  */

  const promiseUserDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_USERS, { search: inputValue, sendOnlyActive: true }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={item?.name} src={item?.profileImg} />
            <Typography variant="subtitle2" noWrap>
              {item?.name}
            </Typography>
          </Stack>
        ),
        value: item?.id,
      }));
      resolve(filter);
    });
  };

  const loadUserData = debounce(promiseUserDataOptions, 300);

  /*Contest Filter Api  */

  const promiseContestDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_CONTESTS, { search: inputValue, sendOnlyActive: true }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: (
          <Stack direction="row" alignItems="center" spacing={3}>
            <Avatar alt={item?.name} src={item?.imageLink} />
            <Typography variant="subtitle2" noWrap>
              {item?.name} | {moment(item.startDateTime).format(DATE_FORMAT_TIME)}
            </Typography>
          </Stack>
        ),
        value: item?.id,
      }));
      resolve(filter);
    });
  };
  const handleEditPermission =
    user && user.roles && user.roles.some((role) => role?.title === 'Users list' && role?.edit === true);

  const isSuperAdmin = user?.isSuperAdmin;

  const loadContestData = debounce(promiseContestDataOptions, 300);

  return (
    <Page title="Users Wallet">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users Wallet
          </Typography>
        </Stack>

        <Card>
          <Grid container spacing={2} padding={1}>
            <Grid>
              <UserListToolbar filterName={search} onFilterName={handleSearch} />
            </Grid>
            <Grid xs={12} md={3.5} style={{ paddingTop: '20px' }}>
              <AsyncSelect
                isClearable
                cacheOptions
                defaultOptions
                placeholder="Filter by user"
                styles={customStyles}
                loadOptions={loadUserData}
                value={userFilterSelect}
                onChange={(data) => {
                  setUserFilterSelect(data);
                }}
              />
            </Grid>

            <Grid xs={12} md={3.5} style={{ paddingTop: '20px', marginLeft: '10px' }}>
              <AsyncSelect
                isClearable
                cacheOptions
                defaultOptions
                placeholder="Filter by contest"
                styles={customStyles}
                loadOptions={loadContestData}
                value={contestFilterSelect}
                onChange={(data) => {
                  setContestFilterSelect(data);
                }}
              />
            </Grid>
          </Grid>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {TABLE_HEAD.map((headCell) => (
                          <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                            <TableSortLabel hideSortIcon active={false} direction={'asc'}>
                              {headCell.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {users.map((row, index) => {
                        const { id, userData, contestData, rewardData, createdAt, actionDate, action, expiryDate } =
                          row;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox">
                            <TableCell align="left">
                              {(handleEditPermission || isSuperAdmin) && (
                                <MoreMenu
                                  options={[
                                    {
                                      label: 'Delete',
                                      icon: 'eva:trash-2-outline',
                                      onClick: () => deleteUsersWallet(row),
                                    },
                                  ]}
                                />
                              )}
                            </TableCell>
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={name} src={userData?.profileImg} />
                                <div>
                                  <p>
                                    <Typography variant="subtitle2" noWrap>
                                      {userData?.name}
                                    </Typography>
                                  </p>
                                  <p>
                                    <Typography variant="subtitle2" noWrap>
                                      {userData?.emailId}
                                    </Typography>
                                  </p>
                                </div>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={name} src={rewardData?.imageLink} />
                                <div>
                                  <p>
                                    <Typography variant="subtitle2" noWrap>
                                      {rewardData?.title}
                                    </Typography>
                                  </p>
                                  <p> ${rewardData?.price}</p>
                                </div>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={name} src={contestData?.imageLink} />
                                <Typography variant="subtitle2" noWrap>
                                  {contestData?.name} | {moment(contestData.startDateTime).format(DATE_FORMAT_TIME)}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">{action === null ? 'false' : 'true'}</TableCell>
                            <TableCell align="left">
                              {actionDate ? moment(actionDate).format(DATE_FORMAT_TIME) : ''}
                            </TableCell>
                            <TableCell align="left">{moment(createdAt).format(DATE_FORMAT_TIME)}</TableCell>
                            <TableCell align="left">{moment(expiryDate).format(DATE_FORMAT_TIME)}</TableCell>

                            <TableCell align="right">{/*<UserMoreMenu />*/}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {totalCount === 0 && (
                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                      <Typography gutterBottom>No Data Found</Typography>
                    </div>
                  )}
                </>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>

      <Dialog open={openModal} onClose={handleClose} fullWidth>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>Add New Partner</DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                margin="normal"
                label="Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />

              <TextField
                fullWidth
                margin="normal"
                label="Email address"
                {...getFieldProps('emailId')}
                error={Boolean(touched.emailId && errors.emailId)}
                helperText={touched.emailId && errors.emailId}
              />

              <PhoneInput
                value={values?.phoneNumber}
                onChange={(value, data) => {
                  setFieldValue('phoneNumber', value);
                  setFieldValue('countryCode', data?.dialCode);
                }}
                country={'us'}
                isValid={!(touched.phoneNumber && errors.phoneNumber)}
                inputStyle={{ width: '100%' }}
                containerStyle={{ marginTop: '20px', marginBottom: '10px' }}
              />

              <TextField
                fullWidth
                margin="normal"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />

              <TextField
                fullWidth
                margin="normal"
                label="Address"
                {...getFieldProps('address')}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>

              <LoadingButton type="submit" variant="contained" loading={loading}>
                Save Partner
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </Page>
  );
}
