import { Dialog } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ImageModal = ({ openModal, closeModal, img }) => {
  return (
    <Dialog open={openModal}>
      <div>
        <div>
          <img src={img} />
        </div>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            bgcolor: '#00000020',
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.common.white,
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </Dialog>
  );
};

export default ImageModal;
