import React, { useState, useMemo } from 'react';
import './index.css';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  CircularProgress,
  IconButton,
  Collapse,
  Box,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { listApi } from 'src/apis/admin';
import { APIs } from 'src/apis/client';
import { gameQuizListTableHeading } from 'src/contants/contants';
import { confirmAlert } from 'react-confirm-alert';
import { addEditApi } from 'src/apis/admin';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const QuizList = ({ closeModal, selectedGame }) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState();
  const [selected, setSelected] = useState([]);
  const [timeInterval, setTimeInterval] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState('');
  const statusContest = (row) => {
    confirmAlert({
      title: 'Confirm for Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            addEditApi(APIs.DELETE_REQUEST, { id: row?.id, type: 7 }).then(() => {
              toast.success('Deleted Successfully');
              setRefresh((prev) => !prev);
            });
          },
        },
        {
          label: 'No',
        },
      ],
      overlayClassName: 'overlayCustomClassName',
    });
  };

  const noDataHandler = () => {
    setTimeout(() => {
      setTimeInterval(true);
    }, 2000);
  };

  ///Getting Particular Contest Winner List/////////

  useMemo(() => {
    setLoading(true);
    listApi(APIs.QUIZ_LIST, { gameId: selectedGame?.gameId })
      .then((result) => {
        setList(result?.data);
      })
      .finally(setLoading(false));
  }, [refresh]);

  return (
    <div className="modalQuizContainer">
      <Container className="modalInnerContainer">
        <div className="modalheadingQuizRow">
          <Typography variant="h4" gutterBottom>
            {selectedGame?.gameName} - {selectedGame?.startTime}
          </Typography>
          <Typography variant="h4" gutterBottom>
            <Button onClick={closeModal} style={{ marginRight: '10px', marginBottom: '20px' }}>
              Cancel
            </Button>
          </Typography>
        </div>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 500 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {gameQuizListTableHeading.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {!!list?.length > 0 ? (
                    <TableBody>
                      {list?.map((row, index) => {
                        const { id, innings, createdAt, additionalData } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <>
                            <TableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell>
                                <Button
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    statusContest(row);
                                  }}
                                  align="right"
                                  // variant="outlined"
                                  color="error"
                                  startIcon={<DeleteIcon />}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                              <TableCell align="left">{innings}</TableCell>
                              <TableCell align="left">
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => {
                                    setOpen(!open);
                                    setOpenIndex(index);
                                  }}
                                >
                                  {open && openIndex === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                              </TableCell>

                              <TableCell align="left">{createdAt}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} key={index}>
                                <Collapse in={open && openIndex === index} timeout="auto" unmountOnExit>
                                  <Box sx={{ margin: 1 }}>
                                    <Typography variant="h6" gutterBottom component="div">
                                      Additional Data
                                    </Typography>
                                    <Table size="small" aria-label="purchases">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Id</TableCell>
                                          <TableCell>Questions</TableCell>
                                          <TableCell align="left">Options</TableCell>
                                          <TableCell align="right">Points</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {additionalData?.map((questionData, dataIndex) => (
                                          <TableRow key={dataIndex}>
                                            <TableCell>{questionData?.id}</TableCell>
                                            <TableCell component="th" scope="row">
                                              {questionData?.question}
                                            </TableCell>

                                            <TableCell align="left">
                                              {questionData?.options?.map((option) => (
                                                <p>"{option}" </p>
                                              ))}
                                            </TableCell>
                                            <TableCell align="right">{questionData?.points}</TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  ) : (
                    ''
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!!list?.length == 0 && (
          <Typography variant="subtitle2" align="center" style={{ marginTop: '20px', marginBottom: '90px' }}>
            {noDataHandler()}
            {!timeInterval ? 'Loading...' : 'No Data Found'}
          </Typography>
        )}
        <div className="modalWinnnerFooterContainer">
          <Typography variant="h4" gutterBottom>
            <Button onClick={closeModal} style={{ marginRight: '10px', marginBottom: '20px' }}>
              Cancel
            </Button>
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default QuizList;
