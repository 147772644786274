import React, { Fragment, useEffect, useState } from 'react';
import { APIs } from 'src/apis';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { sendEmailFollowupSchema } from 'src/utils/validationSchemas';
import { addEditApi } from 'src/apis/admin';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

const initialValues = {
  emailBody: '',
  sendAll: false,
  sendContestUserNotifications: false,
};

const EmailModal = ({ closeModal, partnerId }) => {
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (data, { resetForm }) => {
    setLoading(true);
    addEditApi(APIs.FOLLOW_PARTNER_EMAIL, { partnerId: partnerId?.id, ...data })
      .then(() => {
        toast.success('Sent Successfully'), closeModal();
      })
      .finally(setLoading(false));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: sendEmailFollowupSchema,
    onSubmit: handleFormSubmit,
  });
  const { errors, touched, values, setFieldValue, handleSubmit, getFieldProps } = formik;
  
  function toggleSwitch1() {
    values.sendAll = !values.sendAll;
    if (values.sendAll) {
      values.sendContestUserNotifications = false; // If switch1 is turned on, switch2 is turned off
    }
  }
  function toggleSwitch2() {
    values.sendContestUserNotifications = !values.sendContestUserNotifications;
    if (values.sendContestUserNotifications) {
      values.sendAll = false; // If switch2 is turned on, switch1 is turned off
    }
  }
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography variant="h4" gutterBottom>
            Send Follow Email - {partnerId?.name}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ width: 1100 }}>
          <Card>
            <Box sx={{ flexGrow: 1, padding: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="Email Body" {...getFieldProps('emailBody')} />
                </Grid>
                <Grid item xs={12} md={12} style={{ display: 'flex', marginTop: '20px', marginBottom: '20px' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values?.sendAll}
                        onChange={(date) => {
                          toggleSwitch1();
                          setFieldValue('sendAll', date?.target?.checked);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Send Notifications [All]"
                    error={Boolean(touched.sendAll && errors.sendAll)}
                    helperText={touched.sendAll && errors.sendAll}
                  />
                  <FormControlLabel
                    style={{ marginLeft: '40px' }}
                    control={
                      <Switch
                        checked={values?.sendContestUserNotifications}
                        onChange={(date) => {
                          toggleSwitch2();
                          setFieldValue('sendContestUserNotifications', date?.target?.checked);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Send Notifications [Partner]"
                    error={Boolean(touched.sendContestUserNotifications && errors.sendContestUserNotifications)}
                    helperText={touched.sendContestUserNotifications && errors.sendContestUserNotifications}
                  />
                </Grid>
                {touched.sendContestUserNotifications &&
                  Boolean(touched.sendContestUserNotifications && errors.sendContestUserNotifications) && (
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      color={'red'}
                      paddingTop={1}
                      paddingLeft={2}
                    >
                      *{errors?.sendContestUserNotifications}
                    </Typography>
                  )}
              </Grid>
            </Box>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={() => closeModal()}>
            Cancel
          </Button>

          <LoadingButton size="large" type="submit" variant="contained" loading={loading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
};

export default EmailModal;
