import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  CircularProgress,
  Grid,
  Button,
  Avatar,
  Dialog,
} from '@mui/material';
import Select from 'react-select';

import { listApi } from 'src/apis/admin';
import debounce from 'lodash/debounce';

import 'react-phone-input-2/lib/material.css';
import MoreMenu from 'src/components/table/MoreMenu';
import { TEAMS_TABLE_HEAD } from 'src/contants/contants';

import Iconify from 'src/components/Iconify';
import { APIs } from 'src/apis';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import Scrollbar from 'src/components/Scrollbar';
import Page from 'src/components/Page';
import TeamModal from './teamModal';
import ImageModal from 'src/pages/openAI/questionBankList/imageModal';
import { useSelector } from 'react-redux';
// import teamModal from './teamModal';
export default function TeamsPage() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [teams, setTeams] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();
  const [leagueData, setLeagueData] = useState();
  const [leagues, setLeagues] = useState();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);

  const user = useSelector((state) => state.auth);

  const handleEditPermission =
    user && user.roles && user.roles.some((role) => role?.title === 'Games List' && role?.edit === true);

  const isSuperAdmin = user?.isSuperAdmin;

  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 55,
        border: '',
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };

  useEffect(() => {
    listApi(APIs.LIST_LEAGUES).then((result) => {
      setLeagues(result);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    const ApiPayload = {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      search: searchFilter,
      fromAdmin: true,
    };

    if (leagueData) ApiPayload.leagueId = leagueData;

    listApi(APIs.LIST_TEAMS, ApiPayload)
      .then((res) => {
        setTeams(res?.data);
        setTotalCount(res?.count);
      })
      .finally(() => setLoading(false));
  }, [refresh, rowsPerPage, searchFilter, page, leagueData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };
  const closeModal = () => {
    setSelectedTeam({});
    setOpenModal(false);
  };

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };

  return (
    <Page title="Games">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Teams
          </Typography>
          <Button
            variant="contained"
            // component={RouterLink}
            onClick={() => setOpenModal(true)}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Team
          </Button>
        </Stack>

        <Card>
          <Grid container spacing={2} columns={16} alignItems="center">
            <Grid item xs={5}>
              <UserListToolbar filterName={search} onFilterName={handleSearch} />
            </Grid>
            <Grid item xs={10} md={4}>
              <Select
                cacheOptions
                defaultOptions
                placeholder="Select League"
                styles={customStyles}
                options={leagues}
                name="leagueId"
                getOptionLabel={(v) => v?.leagueName}
                getOptionValue={(v) => v?.id}
                onChange={(data) => setLeagueData(data?.id)}
                isClearable
              />
            </Grid>
          </Grid>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {TEAMS_TABLE_HEAD.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          <TableSortLabel style={{ width: '100px' }} hideSortIcon active={false} direction={'asc'}>
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {teams?.map((row, index) => {
                      const { id, teamName, teamAlias, market, image_url, hash_tag, League } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                          <TableCell align="left">
                            {(handleEditPermission || isSuperAdmin) && (
                              <MoreMenu
                                options={[
                                  {
                                    label: 'Edit',
                                    icon: 'eva:edit-fill',
                                    onClick: () => {
                                      setSelectedTeam({
                                        id,
                                        teamName,
                                        teamAlias,
                                        image_url,
                                        hash_tag,
                                        League,
                                      }),
                                        setOpenModal(true);
                                    },
                                  },
                                ]}
                              />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar
                                alt={teamName}
                                src={image_url}
                                onClick={() => showFullImage(image_url)}
                                style={{ cursor: 'pointer', objectFit: 'contain' }}
                              />
                              <Typography variant="subtitle2" noWrap>
                                {teamName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{League?.leagueName}</TableCell>
                          <TableCell align="left">{teamAlias}</TableCell>
                          <TableCell align="left">{hash_tag}</TableCell>
                          <TableCell align="left">{market || '-'}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>
      <Dialog open={openModal} fullWidth>
        <TeamModal closeModal={closeModal} selectedTeam={selectedTeam} setRefresh={setRefresh} />
      </Dialog>
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </Page>
  );
}
