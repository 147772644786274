import React, { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import AsyncSelect from 'react-select/async';
import Iconify from '../../../components/Iconify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { APIs } from '../../../apis';
import debounce from 'lodash/debounce';
import { addEditApi, listApi } from 'src/apis/admin';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { editRewardRulesSchema } from 'src/utils/validationSchemas';
import { toast } from 'react-toastify';
import { handleRewardType } from '../../../utils/helper';
import {
  Typography,
  TextField,
  Box,
  Grid,
  Button,
} from '@mui/material';

import './index.css';

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 55,
      border: '',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};
const initialValues = {
  Contest: '',
  position: '',
  expiryDate: '',
  redeemTime: '',
  RewardType: '',
  winningPoints: '',
};

const EditModal = ({ closeModal, data }) => {
  const [rulesData, setRulesData] = useState();

  const handleFormSubmit = (data) => {
    let newdata = {
      contestId: data?.Contest?.value,
      position: data?.position,
      rewardId: data?.RewardType?.value,
      expiryDate: data?.expiryDate,
      redeemTime: data?.redeemTime,
      winningPoints: data?.winningPoints,
      id: data?.id,
    };

    addEditApi(APIs.ADD_EDIT_REWARD_RULES, newdata).then((result) => {
      toast.success('Edit Successfully'), closeModal();
    });
  };

  useEffect(() => {
    const tempcontestdata = {
      label: `${data?.Contest?.name} | ${moment(data?.Contest?.startDateTime).format('DD-MMM-YYYY  hh:mm a')}`,
      value: data?.Contest?.id,
    };

    const typealpha = handleRewardType(data?.RewardType?.type);
    const tempRewardType = {
      label: `${data?.RewardType?.title} | ${typealpha} | ${data?.RewardType?.price}$`,
      value: data?.RewardType?.id,
    };

    data.Contest = tempcontestdata;
    data.RewardType = tempRewardType;
    data.expiryDate = new Date(data?.expiryDate);
    setRulesData(data);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: rulesData || initialValues,
    validationSchema: editRewardRulesSchema,
    onSubmit: handleFormSubmit,
  });

  const promiseContestDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_CONTESTS, { search: inputValue }).then((result) => {
      const filter = result?.data.map((item) => ({
        label: (
          <div className="optionStyles">
            <img src={item?.imageLink} height="30px" width="30px" />
            <div className="selectText">
              {item.name} | {moment(item.startDateTime).format('DD-MMM-YYYY  hh:mm a')}
            </div>
          </div>
        ),
        value: item.id,
      }));
      resolve(filter);
    });
  };
  const promiseReawrdDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_REWARD_TYPES, { search: inputValue }).then((result) => {
      const filter = result?.data.map((item) => {
        let typealpha = handleRewardType(item?.type);
        let option = {
          label: (
            <div className="optionStyles">
              <img src={item?.imageLink} height="30px" width="30px" />
              <div className="selectText">
                {item?.title} | {item?.shortDescription} | {typealpha} | {item?.price}$
              </div>
            </div>
          ),
          value: item?.id,
        };
        return option;
      });
      resolve(filter);
    });
  };
  const loadContestData = debounce(promiseContestDataOptions, 300);
  const loadRewardTypes = debounce(promiseReawrdDataOptions, 300);

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <div className="modalheadingrow">
            <Typography variant="h4" gutterBottom>
              Edit Rewards Rules
            </Typography>
          </div>

          <Box sx={{ flexGrow: 1, padding: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  placeholder="Select Contest"
                  styles={customStyles}
                  loadOptions={loadContestData}
                  value={values?.Contest}
                  onChange={(data) => {
                    setFieldValue('Contest', data);
                  }}
                />
                {Boolean(touched.Contest && errors.Contest) && (
                  <Typography variant="caption" display="block" gutterBottom color={'red'}>
                    {errors.Contest}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={4} md={6}>
                <TextField
                  fullWidth
                  label="Position"
                  {...getFieldProps('position')}
                  error={Boolean(touched.position && errors.position)}
                  helperText={touched.position && errors.position}
                />
              </Grid>
              <Grid item xs={4} md={6}>
                <TextField
                  fullWidth
                  value={values?.winningPoints}
                  type="number"
                  label="Winning Points"
                  placeholder="Winning Points"
                  onChange={(e) => setFieldValue(`winningPoints`, parseInt(e.target.value))}
                  error={Boolean(touched?.winningPoints && errors?.winningPoints)}
                  helperText={touched?.winningPoints && errors?.winningPoints}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={loadRewardTypes}
                  value={values?.RewardType}
                  styles={customStyles}
                  onChange={(data) => {
                    setFieldValue('RewardType', data);
                  }}
                />
                {errors?.RewardType && touched?.RewardType && <div className="field-error">{errors?.RewardType}</div>}
              </Grid>
              <Grid item xs={4} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    value={values?.expiryDate}
                    label="Expiry date"
                    onChange={(date) => setFieldValue('expiryDate', date)}
                    inputFormat="MM/dd/yyyy"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={Boolean(touched?.expiryDate && errors?.expiryDate)}
                        helperText={touched?.expiryDate && errors?.expiryDate}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TextField
                    fullWidth
                    value={values?.redeemTime}
                    type="number"
                    label="Redeem Time (in Mins)"
                    placeholder="1. Redeem Time (in Mins)"
                    onChange={(e) => setFieldValue(`redeemTime`, parseInt(e.target.value))}
                    error={Boolean(touched?.redeemTime && errors?.redeemTime)}
                    helperText={touched?.redeemTime && errors?.redeemTime}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>

          <div style={{ textAlign: 'right', marginTop: '5px', marginRight: '5px' }}>
            <Typography variant="h4" gutterBottom>
              <Button onClick={closeModal} style={{ marginRight: '10px' }}>
                Cancel
              </Button>
              <Button variant="contained" type="submit" startIcon={<Iconify icon="eva:save-outline" />}>
                Save Rules
              </Button>
            </Typography>
          </div>
        </Form>
      </FormikProvider>
    </>
  );
};
export default EditModal;
