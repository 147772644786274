import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { useDispatch, useSelector } from 'react-redux';
import navConfig from './NavConfig';
import { setNavConfig } from 'src/redux/actions/auth';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const getAccessibleNavConfig = (navConfig) => {
    // if (isSuperAdmin) {
    //   return navConfig;
    // }

    const object = [];
    const fieldsAccess =  user?.fieldsAccess || {};
    for (let i = 0; i <= navConfig.length; i++) {

      if(navConfig[i]?.id === 'users' && fieldsAccess && fieldsAccess?.users){
        object.push({
          ...navConfig[i],
        });
      }
      // for (let j = 0; j < userRoles.length; j++) {
      //   if (navConfig[i]?.id === userRoles[j]?.Id && object.find((it) => it?.id === navConfig[i]?.id) === undefined) {
      //     object.push({
      //       ...navConfig[i],
      //       children: userRoles.filter(
      //         (it) => it?.Id === navConfig[i]?.id && (it?.read === true || it?.edit === true || it?.delete === true)
      //       ),
      //     });
      //   }
      // }
    }
    const newObject = [
      ...navConfig.filter((it) => it.id === 'dashboard' || it.id === 'contests'),
      ...object
    ];

    return newObject;
  };

  useEffect(() => {
    const accessibleNavConfig = getAccessibleNavConfig(navConfig);
    dispatch(setNavConfig(accessibleNavConfig));
  }, [user]);

  const navConfigFromState = useSelector((state) => state.navConfig.navConfig);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={user?.profileImage} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user?.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Partner
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={navConfigFromState} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
