import { AxiosInstance } from './client';

export const listApi = (apiName, params,config={}) => {
  return AxiosInstance()
    .get(apiName, { params, ...config })
    .then((response) => response.data);
};

export const addEditApi = (apiName, payload) => {
  return AxiosInstance()
    .post(apiName, payload)
    .then((response) => response.data);
};
