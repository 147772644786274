export const ROUTES = {
  LOGIN: '/login',
  PARTNER_LOGIN: '/partner-login',
  PARTNER_DASHBOARD: '/partner-dashboard',
  PARTNERS_STATS: '/partner-stats/:id',
  DASHBOARD: '/dashboard',
  USERS: '/users',
  PARTNER_USERS: '/partner-users',
  PARTNER_CONTESTS: '/partner-contests',
  USERS_WALLET: '/user-wallets',
  REPORTED_USERS: '/reported-users',
  ADMINS: '/admins',
  CONTESTS: '/contests',

  CONTESTSID: '/contests/:id',
  BULK_CONTESTS: '/bulk-contests',
  BULK_CONTESTS_ADD: '/bulk-contests/add',
  BULK_CONTESTS_ID : '/bulk-contests/:id',
  SERIES: '/series',
  SERIESADD: 'add',
  SERIESEDIT: 'edit/:id',
  CONTESTS_FORMS: '/contests-forms',
  CONTESTS_WALLPAPERS: '/contests-wallpapers',
  QUESTION_STATS: '/question-stats',
  CONTESTS_INSTRUCTIONS: '/contests-instructions',
  REWARDTYPES: '/reward-types',
  REWARDADD: 'add',

  SCHEDULER_NOTIFICATIONS: '/scheduler-notifications',
  SCHEDULER_NOTIFICATIONS_ADD: '/notifications/add',
  SCHEDULER_NOTIFICATIONS_ID: '/notifications/:id',
  
  REWARDEDIT: 'edit/:id',
  PRODUCTS: '/products',
  NFTPURCHASE: '/nft-purchases',
  NFTNEWSLETTER: '/nft-newsletter',
  GAMES: '/games',
  TEAMS: '/teams',
  PARTNER: '/partners',
  PARTNERS_REWARDS: '/partners-rewards',
  NOT_FOUND: '/404',
  ADD: 'add',
  EDIT: 'edit/:id',
  REWARD_POSITION: '/reward-rules',
  REWARD_RULE_ID: '/reward-rules/:id',
  REWARD_RULE_ADD: 'add',
  REWARD_RULE_EDIT: 'edit/:id',
  WALLPAPER_PURCHASE: '/wallpaper-purchase',
  SUPPORT_REQUEST: '/support-request',
  OPEN_AI_GENERATOR: '/question-generator',
  QUESTION_BANK: '/question-bank',
  ADD_EDIT_QUESTION: '/edit-question/:id',
  FAQ: '/faq',
};
