import React, { useEffect, useState, useMemo } from 'react';
import './index.css';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  CircularProgress,
  Divider,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { listApi } from 'src/apis/admin';
import { APIs } from 'src/apis/client';
import { handleRewardType } from 'src/utils/helper';
import moment from 'moment';
import { UserContestHistoryListTableHeading } from 'src/contants/contants';
import { DATE_FORMAT_TIME } from 'src/contants/contants';
import { useNavigate } from 'react-router-dom';

const ContestHistory = ({ closeModal, userId }) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState();
  const [timeInterval, setTimeInterval] = useState(false);
  useEffect(() => {
    listApi(APIs.LIST_USERS_POINTS, { userId: userId?.id }).then((res) => {
    });
  }, [userId]);
  const noDataHandler = () => {
    setTimeout(() => {
      setTimeInterval(true);
    }, 2000);
  };
  const boxSX = {
    '&:hover': {
      cursor: 'pointer',
      color: '#1769aa',
    },
  };

  ///Getting Particular Contest Winner List/////////
  useMemo(() => {
    setLoading(true);
    listApi(APIs.USER_CONTEST_HISTORY, { userId: userId?.id })
      .then((result) => {
        setList(result?.data);
      })
      .finally(setLoading(false));
  }, []);

  return (
    <div className="modalWinnerContainer">
      <Container className="modalInnerContainer">
        <div className="modalheadingWinnerrow">
          <Typography variant="h4" gutterBottom>
            {userId?.name}
          </Typography>
        </div>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 500 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {UserContestHistoryListTableHeading.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {!!list?.length > 0 ? (
                    <TableBody>
                      {list?.map((row, index) => {
                        const { id, contest_id, rank, score, userData, userWallet, contestData, createdAt } = row;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox">
                            <TableCell align="center">{rank}</TableCell>
                            <TableCell align="left">{score}</TableCell>
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={contestData?.name} src={contestData?.imageLink} />
                                <Stack
                                  spacing={1}
                                  sx={boxSX}
                                  onClick={() => {
                                    window.open(`/contests/${contest_id}`);
                                  }}
                                >
                                  <Typography variant="subtitle2" noWrap>
                                    {contestData?.name}{' '}
                                  </Typography>
                                  <Typography variant="subtitle2" noWrap>
                                    {moment(contestData?.startDateTime).format(DATE_FORMAT_TIME)}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Typography sx={{ width: 200, marginBottom: 2 }}>
                                {moment(createdAt).format(DATE_FORMAT_TIME)}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              {userWallet?.map((wallet) => (
                                <p style={{ width: 270, marginBottom: 5 }}>
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    {wallet?.rewardData?.imageLink ? (
                                      <Avatar alt={wallet?.rewardData?.title} src={wallet?.rewardData?.imageLink} />
                                    ) : (
                                      '-'
                                    )}
                                    <Typography variant="subtitle2" break-spaces>
                                      {wallet?.rewardData?.title}
                                    </Typography>
                                  </Stack>
                                </p>
                              ))}
                            </TableCell>
                            <TableCell align="left">
                              {userWallet?.map((wallet) => (
                                <Typography sx={{ width: 200, marginBottom: 2 }}>
                                  {handleRewardType(wallet?.rewardData?.type)}
                                </Typography>
                              ))}
                            </TableCell>
                            <TableCell align="left">
                              {userWallet?.map((wallet) =>
                                wallet?.actionDate ? (
                                  <Typography sx={{ width: 200, marginBottom: 2 }}>
                                    {moment(wallet?.actionDate).format(DATE_FORMAT_TIME)}
                                  </Typography>
                                ) : (
                                  <Typography sx={{ width: 200, marginBottom: 2 }}>-</Typography>
                                )
                              )}
                            </TableCell>
                            <TableCell align="center" className="dateColumn">
                              {userWallet?.map((wallet) => (
                                <Typography sx={{ marginBottom: 2 }}>{wallet?.finalRedeem ? 'Yes' : 'No'}</Typography>
                              ))}
                            </TableCell>
                            <TableCell align="left" className="actiondateColumn">
                              {userWallet?.map((wallet) =>
                                wallet?.expiryDate ? (
                                  <Typography sx={{ width: 200, marginBottom: 2 }}>
                                    {moment(wallet?.expiryDate).format(DATE_FORMAT_TIME)}
                                  </Typography>
                                ) : (
                                  '-'
                                )
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    ''
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!!list?.length == 0 && (
          <Typography variant="subtitle2" align="center" style={{ marginTop: '20px', marginBottom: '90px' }}>
            {noDataHandler()}
            {!timeInterval ? 'Loading...' : 'No Data Found'}
          </Typography>
        )}
        <div className="modalWinnnerFooterContainer">
          <Typography variant="h4" gutterBottom>
            <Button onClick={closeModal} style={{ marginRight: '10px' }}>
              Cancel
            </Button>
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default ContestHistory;
