import React, { useState } from 'react';
import { Card, Stack, Container, Typography, TextField, Grid, Button, CircularProgress } from '@mui/material';
import Page from 'src/components/Page';
import Select from 'react-select';
import Scrollbar from 'src/components/Scrollbar';
import { useFormik, Form, FormikProvider } from 'formik';
import { APIs, BASE_URL } from 'src/apis';
import { generateQuestionSchema } from 'src/utils/validationSchemas';
import Listing from './listing';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import CachedIcon from '@mui/icons-material/Cached';
import axios from 'axios';
import { store } from 'src/redux/store';

const GENERATE_FIELDS = [
  { label: 'Count', identifier: 'count', type: 'number', gridSize: 1 },
  { label: 'Topic', identifier: 'topic', type: 'text', gridSize: 3 },
  { label: 'Sub Topic', identifier: 'subtopic', type: 'text', gridSize: 2 },
  {
    label: 'Difficulty',
    identifier: 'difficulty',
    gridSize: 2,
    type: 'select',
    options: [
      { label: 'Beginner', value: 'beginner' },
      { label: 'Intermediate', value: 'intermediate' },
      { label: 'Difficult', value: 'difficult' },
    ],
  },
  {
    label: 'Fetch From',
    identifier: 'fetchFrom',
    gridSize: 3,
    type: 'select',
    options: [
      { label: 'ShotCaller InHouse', value: 1 },
      { label: 'Open AI', value: 2 },
    ],
  },
];

const initialValues = {
  count: '',
  topic: '',
  subtopic: '',
  difficulty: GENERATE_FIELDS?.[3]?.options?.[0],
  fetchFrom: GENERATE_FIELDS?.[4]?.options?.[1],
};

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 55,
      border: '',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

const Generator = () => {
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);

  const handleFormSubmit = (payload) => {
    const apiPayload = {
      ...payload,
      difficulty: payload?.difficulty?.value,
    };
    setLoading(true);
    const apiUrl =
      payload?.fetchFrom?.value === 1
        ? 'https://app.playshotcaller.com/python/genquestions'
        : BASE_URL + '/' + APIs.GENERATE_QUESTION;

    delete apiPayload.fetchFrom;

    axios(apiUrl, {
      params: { ...apiPayload },
      headers: {
        authorization: store.getState().auth.accessToken ? `${store.getState().auth.accessToken}` : '',
      },
    })
      .then((res) => {
        const updatedData = res?.data?.questions?.map((obj, index) => {
          return {
            ...obj,
            source: obj?.['origin source'] || '',
            isHeaderImageGenerated: false,
            questionHeader: '',
            images: [],
            originalImages: [],
            selectedImage: '',
            isImageGenerated: false,
            questionChecked: true,
            generateImageAuto: false,
            topic: payload.topic,
            subtopic: payload.subtopic,
            choices: obj?.choices?.map((option, i) => {
              return { option, images: [], selectedImage: '', originalImages: [] };
            }),
            headerImgGenCount: 0,
          };
        });
        setListData(updatedData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: generateQuestionSchema,
    onSubmit: handleFormSubmit,
  });

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue, resetForm } = formik;

  return (
    <Page title="Question Generator">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Question Generator
          </Typography>
        </Stack>

        <Card style={{ minHeight: '66vh' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2} padding={3} style={{ paddingBottom: '0px' }}>
                {GENERATE_FIELDS.map((fieldObj, index) => {
                  return (
                    <Grid item xs={12} md={fieldObj.gridSize} key={index}>
                      {fieldObj.type === 'select' ? (
                        <Select
                          fullWidth
                          placeholder={fieldObj.label}
                          styles={customStyles}
                          options={fieldObj?.options}
                          name={fieldObj.identifier}
                          value={values?.[fieldObj.identifier]}
                          getOptionLabel={(v) => v?.label}
                          getOptionValue={(v) => v?.value}
                          onChange={(data) => setFieldValue([fieldObj.identifier], data)}
                        />
                      ) : (
                        <TextField
                          key={index}
                          fullWidth
                          label={fieldObj.label}
                          type={fieldObj.type}
                          {...getFieldProps(`${fieldObj.identifier}`)}
                          error={Boolean(touched?.[fieldObj.identifier] && errors?.[fieldObj.identifier])}
                          helperText={
                            (touched?.[fieldObj.identifier] && errors?.[fieldObj.identifier]) || (
                              <div style={{ visibility: 'hidden' }}>hidden</div>
                            )
                          }
                        />
                      )}
                    </Grid>
                  );
                })}

                <Grid item xs={12} md={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<YoutubeSearchedForIcon />}
                    style={{ marginBottom: '20px' }}
                    disabled={loading}
                  >
                    {loading ? 'Generating' : 'Generate'}
                  </Button>
                  <Button
                    variant="outlined"
                    type="button"
                    startIcon={<CachedIcon />}
                    style={{ marginBottom: '20px', marginLeft: '10px' }}
                    disabled={loading}
                    onClick={() => {
                      resetForm();
                      setListData([]);
                    }}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>

          <Scrollbar>
            {/* <Listing listData={listData} topic={values?.topic} /> */}
            {loading ? (
              <Stack alignItems="center" style={{ marginBottom: '10px', marginTop: '10px' }}>
                <CircularProgress />
              </Stack>
            ) : listData?.length > 0 ? (
              <Listing listData={listData} topic={values?.topic} subtopic={values?.subtopic} />
            ) : (
              <Stack alignItems="center" style={{ marginTop: '70px', color: 'gray' }}>
                Generate Questions
              </Stack>
            )}
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
};

export default Generator;
