import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  CircularProgress,
  Dialog,
} from '@mui/material';
import Page from 'src/components/Page';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import Iconify from 'src/components/Iconify';
import { UserListToolbar, UserMoreMenu } from 'src/sections/@dashboard/user';
import { listApi } from 'src/apis/admin';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { APIs } from 'src/apis';
import 'react-phone-input-2/lib/material.css';
import AddEditModal from './addEditModal';
import { DATE_FORMAT } from 'src/contants/contants';
import GenerateDeepLinkModal from './generateDeepLinkModal';
import WallpaperModal from './wallpaperModal';
import { useNavigate } from 'react-router-dom';
import EmailModal from './emailModal';
import ImageModal from 'src/pages/openAI/questionBankList/imageModal';
import { useSelector } from 'react-redux';

const TABLE_HEAD = [
  { id: 'action', label: 'Action', alignRight: false },
  { id: 'name', label: 'Partner Name', alignRight: false },
  { id: 'deeplinkUrl', label: 'DeepLink', alignRight: false },
  { id: 'partnerWallpaperLink', label: 'Wallpaper DeepLink', alignRight: false },
  { id: 'partnerProfileLink', label: 'App Profile DeepLink', alignRight: false },
  { id: 'contactPersonName', label: 'Contact Person Name', alignRight: false },
  { id: 'contactPersonEmail', label: 'Contact Person Email', alignRight: false },
  { id: 'emailId', label: 'Email', alignRight: false },
  { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'twitterHandle', label: 'Twitter Handle', alignRight: false },
  { id: 'hashTags', label: 'HashTags', alignRight: false },
  { id: 'emailValidated', label: 'Email Validated', alignRight: false },
  { id: 'createdAt', label: 'Created at', alignRight: false },
  { id: 'active', label: 'Status', alignRight: false },
];

export default function Partner() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [openModal, setModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [partnerId, setPartnerId] = useState({});
  const [generateModal, setGenerateModal] = useState();
  const [wallpaperModal, setWallpaperModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth);

  const handleEditPermission =
    user && user.roles && user.roles.some((role) => role?.title === 'Partners List' && role?.edit === true);

  const isSuperAdmin = user?.isSuperAdmin;

  useEffect(() => {
    setLoading(true);
    listApi(APIs.LIST_PARTNERS, { skip: page * rowsPerPage, limit: rowsPerPage, search: searchFilter })
      .then((res) => {
        setUsers(res?.data);
        setTotalCount(res?.count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refresh, rowsPerPage, searchFilter, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  const handleClose = (_, reason) => {
    setModalOpen(false);
    setSelectedRow('');
    // if (reason === 'backdropClick') {
    //   setModalOpen(false);
    // }
  };
  const closeModal = () => {
    setPartnerId({});
    setGenerateModal(false);
    setWallpaperModal(false);
    setEmailModal(false);
    setRefresh(!refresh);
  };

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };

  return (
    <Page title="Partners">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Partners
          </Typography>
          <Button variant="contained" onClick={() => setModalOpen(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Partner
          </Button>
        </Stack>

        <Card>
          <UserListToolbar filterName={search} onFilterName={handleSearch} />
          {loading ? (
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          ) : (
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          <TableSortLabel hideSortIcon active={false} direction={'asc'}>
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {users.map((row, index) => {
                      const {
                        id,
                        name,
                        emailId,
                        contactPersonName,
                        contactPersonEmail,
                        phoneNumber,
                        createdAt,
                        address,
                        profileImage,
                        emailValidated,
                        active,
                        deepLinkUrl,
                        partnerWallpaperLink,
                        partnerAppProfileLink,
                        isNft,
                        wallpapers,
                        wallpaperDescription,
                        hashTags,
                        twitterHandle,
                      } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                          <TableCell align="right">
                            {handleEditPermission ||
                              (isSuperAdmin && (
                                <UserMoreMenu
                                  options={[
                                    {
                                      label: 'Edit',
                                      icon: 'eva:edit-fill',

                                      onClick: () => {
                                        setSelectedRow(row);
                                        setModalOpen(true);
                                      },
                                    },
                                    {
                                      label: 'Generate Deep Link',
                                      icon: 'material-symbols:link',

                                      onClick: () => {
                                        setPartnerId({ id, name, type : 1});
                                        setGenerateModal(true);
                                      },
                                    },
                                    {
                                      label: 'Generate Wallpaper Link',
                                      icon: 'material-symbols:link',

                                      onClick: () => {
                                        setPartnerId({ id, name , type : 2});
                                        setGenerateModal(true);
                                      },
                                    },
                                    {
                                      label: 'Generate Profile Link',
                                      icon: 'material-symbols:link',

                                      onClick: () => {
                                        setPartnerId({ id, name , type : 3});
                                        setGenerateModal(true);
                                      },
                                    },
                                    {
                                      label: 'Partner Wallpapers',
                                      icon: 'material-symbols:wallpaper',

                                      onClick: () => {
                                        setPartnerId({ id, name, isNft, wallpapers, wallpaperDescription });
                                        setWallpaperModal(true);
                                      },
                                    },
                                    {
                                      label: 'Partner Stats',
                                      icon: 'carbon:partnership',

                                      onClick: () => {
                                        navigate(`/partner-stats/${id}`);
                                      },
                                    },
                                    {
                                      label: 'Send Follow Email',
                                      icon: 'ic:outline-email',

                                      onClick: () => {
                                        setPartnerId({ id, name });
                                        setEmailModal(true);
                                      },
                                    },
                                  ]}
                                />
                              ))}
                          </TableCell>
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar
                                alt={name}
                                src={profileImage}
                                onClick={() => showFullImage(profileImage)}
                                style={{ cursor: 'pointer' }}
                              />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>{deepLinkUrl ? <a href={deepLinkUrl}>{deepLinkUrl}</a> : '-'}</TableCell>
                          <TableCell>{partnerWallpaperLink ? <a href={partnerWallpaperLink}>{partnerWallpaperLink}</a> : '-'}</TableCell>
                          <TableCell>{partnerAppProfileLink ? <a href={partnerAppProfileLink}>{partnerAppProfileLink}</a> : '-'}</TableCell>
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle5" noWrap>
                                {contactPersonName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{contactPersonEmail}</TableCell>
                          <TableCell align="left">{emailId}</TableCell>
                          <TableCell align="left">{phoneNumber}</TableCell>
                          <TableCell align="left">{address}</TableCell>
                          <TableCell align="left">{twitterHandle || '-'}</TableCell>
                          <TableCell align="left">{hashTags || '-'}</TableCell>
                          <TableCell align="left">{emailValidated ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">{moment(createdAt).format(DATE_FORMAT)}</TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={active !== 1 ? 'error' : 'success'}>
                              {active !== 1 ? 'InActive' : 'Active'}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          )}

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>

      <AddEditModal
        setSelectedRow={setSelectedRow}
        selectedRow={selectedRow}
        openModal={openModal}
        handleClose={handleClose}
        setModalOpen={setModalOpen}
        setRefresh={setRefresh}
      />
      <Dialog open={generateModal}>
        <GenerateDeepLinkModal closeModal={closeModal} partnerId={partnerId} />
      </Dialog>
      <Dialog maxWidth="lg" open={wallpaperModal}>
        <WallpaperModal closeModal={closeModal} partnerId={partnerId} />
      </Dialog>
      <Dialog maxWidth="lg" open={emailModal}>
        <EmailModal closeModal={closeModal} partnerId={partnerId} />
      </Dialog>
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </Page>
  );
}
