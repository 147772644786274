import { AxiosInstance, AuthAPIs, PartnerAuthAPIs } from './client';
import { store } from '../redux/store';

export const login = (payload) => {
  return AxiosInstance()
    .post(AuthAPIs.LOGIN, payload)
    .then((response) => response.data);
};

export const partnerLogin = (payload) =>{
  return AxiosInstance()
    .post(PartnerAuthAPIs.VERIFY_OTP, payload)
    .then((response)=> response.data)
}
export const logout = () => {
  // return AxiosInstance()
  //   .post(AuthAPIs.LOGOUT, {
  //     deviceId: store?.getState()?.auth?.deviceId || '',
  //   })
  //   .then((response) => response.data);
};
