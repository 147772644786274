export const handleRewardType = (type) => {
  switch (type) {
    case 1:
      return 'XP';
    case 2:
      return 'Wallpaper';
    case 3:
      return 'Food, Beverage, Appetizer';
    case 4:
      return 'Physical Good';
    case 5:
      return 'Physical Gift Certificate';
    case 6:
      return 'Virtual Gift Certificate';
    case 7:
      return 'NFT Reward';
    case 8:
      return 'Partner Reward';

    default:
      return 'No type';
  }
};

export const winnerListActionHanlder = (type) => {
  switch (type) {
    case 1:
      return 'Redeemed';
      break;
    case 2:
      return 'Donate';
      break;
    case null:
      return '-';
      break;
    default:
      return false;
  }
};

 
export const openLinkNewTab = (url) => window.open(url, '_blank')?.focus();

  
