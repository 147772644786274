import { useEffect, useState ,useMemo} from 'react';
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  CircularProgress,
} from '@mui/material';
import Page from '../../../components/Page';

import Scrollbar from '../../../components/Scrollbar';
import { listApi } from 'src/apis/admin';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import { APIs } from '../../../apis';
import React from 'react';
import ImageModal from 'src/pages/openAI/questionBankList/imageModal';

const TABLE_HEAD = [
  { id: 'name', label: 'Username', alignRight: false },
  { id: 'emailId', label: 'Email', alignRight: false },
  { id: 'phoner', label: 'Phone Number', alignRight: false },
  { id: 'dob', label: 'DOB', alignRight: false },
  { id: 'tshirtSize', label: 'T-shirt Size', alignRight: false },
  { id: 'twitter', label: 'Twitter', alignRight: false },
  { id: 'instagram', label: 'Instagram', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
];

export default function PartnerUsers() {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);
  const [fieldsAccess, setFieldsAccess] = useState({});
  let {id } = useSelector((state) => state.auth);

  useMemo(() => {
    listApi(APIs.LIST_PARTNERS, { partnerId: id })
      .then((res) => {
        if(res?.data?.length > 0){
          console.log(res?.data[0]);
          setFieldsAccess(res?.data[0]?.fieldsAccess);
        }
      })
  }, [id]);

  useEffect(() => {
    setLoading(true);
    listApi(APIs.LIST_PARTNER_USERS, { partnerId: id })
      .then((res) => {
        setUsers(res?.data);
        setTotalCount(res?.data?.length);
        setRowsPerPage(res?.data?.length);
      })
      .finally(() => setLoading(false));
  }, [rowsPerPage, searchFilter, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  //function to open a full size image
  const showFullImage = (src) => {
    if(src !== ''){
      setOpenImageModal(true);
      setImageSrc(src);
    }
  };

  return (
    <Page title="Users">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Partner Gamers
          </Typography>
        </Stack>

        <Card>
          {/* <UserListToolbar numSelected={selected.length} filterName={search} onFilterName={handleSearch} /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          <TableSortLabel hideSortIcon active={false} direction={'asc'}>
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {users.map((row, index) => {
                      const {
                        id,
                        name,
                        emailId,
                        profileImg,
                        phoneNumber,
                        dob,
                        addressLine1,
                        addressLine2,
                        city,
                        state,
                        pinCode,
                        tshirtSize,
                        twitter,
                        instagram,
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {' '}
                          {/* <TableCell align="right">
                            <MoreMenu
                              options={[

                              ]}
                            />
                          </TableCell> */}
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar
                                alt={name}
                                src={fieldsAccess?.users?.profileImg && profileImg || '-'}
                                onClick={() => showFullImage(fieldsAccess?.users?.profileImg && profileImg || '')}
                                style={{ cursor: 'pointer' }}
                              />
                              <Stack>
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2" noWrap>
                              {fieldsAccess?.users?.emailId && emailId || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2" noWrap>
                              {fieldsAccess?.users?.phoneNumber && phoneNumber || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{fieldsAccess?.users?.dob && dob || '-'}</TableCell>
                          <TableCell align="left">
                            <Typography sx={{ width: '100px' }} noWrap>
                              {fieldsAccess?.users?.tshirtSize && tshirtSize || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{fieldsAccess?.users?.twitter && twitter || '-'}</TableCell>
                          <TableCell align="left">{fieldsAccess?.users?.instagram && instagram || '-'}</TableCell>
                          <TableCell align="left">{fieldsAccess?.users?.address && `${addressLine1} ${addressLine2} , ${city} , ${state} , ${pinCode}` || '-'}</TableCell>

                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>

      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </Page>
  );
}
