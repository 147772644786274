import React, { useEffect, useState } from 'react';
import './index.css';
import { Typography, Grid, Button, TextField, Avatar, Stack } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { gameSchema } from 'src/utils/validationSchemas';
import Iconify from 'src/components/Iconify';
import Select from 'react-select';
import { APIs } from 'src/apis';
import debounce from 'lodash/debounce';
import { addEditApi, listApi } from 'src/apis/admin';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const initialValues = {
  leagueId: '',
  homeTeamId: '',
  awayTeamId: '',
  startTime: '',
  status: '',
  gameQuarterDuration: '',
  clock: '',
  maxQuarterToPlay: '5',
};
const statusOptions = [
  { label: 'Scheduled', value: 'scheduled' },
  { label: 'Complete', value: 'complete' },
  { label: 'Inprogress', value: 'inprogress' },
];

/*Styles from React Select */

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 55,
      border: '',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

const GameModal = ({ closeModal, selectedGame }) => {
  const [leagues, setLeagues] = useState();
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    listApi(APIs.LIST_LEAGUES).then((result) => {
      setLeagues(result);
    });

    const SelectedDataObj = {
        leagueId: selectedGame?.League,
        homeTeamId: {
          label: (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar alt={selectedGame?.HomeTeam?.teamName} src={selectedGame?.HomeTeam?.image_url} />
              <Typography variant="subtitle2" noWrap>
                {selectedGame?.HomeTeam?.teamName}
              </Typography>
            </Stack>
          ),
          value: selectedGame?.HomeTeam?.id,
        },
        awayTeamId: {
          label: (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar alt={selectedGame?.AwayTeam?.teamName} src={selectedGame?.AwayTeam?.image_url} />
              <Typography variant="subtitle2" noWrap>
                {selectedGame?.AwayTeam?.teamName}
              </Typography>
            </Stack>
          ),
          value: selectedGame?.AwayTeam?.id,
        },
        startTime: new Date(selectedGame?.startTime),
        status: statusOptions.find((obj, i) => obj.value === selectedGame?.status),
        gameQuarterDuration: selectedGame?.gameQuarterDuration,
        clock: selectedGame?.clock,
        maxQuarterToPlay: selectedGame?.maxQuarterToPlay ? selectedGame?.maxQuarterToPlay : '',
      };

      if (selectedGame?.id) {
        SelectedDataObj.id = selectedGame?.id
      }

      setSelectedData(SelectedDataObj);
    
  }, []);

  /*FORM SUBMIT HANDLER */
  const promiseTeamDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_TEAMS, { search: inputValue }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={item?.teamName} src={item?.image_url} />
            <Typography variant="subtitle2" noWrap>
              {item?.teamName}
            </Typography>
          </Stack>
        ),
        value: item?.id,
      }));
      resolve(filter);
    });
  };
  const loadHomeTeamData = debounce(promiseTeamDataOptions, 300);

  const loadAwayTeamData = debounce(promiseTeamDataOptions, 300);

  const handleFormSubmit = (payload) => {
    const ApiPayload = {
      leagueId: payload?.leagueId?.id,
      homeTeamId: payload?.homeTeamId?.value,
      awayTeamId: payload?.awayTeamId?.value,
      startTime: payload?.startTime,
      status: payload?.status?.value,
      gameQuarterDuration: payload?.gameQuarterDuration === '' ? null : payload?.gameQuarterDuration,
      clock: payload?.clock === '' ? null : payload?.clock,
      maxQuarterToPlay: payload?.maxQuarterToPlay === '' ? null : payload?.maxQuarterToPlay,
    };
    if (selectedGame?.id) {
      ApiPayload.id = selectedGame?.id;
    }
    addEditApi(APIs.ADD_EDIT_GAME, { ...ApiPayload }).then(() => {
      toast.success(`${selectedGame?.id ? 'Updated' : 'Saved'} Successfully`);
      closeModal();
    });
  };

  /*FORMIK INTIMIALIZATION */

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: selectedData || initialValues,
    validationSchema: gameSchema,
    onSubmit: handleFormSubmit,
  });

  /*DISTRCUTING FORMIK  */

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        <div className="modalContainer">
          <div className="modalheadingrow">
            <Typography variant="h4" gutterBottom>
              {selectedGame?.id ? 'Edit Game' : 'Add Game'}
            </Typography>
          </div>
          <Grid container className="formContainer" spacing={4}>
            <Grid item xs={12} md={12}>
              <Select
                cacheOptions
                defaultOptions
                placeholder="Select League"
                styles={customStyles}
                options={leagues}
                name="leagueId"
                getOptionLabel={(v) => v?.leagueName}
                getOptionValue={(v) => v?.id}
                value={leagues?.filter((league) => league?.id === values?.leagueId?.id)}
                onChange={(data) => {
                  setFieldValue('leagueId', data);
                }}
              />

              {Boolean(touched.leagueId && errors.leagueId) && (
                <Typography variant="caption" display="block" gutterBottom color={'red'}>
                  {errors.leagueId}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Select Away Team"
                styles={customStyles}
                loadOptions={loadAwayTeamData}
                name="awayTeamId"
                value={values?.awayTeamId}
                onChange={(data) => {
                  setFieldValue('awayTeamId', data);
                }}
              />

              {Boolean(touched.awayTeamId && errors.awayTeamId) && (
                <Typography variant="caption" display="block" gutterBottom color={'red'}>
                  {errors.awayTeamId}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Select Home Team"
                styles={customStyles}
                loadOptions={loadHomeTeamData}
                name="homeTeamId"
                value={values?.homeTeamId}
                onChange={(data) => {
                  setFieldValue('homeTeamId', data);
                }}
              />

              {Boolean(touched.homeTeamId && errors.homeTeamId) && (
                <Typography variant="caption" display="block" gutterBottom color={'red'}>
                  {errors.homeTeamId}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  value={values?.startTime}
                  label="Start Time"
                  onChange={(date) => setFieldValue('startTime', date)}
                  // disablePast={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={Boolean(touched.startTime && errors.startTime)}
                      helperText={touched.startTime && errors.startTime}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                cacheOptions
                defaultOptions
                placeholder="Select Status"
                styles={customStyles}
                options={statusOptions}
                name="status"
                value={values?.status}
                onChange={(data) => {
                  setFieldValue('status', data);
                }}
              />

              {Boolean(touched.status && errors.status) && (
                <Typography variant="caption" display="block" gutterBottom color={'red'}>
                  {errors.status}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="number"
                fullWidth
                label="Quarter Duration (In Mins)"
                {...getFieldProps('gameQuarterDuration')}
                helperText={touched.gameQuarterDuration && errors.gameQuarterDuration}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="number"
                fullWidth
                label="Quiz Show Duration (In Mins) "
                {...getFieldProps('clock')}
                helperText={touched.clock && errors.clock}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="number"
                fullWidth
                label="Max. Quarter to Play"
                {...getFieldProps('maxQuarterToPlay')}
                helperText={touched.maxQuarterToPlay && errors.maxQuarterToPlay}
              />
            </Grid>
          </Grid>

          <div className="modalFooterContainer">
            <Typography variant="h4" gutterBottom>
              <Button onClick={closeModal} style={{ marginRight: '10px' }}>
                Cancel
              </Button>

              <Button variant="contained" type="submit" startIcon={<Iconify icon="eva:save-outline" />}>
                Save
              </Button>
            </Typography>
          </div>
        </div>
      </Form>
    </FormikProvider>
    
  );
};

export default GameModal;
