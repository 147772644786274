import React, { useState, useMemo } from 'react';
import './index.css';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  CircularProgress,
  Skeleton,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { listApi } from 'src/apis/admin';
import { APIs } from 'src/apis/client';
import { handleRewardType } from 'src/utils/helper';
import moment from 'moment';
import { seriesWinnerListTableHeading } from 'src/contants/contants';
import { winnerListActionHanlder } from 'src/utils/helper';
import { DATE_FORMAT_TIME } from 'src/contants/contants';

const SeriesWinnerList = ({ closeModal, seriesId }) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState();
  const [selected, setSelected] = useState([]);
  const [timeInterval, setTimeInterval] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const noDataHandler = () => {
    setTimeout(() => {
      setTimeInterval(true);
    }, 2000);
  };

  ///Getting Particular Contest Winner List/////////

  useMemo(() => {
    setLoading(true);
    listApi(APIs.WINNER_SERIES_LIST, { seriesId: seriesId?.id, sendRewards : true })
      .then((result) => {
        setList(result?.data);
      })
      .finally(setLoading(false));
  }, [refresh]);

  return (
    <div className="modalWinnerContainer">
      <Container className="modalInnerContainer">
        <div className="modalheadingWinnerrow">
          <Typography variant="h4" gutterBottom>
            {seriesId?.id ? seriesId?.name :  <Skeleton variant="rectangular" width={310} height={40} />}
          </Typography>
          <Typography variant="h4" gutterBottom>
            <Button onClick={closeModal} style={{ marginRight: '10px' }}>
              Cancel
            </Button>
          </Typography>
        </div>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 500 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {seriesWinnerListTableHeading.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {!!list?.length > 0 ? (
                    <TableBody>
                      {list?.map((row, index) => {
                        const {
                          id,
                          rank,
                          score,
                          userData,
                          userWallet,
                        } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            {/* <TableCell>
                              <Button
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  statusContest(row);
                                }}
                                align="right"
                                // variant="outlined"
                                color="error"
                                startIcon={<DeleteIcon />}
                              >
                                Delete
                              </Button>
                            </TableCell> */}
                            <TableCell align="center">{rank}</TableCell>
                            <TableCell align="left">{score}</TableCell>
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={userData?.name} src={userData?.profileImg} />
                                <Typography variant="subtitle2" break-spaces style={{ width: '120px' }}>
                                  {userData?.name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              {userWallet?.map((wallet) => (
                                <p style={{ width: 270, marginBottom: 5 }}>
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    {wallet?.rewardData?.imageLink ? (
                                      <Avatar alt={wallet?.rewardData?.title} src={wallet?.rewardData?.imageLink} />
                                    ) : (
                                      '-'
                                    )}
                                    <Typography variant="subtitle2" break-spaces>
                                      {wallet?.rewardData?.title}
                                    </Typography>
                                  </Stack>
                                  {/* <Stack className="discriptionStyle">{wallet?.rewardData?.shortDescription}</Stack> */}
                                </p>
                              ))}
                            </TableCell>
                            <TableCell align="left">
                              {userWallet?.map((wallet) => (
                                <Typography sx={{ width: 200, marginBottom: 2 }}>
                                  {handleRewardType(wallet?.rewardData?.type)}
                                </Typography>
                              ))}
                            </TableCell>

                            <TableCell align="left">
                              {userWallet?.map((wallet) => (
                                <Typography sx={{ width: 100, marginBottom: 2 }}>
                                  {winnerListActionHanlder(wallet?.action)}
                                </Typography>
                              ))}
                            </TableCell>
                            <TableCell align="left">
                              {userWallet?.map((wallet) =>
                                wallet?.actionDate ? (
                                  <Typography sx={{ width: 200, marginBottom: 2 }}>
                                    {moment(wallet?.actionDate).format(DATE_FORMAT_TIME)}
                                  </Typography>
                                ) : (
                                  <Typography sx={{ width: 200, marginBottom: 2 }}>-</Typography>
                                )
                              )}
                            </TableCell>
                            <TableCell align="center" className="dateColumn">
                              {userWallet?.map((wallet) => (
                                <Typography sx={{ marginBottom: 2 }}>{wallet?.finalRedeem ? 'Yes' : 'No'}</Typography>
                              ))}
                            </TableCell>
                            <TableCell align="left" className="actiondateColumn">
                              {userWallet?.map((wallet) =>
                                wallet?.expiryDate ? (
                                  <Typography sx={{ width: 200, marginBottom: 2 }}>
                                    {moment(wallet?.expiryDate).format(DATE_FORMAT_TIME)}
                                  </Typography>
                                ) : (
                                  '-'
                                )
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    ''
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!!list?.length == 0 && (
          <Typography variant="subtitle2" align="center" style={{ marginTop: '20px', marginBottom: '90px' }}>
            {noDataHandler()}
            {!timeInterval ? 'Loading...' : 'No Data Found'}
          </Typography>
        )}
        <div className="modalWinnnerFooterContainer">
          <Typography variant="h4" gutterBottom>
            <Button onClick={closeModal} style={{ marginRight: '10px' }}>
              Cancel
            </Button>
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default SeriesWinnerList;
