import { Button, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import Iconify from 'src/components/Iconify';
import { toast } from 'react-toastify';
import { openLeaderboardLink } from 'src/contants/contants';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------


const copyToClipboard = (text) => {
    const input = document.createElement('input');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
};

export default function TopButtons() {
    const { deepLinkUrl , id} = useSelector((state) => state.auth);

    const handleCopyUrl = () => {
        const urlToCopy = deepLinkUrl;
        copyToClipboard(urlToCopy);
        toast.success('Partner Url Copied');
    };

    return (
        <>
            <Tooltip title="Copy URL by Click">
            <Button
                variant="contained"
                onClick={() => handleCopyUrl()}
                startIcon={<Iconify icon="ion:copy-outline" />}
                sx={{
                    height: '50%',
                    width: 'auto',
                    marginRight: 5,
                    backgroundColor: '#FC2759',
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                Copy/Share Partner Game Link
            </Button>
            </Tooltip>
            <Tooltip title="Open Public Leaderboard">
            <Button
                variant="contained"
                onClick={() => window.open(openLeaderboardLink+id, '_blank')}
                startIcon={<Iconify icon="iconoir:leaderboard-star" />}
                sx={{
                    height: '50%',
                    width: 'auto',
                    marginRight: 5,
                    backgroundColor: '#FC2759',
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                Public Leaderboard
            </Button>
            </Tooltip>
        </>
    );
}
