import { ROUTES } from 'src/router/routes';
import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    path: ROUTES.DASHBOARD,
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    id: 'users',
    title: 'Users',
    path: ROUTES.USERS,
    icon: getIcon('dashicons:games'),
    children: [
      { id: 'usersList', title: 'Users List', path: ROUTES.USERS, icon: getIcon('eva:people-fill') },
      { id: 'wallets', title: 'User Wallets', path: ROUTES.USERS_WALLET, icon: getIcon('akar-icons:wallet') },
      { id: 'reported', title: 'Reported Users', path: ROUTES.REPORTED_USERS, icon: getIcon('ic:round-report') },
    ],
  },
  {
    id: 'contests',
    title: 'Contests',
    path: ROUTES.CONTESTS,
    icon: getIcon('eva:bar-chart-outline'),
    children: [
      { id: 'list', title: 'Contest List', path: ROUTES.CONTESTS, icon: getIcon('akar-icons:wallet') },
      {
        id: 'contestbulk',
        title: 'Contest Bulk Create',
        path: ROUTES.BULK_CONTESTS,
        icon: getIcon('akar-icons:wallet'),
      },
      {
        id: 'stats',
        title: 'Question Stats',
        path: ROUTES.QUESTION_STATS,
        icon: getIcon('akar-icons:wallet'),
      },
      { id: 'form', title: 'Contest Form', path: ROUTES.CONTESTS_FORMS, icon: getIcon('akar-icons:wallet') },
      {
        id: 'wallpapers',
        title: 'Contest Wallpapers',
        path: ROUTES.CONTESTS_WALLPAPERS,
        icon: getIcon('akar-icons:wallet'),
      },
      {
        id: 'purchase',
        title: 'Wallpaper Purchase',
        path: ROUTES.WALLPAPER_PURCHASE,
        icon: getIcon('icon-park-solid:buy'),
      },
      {
        id: 'instructions',
        title: 'Contest Instructions',
        path: ROUTES.CONTESTS_INSTRUCTIONS,
      },
    ],
  },
  {
    id: 'Scheduler',
    title: 'Scheduler',
    path: ROUTES.SCHEDULER,
    icon: getIcon('eva:bar-chart-outline'),
    children: [
      {
        id: 'notifications',
        title: 'Notifications',
        path: ROUTES.SCHEDULER_NOTIFICATIONS,
        icon: getIcon('akar-icons:wallet'),
      },
    ],
  },
  {
    id: 'series',
    title: 'Series',
    path: ROUTES.SERIES,
    icon: getIcon('iconoir:list'),
    children: [{ id: 'list', title: 'Series List', path: ROUTES.SERIES, icon: getIcon('akar-icons:wallet') }],
  },
  {
    id: 'partners',
    title: 'Partners',
    path: ROUTES.PARTNER,
    icon: getIcon('wpf:administrator'),
    children: [{ id: 'list', title: 'Partners List', path: ROUTES.PARTNER, icon: getIcon('wpf:administrator') }],
  },
  {
    id: 'gameModels',
    title: 'Question Key',
    path: ROUTES.OPEN_AI_GENERATOR,
    icon: getIcon('carbon:purchase'),
    children: [
      {
        id: 'generator',
        title: 'Question Generator',
        path: ROUTES.OPEN_AI_GENERATOR,
        icon: getIcon('wpf:administrator'),
      },
      { id: 'question', title: 'Question Bank', path: ROUTES.QUESTION_BANK, icon: getIcon('eos-icons:admin') },
    ],
  },
  {
    id: 'rewardTypes',
    title: 'Reward Types',
    path: ROUTES.REWARDTYPES,
    icon: getIcon('eva:file-text-fill'),
  },
  {
    id: 'rewardRules',
    title: 'Reward Rules',
    path: ROUTES.REWARD_POSITION,
    icon: getIcon('eva:lock-fill'),
  },
  {
    id: 'games',
    title: 'Games',
    path: ROUTES.GAMES,
    icon: getIcon('dashicons:games'),
    children: [
      { id: 'list', title: 'Games List', path: ROUTES.GAMES, icon: getIcon('akar-icons:wallet') },
      { id: 'teams', title: 'Teams List', path: ROUTES.TEAMS, icon: getIcon('akar-icons:wallet') },
    ],
  },
  {
    id: 'admins',
    title: 'Admins',
    path: ROUTES.ADMINS,
    icon: getIcon('eos-icons:admin'),
  },
  {
    id: 'content',
    title: 'Content',
    path: ROUTES.NFTNEWSLETTER,
    icon: getIcon('akar-icons:wallet'),
    children: [
      { id: 'news', title: 'News Letter', path: ROUTES.NFTNEWSLETTER, icon: getIcon('mdi:email-newsletter') },
      { id: 'support', title: 'Support Request', path: ROUTES.SUPPORT_REQUEST, icon: getIcon('akar-icons:wallet') },
    ],
  },
  {
    id: 'nftPurchase',
    title: 'NFT Purchase',
    path: ROUTES.NFTPURCHASE,
    icon: getIcon('carbon:purchase'),
  },
  {
    id: 'faqs',
    title: 'FAQs',
    path: ROUTES.FAQ,
    icon: getIcon('mdi:email-newsletter'),
  },
];

export default navConfig;
