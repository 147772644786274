import { ROUTES } from 'src/router/routes';
import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    path: ROUTES.DASHBOARD,
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    id: 'users',
    title: 'Gamers',
    path: ROUTES.PARTNER_USERS,
    icon: getIcon('dashicons:games'),
    // children: [
    //   { id: 'usersList', title: 'Users List', path: ROUTES.USERS, icon: getIcon('eva:people-fill') },
    //   { id: 'wallets', title: 'User Wallets', path: ROUTES.USERS_WALLET, icon: getIcon('akar-icons:wallet') },
    //   { id: 'reported', title: 'Reported Users', path: ROUTES.REPORTED_USERS, icon: getIcon('ic:round-report') },
    // ],
  },
  {
    id: 'contests',
    title: 'Contests',
    path: ROUTES.PARTNER_CONTESTS,
    icon: getIcon('eva:bar-chart-outline'),
    // children: [
    //   { id: 'list', title: 'Contest List', path: ROUTES.CONTESTS, icon: getIcon('akar-icons:wallet') },
    //   {
    //     id: 'stats',
    //     title: 'Question Stats',
    //     path: ROUTES.QUESTION_STATS,
    //     icon: getIcon('akar-icons:wallet'),
    //   },
    //   { id: 'form', title: 'Contest Form', path: ROUTES.CONTESTS_FORMS, icon: getIcon('akar-icons:wallet') },
    //   {
    //     id: 'wallpapers',
    //     title: 'Contest Wallpapers',
    //     path: ROUTES.CONTESTS_WALLPAPERS,
    //     icon: getIcon('akar-icons:wallet'),
    //   },
    //   {
    //     id: 'purchase',
    //     title: 'Wallpaper Purchase',
    //     path: ROUTES.WALLPAPER_PURCHASE,
    //     icon: getIcon('icon-park-solid:buy'),
    //   },
    //   {
    //     id: 'instructions',
    //     title: 'Contest Instructions',
    //     path: ROUTES.CONTESTS_INSTRUCTIONS,
    //   },
    // ],
  },
 
];

export default navConfig;
