import React, { useEffect, useState } from 'react';
import './index.css';
import {
  Typography,
  Grid,
  Button,
  Switch,
  TextField,
  Avatar,
  IconButton,
  colors,
  CircularProgress,
} from '@mui/material';
// import Select from 'react-select';
import { useFormik, Form, FormikProvider } from 'formik';
// import { gameQuizSchema } from 'src/utils/validationSchemas';
import Iconify from 'src/components/Iconify';
import { GAME_QUIZ_TYPE_OPTIONS } from 'src/contants/contants';
import { APIs } from 'src/apis/client';
import { addEditApi } from 'src/apis/admin';
import { toast } from 'react-toastify';

const initialValues = {
  gameOfWeek: false,
  emailSubject: '',
  emailBodyContent: '',
  emailImage: '',
};

/*Styles from React Select */

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 55,
      border: '',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

const GameWeekModal = ({ closeModal, selectedGame }) => {
  const [selectedData, setSelectedData] = useState({});
  const [imageLoading, setImageLoading] = useState();
  const [emailImage, setEmailImage] = useState();
  useEffect(() => {
    let SelectedData = {
      gameOfWeek: selectedGame?.gameOfWeek,
      emailSubject: selectedGame?.emailSubject,
      emailBodyContent: selectedGame?.emailBodyContent,
      emailImage: selectedGame?.emailImage,
    };
    setSelectedData(SelectedData);
  }, []);
  /*FORM SUBMIT HANDLER */
  const handleEmailImageChange = (e) => {
    if (e?.target?.files?.[0]) {
      setEmailImage(e?.target?.files?.[0]);
    }
  };

  const uploadEmailImage = () => {
    setImageLoading(true);
    const data = new FormData();
    data.append('file', emailImage);
    addEditApi(APIs.UPLOAD_IMAGE, data)
      .then((result) => {
        setFieldValue('emailImage', result?.profileImg);
        setEmailImage(null);
      })
      .finally(() => setImageLoading(false));
  };
  const handleFormSubmit = (payload) => {
    let ApiPayload = {
      ...payload,
      gameId: selectedGame?.id,
    };

    addEditApi(APIs.GAME_OF_WEEK, { ...ApiPayload }).then(() => {
      toast.success('Updated Successfully');
      closeModal();
    });
  };

  /*FORMIK INTIMIALIZATION */

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: selectedData || initialValues,
    // validationSchema: gameQuizSchema,
    onSubmit: handleFormSubmit,
  });

  /*DISTRCUTING FORMIK  */
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        <div className="modalContainer">
          <div className="modalheadingrow">
            <Typography variant="h4" gutterBottom>
              {`${selectedGame?.gameName} - Game Of Week`}
            </Typography>
          </div>
          <Grid className="formContainer">
            <Grid container spacing={4} sx={{ alignItems: 'center' }}>
              <Grid item xs={8}>
                <Typography> Game Of Week</Typography>
              </Grid>
              <Grid item xs={4}>
                <Switch
                  checked={values?.gameOfWeek}
                  onChange={(date) => {
                    setFieldValue('gameOfWeek', date?.target?.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Grid>
              <Grid item xs={16}>
                <TextField fullWidth label="Email Subject" {...getFieldProps('emailSubject')} />
              </Grid>
              <Grid item xs={16}>
                <TextField fullWidth label="Email Body Content" {...getFieldProps('emailBodyContent')} />
              </Grid>
            </Grid>
            <Grid item xs={16} sx={{ mt: 2 }}>
              <Typography>Email Image</Typography>
              {(values?.emailImage || emailImage) && (
                <Avatar
                  alt={'N'}
                  src={emailImage ? URL.createObjectURL(emailImage) : values?.emailImage}
                  sx={{ width: 100, height: 100 }}
                />
              )}
              <input type="file" accept="image/*" onChange={handleEmailImageChange} />
              {emailImage && (
                <>
                  {imageLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Button
                        onClick={uploadEmailImage}
                        size="small"
                        variant="outlined"
                        startIcon={<Iconify icon="eva:save-outline" />}
                      >
                        upload Image
                      </Button>
                      <IconButton
                        size="large"
                        sx={{ ml: 2, color: colors.red[500] }}
                        onClick={() => setEmailImage(null)}
                      >
                        <Iconify icon="eva:minus-square-fill" />
                      </IconButton>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>

          <div className="modalFooterContainer">
            <Typography variant="h4" gutterBottom>
              <Button onClick={closeModal} style={{ marginRight: '10px' }}>
                Cancel
              </Button>

              <Button variant="contained" type="submit" startIcon={<Iconify icon="eva:save-outline" />}>
                Update
              </Button>
            </Typography>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default GameWeekModal;
