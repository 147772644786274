import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Card, Container, Typography } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import Page from '../../../components/Page';
import Logo from '../../../components/Logo';
import { useFormik, Form, FormikProvider } from 'formik';
import {  Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {  handlePartnerLogin } from 'src/redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import {  emailValidationSchema, otpValidationSchema } from 'src/utils/validationSchemas';
import { addEditApi } from 'src/apis/admin';
import { PartnerAuthAPIs } from 'src/apis';
import { toast } from 'react-toastify';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function PartnerLogin() {
  
  const mdUp = useResponsive('up', 'md');
  const dispatch = useDispatch();
  const [hasOtp, setHasOtp] = useState();

  const getOtpHandler = (payload) => {
    addEditApi(PartnerAuthAPIs.GET_OTP, payload).then((res) => {
      setHasOtp(res);
      setSubmitting(false);
      toast.success(res?.message);
    });
  };

  const handleFormSubmit = (payload) => {
    dispatch(handlePartnerLogin(payload));
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: hasOtp ? otpValidationSchema : emailValidationSchema,
    onSubmit: hasOtp ? handleFormSubmit : getOtpHandler,
  });
  const { errors, touched, handleSubmit, getFieldProps, setSubmitting, isSubmitting  } = formik;

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome ShotCaller Partner
            </Typography>
            <img src="/static/illustrations/illustration_login.png" alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Sign in
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    // autoComplete="username"
                    type="email"
                    label="Enter Email address"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  {hasOtp && (
                    <TextField
                      fullWidth
                      type="number"
                      label="Enter Code shared via Email"
                      {...getFieldProps('otp')}
                      error={Boolean(touched.otp && errors.otp)}
                      helperText={touched.otp && errors.otp}
                    />
                  )}
                </Stack>
                {/* <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
                  <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
                    Forgot password?
                  </Link>
                </Stack> */}
                <LoadingButton
                  sx={{ my: 2 }}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {hasOtp ? 'Login' : 'Get Login Code'}
                </LoadingButton>
              </Form>
            </FormikProvider>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
