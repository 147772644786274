import React, { useState } from 'react';
import {
  Card,
  TextField,
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  Tooltip,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import Iconify from 'src/components/Iconify';
import { addEditApi, listApi } from 'src/apis/admin';
import { APIs } from 'src/apis';
import { generatorlisitngSchema } from 'src/utils/validationSchemas';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { confirmAlert } from 'react-confirm-alert';
import CachedIcon from '@mui/icons-material/Cached';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ImageModal from '../questionBankList/imageModal';

const GENERATED_CONTANT = {
  OPTION_IMAGE: 'optionImage',
  HEADER_IMAGE: 'headerImage',
};

const BUTTTON_COSNTANT = [
  {
    label: 'Reset',
    variant: 'outlined',
    type: 'button',
    style: { marginLeft: '20px' },
    identifier: 1,
    startIcon: <CachedIcon />,
  },
  {
    label: 'Save',
    variant: 'contained',
    type: 'button',
    identifier: 2,
    startIcon: <CheckCircleIcon />,
    style: { marginLeft: '20px' },
  },
];

const TYPE_OPTIONS = [
  { label: 'GENERAL TYPE', value: 'GENERAL_TYPE' },
  { label: 'IMAGE TYPE', value: 'IMAGE_TYPE' },
];
const QUESTION_TYPE = [
  { label: 'Skill Based', value: '1' },
  { label: 'Non - Skill', value: '2' },
];
const ANSWERE_TYPE = [
  { label: 'NOW', value: 'NOW' },
  { label: 'EOG', value: 'EOG' },
  { label: 'EOP', value: 'EOP' },
];

const COMMONN_FIELD = [
  { label: 'Type', identifier: 'type', options: TYPE_OPTIONS },
  { label: 'Question Type', identifier: 'questionType', options: QUESTION_TYPE },
  { label: 'Answere Calculated', identifier: 'answerCalculated', options: ANSWERE_TYPE },
  { label: 'Category', identifier: 'category', type: 'text', isBasic: true },
  { label: 'Brand', identifier: 'brand', type: 'text', isBasic: true },
  { label: 'Timer', identifier: 'timer', type: 'number', isBasic: true },
  { label: 'TimerPoints', identifier: 'timerPoints', type: 'number', isBasic: true },
  { label: 'Points', identifier: 'points', type: 'number', isBasic: true },
];

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 55,
      border: '',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

const Listing = ({ listData, topic, subtopic }) => {
  const [loading, setLoading] = useState(false);
  const [clickedIndex, setClickedIndex] = useState('');
  const [loadingIndex, setLoadingIndex] = useState('');
  const [imageError, setImageError] = useState({
    outerIndex: [],
    innerIndex: [],
  });
  const [lightBoxImages, setLightBoxImages] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [headerIndex, setHeaderIndex] = useState('');
  const [headerText, setHeaderText] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [headerImageErrorIndex, setHeaderImageErrorIndex] = useState([]);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);
  const [imageCount, setImageCount] = useState(0);

  const handleFormSubmit = (payload) => {
    setImageError({
      outerIndex: [],
      innerIndex: [],
    });

    setHeaderImageErrorIndex([]);
    let error = [];
    let headerError = [];
    payload?.question?.map((obj, index) => {
      if (obj?.isImageGenerated) {
        obj?.choices?.map((innerObj, innerIndex) => {
          if (innerObj?.selectedImage === '') {
            error.push(true);
            setImageError((prev) => ({
              outerIndex: [...prev.outerIndex, index],
              innerIndex: [...prev.innerIndex, innerIndex],
            }));
          }
        });
      }
    });

    payload?.question?.map((obj, i) => {
      if (obj?.selectedImage === '' && obj?.isHeaderImageGenerated) {
        headerError.push(true);
        setHeaderImageErrorIndex((prev) => [...prev, i]);
      }
    });

    if (error.length === 0 && !error.includes(true) && headerError.length === 0 && !headerError.includes(true)) {
      const updatedPayload = payload?.question?.filter((quesObj, i) => quesObj?.questionChecked === true);
      const payloadWithCommonValue = updatedPayload.map((obj, i) => {
        return {
          ...obj,
          nameOptions: obj?.isImageGenerated
            ? obj?.choices?.map((innerObj, i) => {
                return innerObj?.option;
              })
            : [],
          choices: obj?.choices?.map((innerObj, i) => {
            if (innerObj?.selectedImage !== '' && obj?.isImageGenerated) {
              return innerObj?.selectedImage;
            } else {
              return innerObj?.option;
            }
          }),
          questionHeader: obj.questionHeader ? obj.questionHeader : '',
          questionHImage: obj?.isHeaderImageGenerated ? obj?.selectedImage : null,
          type: obj?.isImageGenerated ? TYPE_OPTIONS?.[1]?.value : payload?.type?.value,
          questionType: payload?.questionType?.value,
          answerCalculated: payload?.answerCalculated?.value,
          category: payload.category,
          brand: payload.brand,
          timer: Number(payload.timer),
          timerPoints: Number(payload.timerPoints),
          points: Number(payload.points),
          correct: obj?.answer,
          source: obj?.source || null,
          topic: obj?.topic,
          subtopic: obj?.subtopic,
        };
      });
      const newArray = payloadWithCommonValue.map((item) => {
        delete item.answer;
        delete item.isHeaderImageGenerated;
        delete item.isImageGenerated;
        delete item.originalImages;
        delete item.images;
        delete item.selectedImage;
        // delete item.questionHeader;

        return item;
      });

      confirmAlert({
        title: 'Alert',
        message: `You have select ${newArray?.length} questions to save in Question Bank. Please confirm !!`,
        buttons: [
          {
            label: 'Confirm',
            onClick: () => {
              addEditApi(APIs.ADD_GENERATED_QUESTION, { question: newArray }).then((res) =>
                toast.success('Saved Successfully')
              );
            },
          },
          {
            label: 'Check again',
          },
        ],
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      question: listData,
      type: TYPE_OPTIONS?.[0],
      questionType: QUESTION_TYPE?.[0],
      answerCalculated: ANSWERE_TYPE?.[0],
      category: topic,
      brand: subtopic,
      timer: '20',
      timerPoints: '25',
      points: '500',
    },
    validationSchema: generatorlisitngSchema,
    onSubmit: handleFormSubmit,
  });

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;

  const GenerateButtonHandler = (listObj, index, identifier) => {
    if (identifier === GENERATED_CONTANT.OPTION_IMAGE) {
      setLoadingIndex(index);
      let customChoiceArray = listObj?.choices.map((obj, i) => {
        return obj?.option;
      });
      listApi(APIs.GENERATE_IMAGE_AI, { options: customChoiceArray }).then((res) => {
        let newData = listObj?.choices?.map((obj, i) => {
          const matchingData = res?.data?.[obj.option] || [];
          const images = matchingData.map((data) => data.thumbnail);
          const originalImages = matchingData.map((data) => data.original);
          const imagePageCount = 0;
          return { ...obj, images, originalImages, imagePageCount };
        });
        setFieldValue(`question.${[index]}.choices`, newData);
        setFieldValue(`question.${[index]}.isImageGenerated`, true);
        // setFieldValue(`question.${[innerIndex]}.choices.${[innerIndex]}.imagePageCount`, 0);
        setLoadingIndex('');
        toast.success('Images Generated Successfully');
      });
    }
    if (identifier === GENERATED_CONTANT.HEADER_IMAGE) {
      setFieldValue(`question.${[index]}.isHeaderImageGenerated`, true);
      setSubmitLoading(true);
      listApi(APIs.GENERATE_IMAGE_AI, { headerText: listObj.questionHeader }).then((res) => {
        setFieldValue(`question.${[index]}.isHeaderImageGenerated`, true);
        setFieldValue(
          `question.${[index]}.images`,
          res?.data.map((keyValue, i) => {
            return keyValue?.thumbnail;
          })
        );
        setFieldValue(
          `question.${[index]}.originalImages`,
          res?.data.map((keyValue, i) => {
            return keyValue?.original;
          })
        );

        toast.success('Images Generated Successfully');
        setFieldValue(`question.${[index]}.questionHeader`, headerText);
        setFieldValue(`question.${[index]}.headerImgGenCount`, 0);
        // setHeaderText('');
        setHeaderIndex('');
        setSubmitLoading(false);
      });
    }
  };

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };

  return (
    <Card style={{ padding: '20px 20px 20px 20px' }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} padding={2} style={{ paddingTop: '0px', paddingBottom: '20px' }}>
            {COMMONN_FIELD.map((field, fieldIndex) => {
              return (
                <Grid item xs={12} md={3}>
                  {field?.isBasic ? (
                    <TextField
                      fullWidth
                      label={field.label}
                      type={field.type}
                      defaultValue={values?.[field.identifier]}
                      onChange={(e) => {
                        setFieldValue([field.identifier], e.target.value);
                      }}
                      error={Boolean(touched?.[field.identifier] && errors?.[field.identifier])}
                      helperText={
                        errors?.[field.identifier] ? (
                          <div style={{ color: 'red' }}>{errors?.[field.identifier]}</div>
                        ) : (
                          <div style={{ visibility: 'hidden' }}>hidden</div>
                        )
                      }
                    />
                  ) : (
                    <Select
                      cacheOptions
                      defaultOptions
                      placeholder={field.label}
                      styles={customStyles}
                      options={field.options}
                      name={field.identifier}
                      value={values?.[field.identifier]}
                      getOptionLabel={(v) => v?.label}
                      getOptionValue={(v) => v?.value}
                      onChange={(data) => setFieldValue([field.identifier], data)}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
          {values?.question?.map((listObj, i) => {
            return clickedIndex === i ? (
              <div
                style={{
                  border: '1px solid black',
                  borderRadius: '10px',
                  padding: '20px 20px 20px 20px',
                  margin: '20px 0px 20px 0px',
                }}
              >
                <Grid container spacing={2} padding={2}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      key={i}
                      fullWidth
                      label="Question"
                      type="text"
                      defaultValue={values?.question?.[i]?.question}
                      onChange={(e) => {
                        setFieldValue(`question.${[i]}.question`, e.target.value);
                      }}
                      error={Boolean(touched?.question?.[i]?.question && errors?.question?.[i]?.question)}
                      helperText={
                        errors?.question?.[i]?.question ? (
                          <div style={{ color: 'red' }}>{errors?.question?.[i]?.question}</div>
                        ) : (
                          <div style={{ visibility: 'hidden' }}>hidden</div>
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} padding={2}>
                  {listObj?.choices?.map((choice, innerIndex) => {
                    return (
                      <Grid item xs={12} md={6} container direction="row" alignItems="center">
                        <Radio
                          checked={innerIndex === listObj.answer}
                          onChange={() => {
                            setFieldValue(`question[${i}].answer`, innerIndex);
                          }}
                        />
                        <TextField
                          key={innerIndex}
                          // fullWidth
                          label={`Choice ${innerIndex + 1}`}
                          type="text"
                          value={choice?.option}
                          onChange={(e) => {
                            setFieldValue(`question.${[i]}.choices.${[innerIndex]}.option`, e.target.value);
                          }}
                          error={Boolean(
                            touched?.question?.[i]?.choices?.[innerIndex]?.option &&
                              errors?.question?.[i]?.choices?.[innerIndex]?.option
                          )}
                          helperText={
                            errors?.question?.[i]?.choices?.[innerIndex]?.option ? (
                              <div style={{ color: 'red' }}>{errors?.question?.[i]?.choices?.[innerIndex]?.option}</div>
                            ) : (
                              <div style={{ visibility: 'hidden' }}>hidden</div>
                            )
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container spacing={2} padding={2}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      key={i}
                      fullWidth
                      multiline
                      rows={5}
                      maxRows={4}
                      label="Explanation"
                      type="text"
                      defaultValue={values?.question?.[i]?.explanation}
                      onChange={(e) => {
                        setFieldValue(`question.${[i]}.explanation`, e.target.value);
                      }}
                      error={Boolean(touched?.question?.[i]?.explanation && errors?.question?.[i]?.explanation)}
                      helperText={
                        errors?.question?.[i]?.explanation ? (
                          <div style={{ color: 'red' }}>{errors?.question?.[i]?.explanation}</div>
                        ) : (
                          <div style={{ visibility: 'hidden' }}>hidden</div>
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} padding={2}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      key={i}
                      fullWidth
                      label="Source Url"
                      type="text"
                      defaultValue={values?.question?.[i]?.source}
                      onChange={(e) => {
                        setFieldValue(`question.${[i]}.source`, e.target.value);
                      }}
                      error={Boolean(touched?.question?.[i]?.source && errors?.question?.[i]?.source)}
                      helperText={
                        errors?.question?.[i]?.source ? (
                          <div style={{ color: 'red' }}>{errors?.question?.[i]?.source}</div>
                        ) : (
                          <div style={{ visibility: 'hidden' }}>hidden</div>
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {BUTTTON_COSNTANT.map((btnObj, buttonIndex) => {
                    return (
                      <Button
                        variant={btnObj?.variant}
                        type={btnObj?.type}
                        startIcon={btnObj?.startIcon}
                        style={btnObj?.style}
                        onClick={() => {
                          if (btnObj.identifier === 1) {
                            // RESET
                            setFieldValue(`question.${[i]}`, listObj);
                            setClickedIndex('');
                          } else {
                            if (Object.keys(errors).length === 0) {
                              setClickedIndex('');
                            }
                          }
                        }}
                      >
                        {btnObj.label}
                      </Button>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div style={{ marginBottom: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ fontWeight: 'bold' }}>{`${i + 1}. ${listObj.question}`}</div>
                    <div
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <ModeEditIcon
                        style={{ marginLeft: '10px', fontSize: '20px', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.preventDefault();
                          setClickedIndex(i);
                        }}
                      />
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setHeaderIndex(i);
                        }}
                        disabled={headerIndex !== '' || loadingIndex !== '' || submitLoading === true}
                        style={{ marginLeft: '10px' }}
                      >
                        Generate Header Images
                      </Button>
                      {listObj?.images?.length > 0 && (
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue(`question.${[i]}.isHeaderImageGenerated`, false);
                            setFieldValue(`question.${[i]}.images`, []);
                            setFieldValue(`question.${[i]}.originalImages`, []);
                            setFieldValue(`question.${[i]}.selectedImage`, '');
                            setFieldValue(`question.${[i]}.headerImgGenCount`, 0);
                            setFieldValue(`question.${[i]}.questionHeader`, '');
                            setHeaderText('');
                          }}
                          disabled={headerIndex !== '' || loadingIndex !== '' || submitLoading === true}
                          style={{ marginLeft: '10px', color: 'red' }}
                        >
                          Delete Header Images
                        </Button>
                      )}
                    </div>
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setFieldValue(`question.${[i]}.questionChecked`, e.target.checked);
                        }}
                        checked={values?.question?.[i]?.questionChecked}
                      />
                    }
                    label="Save Question"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setFieldValue(`question.${[i]}.generateImageAuto`, e.target.checked);
                        }}
                        checked={values?.question?.[i]?.generateImageAuto}
                      />
                    }
                    label="Generate Image Auto"
                  />
                </div>

                {headerIndex !== '' && headerIndex === i && (
                  <Grid container spacing={2} padding={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        label="Header Prompt"
                        type="text"
                        value={listObj?.questionHeader}
                        onChange={(e) => {
                          // setHeaderText(e.target.value);
                          setFieldValue(`question.${[i]}.questionHeader`, e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          GenerateButtonHandler(listObj, i, GENERATED_CONTANT.HEADER_IMAGE);
                        }}
                        disabled={!listObj.questionHeader}
                        style={{ marginLeft: '10px' }}
                      >
                        {submitLoading ? 'Submiting...' : 'Submit'}
                      </Button>

                      <Button
                        variant="contained"
                        onClick={(e) => {
                          setHeaderIndex('');
                          setHeaderText('');
                          setFieldValue(`question.${[i]}.headerImgGenCount`, 0);
                          setFieldValue(`question.${[i]}.questionHeader`, '');
                        }}
                        style={{ marginLeft: '10px' }}
                      >
                        {'Reset/Cancel'}
                      </Button>
                    </Grid>
                  </Grid>
                )}

                {listObj?.images?.length > 0 && (
                  // SHOWCASE OF HEADER IMAGES
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center', margin: '5px 0px' }}>
                      {listObj?.headerImgGenCount >= 1 && (
                        <Tooltip title="Previous images">
                          <ReadMoreIcon
                            style={{
                              marginLeft: '10px',
                              fontSize: '30px',
                              cursor: 'pointer',
                              color: 'red',
                              transform: 'scaleX(-1)',
                            }}
                            onClick={() => {
                              setFieldValue(`question.${i}.headerImgGenCount`, listObj?.headerImgGenCount - 1);
                            }}
                          />
                        </Tooltip>
                      )}
                      {listObj?.images
                        ?.slice(listObj?.headerImgGenCount * 4, listObj?.headerImgGenCount * 4 + 4)
                        ?.map((img, outerIndex) => {
                          return (
                            <div
                              style={{
                                width: '100px',
                                padding: '1px',
                                border: `3px solid ${listObj?.selectedImage === img ? '#2065d2' : 'white'}`,
                                height: '100px',
                                marginLeft: '10px',
                                cursor: 'pointer',
                                position: 'relative',
                              }}
                            >
                              <img
                                src={img}
                                style={{
                                  width: '100px',
                                  height: '92px',
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFieldValue(`question.${[i]}.selectedImage`, img);
                                }}
                              />
                              <div
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  position: 'absolute',
                                  top: '1px',
                                  right: '1px',
                                  padding: '2px',
                                  backgroundColor: '#00000060',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <FullscreenIcon onClick={() => showFullImage(img)} style={{ fill: '#ffffff' }} />
                              </div>
                            </div>
                          );
                        })}

                      <Tooltip title="Show more images">
                        <ReadMoreIcon
                          style={{
                            marginLeft: '10px',
                            fontSize: '30px',
                            cursor: 'pointer',
                            color: 'red',
                          }}
                          onClick={() => {
                            setFieldValue(`question.${i}.headerImgGenCount`, listObj?.headerImgGenCount + 1);
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                )}

                <div
                  style={{
                    fontSize: '13px',
                    width: '90%',
                    marginBottom: '0px',
                    wordWrap: 'break-word',
                  }}
                >
                  {`( ${listObj.explanation} )`}
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      GenerateButtonHandler(listObj, i, GENERATED_CONTANT.OPTION_IMAGE);
                    }}
                    disabled={headerIndex !== '' || loadingIndex !== '' || submitLoading === true}
                    style={{ marginLeft: '10px' }}
                  >
                    {loadingIndex === i ? 'Generating Images...' : 'Generate Images'}
                  </Button>

                  {`${listObj?.source || ''}`}

                  {listObj?.isImageGenerated && (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        let newArray = values?.question?.[i]?.choices?.map((obj) => ({ option: obj.option }));
                        setFieldValue(`question.${i}.isImageGenerated`, false);
                        setFieldValue(`question.${i}.choices`, newArray);
                      }}
                      disabled={headerIndex !== '' || loadingIndex !== '' || submitLoading === true}
                      style={{ marginLeft: '10px', color: 'red' }}
                    >
                      Delete Images
                    </Button>
                  )}
                </div>

                <RadioGroup>
                  {listObj?.choices?.map((item, ind) => (
                    <FormControlLabel
                      style={{ marginTop: '20px' }}
                      key={item.option}
                      value={item.option}
                      control={<Radio />}
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ minWidth: '200px', width: '200px', wordWrap: 'break-word' }}>
                            {item?.option}
                          </div>
                          <div onClick={(e) => e.preventDefault()}>
                            <p
                              style={{
                                fontSize: '12px',
                                color: 'red',
                                marginBottom: '2px',
                                visibility:
                                  imageError.outerIndex.includes(i) && imageError.innerIndex.includes(ind)
                                    ? 'visible'
                                    : 'hidden',
                              }}
                            >
                              Select Image from This
                            </p>

                            {item?.images?.length > 0 && (
                              <div style={{ display: 'flex', marginLeft: '20px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {item?.imagePageCount >= 1 && (
                                    <Tooltip title="Previous images">
                                      <ReadMoreIcon
                                        style={{
                                          marginLeft: '10px',
                                          fontSize: '30px',
                                          cursor: 'pointer',
                                          color: 'red',
                                          transform: 'scaleX(-1)',
                                        }}
                                        onClick={() => {
                                          setFieldValue(
                                            `question.${[i]}.choices.${[ind]}.imagePageCount`,
                                            item?.imagePageCount - 1
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                  {item?.images
                                    ?.slice(item?.imagePageCount * 4, item?.imagePageCount * 4 + 4)
                                    ?.map((img, innerIndex) => {
                                      return (
                                        <div
                                          style={{
                                            width: '100px',
                                            padding: '1px',
                                            border: `3px solid ${item?.selectedImage === img ? '#2065d2' : 'white'}`,
                                            height: '100px',
                                            marginLeft: '10px',
                                            cursor: 'pointer',
                                            position: 'relative',
                                          }}
                                        >
                                          <img
                                            src={img}
                                            style={{
                                              width: '100px',
                                              height: '92px',
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setFieldValue(`question.${[i]}.choices.${[ind]}.selectedImage`, img);
                                            }}
                                          />
                                          <div
                                            style={{
                                              width: '30px',
                                              height: '30px',
                                              position: 'absolute',
                                              top: '1px',
                                              right: '1px',
                                              padding: '2px',
                                              backgroundColor: '#00000060',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                            }}
                                          >
                                            <FullscreenIcon
                                              onClick={() => showFullImage(img)}
                                              style={{ fill: '#ffffff' }}
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}

                                  <Tooltip title="Show more images">
                                    <ReadMoreIcon
                                      style={{
                                        marginLeft: '10px',
                                        fontSize: '30px',
                                        cursor: 'pointer',
                                        color: 'red',
                                      }}
                                      onClick={() => {
                                        setFieldValue(
                                          `question.${[i]}.choices.${[ind]}.imagePageCount`,
                                          item?.imagePageCount + 1
                                        );
                                      }}
                                    />
                                  </Tooltip>

                                  <TextField
                                    style={{ marginLeft: '20px' }}
                                    label="Image link"
                                    type="text"
                                    defaultValue={values?.question?.[i]?.choices?.[ind]?.selectedImage}
                                    value={values?.question?.[i]?.choices?.[ind]?.selectedImage}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      setFieldValue(`question.${[i]}.choices.${[ind]}.selectedImage`, e.target.value);
                                    }}
                                  />

                                  {/* <RemoveRedEyeIcon
                                    style={{ marginLeft: '10px' }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setLightBoxImages(item?.originalImages);
                                    }}
                                  /> */}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      }
                      checked={listObj?.answer === ind ? true : false}
                      onChange={(e) => setFieldValue(`question.${[i]}.answer`, ind)}
                    />
                  ))}
                </RadioGroup>
              </div>
            );
          })}
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <Button
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
                disabled={loading}
              >
                {loading ? 'submiting' : 'submit'}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      <Dialog open={lightBoxImages?.length > 0} fullWidth>
        <Lightbox
          mainSrc={lightBoxImages[photoIndex]}
          nextSrc={lightBoxImages[(photoIndex + 1) % lightBoxImages.length]}
          prevSrc={lightBoxImages[(photoIndex + lightBoxImages.length - 1) % lightBoxImages.length]}
          onCloseRequest={() => setLightBoxImages([])}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + lightBoxImages.length - 1) % lightBoxImages.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % lightBoxImages.length)}
        />
      </Dialog>
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </Card>
  );
};

export default Listing;
