import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';
import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Page from 'src/components/Page';
import AsyncSelect from 'react-select/async';
import { Form, FormikProvider, useFormik } from 'formik';
import { styled } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { newNotificationsSchema } from 'src/utils/validationSchemas';
import { addEditApi, listApi } from 'src/apis/admin';
import { APIs } from 'src/apis';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { ROUTES } from 'src/router/routes';
import BackButton from 'src/components/backButton';

const options = [
  { value: 1, label: 'Send Email Only' },
  { value: 2, label: 'Send Push Only' },
  { value: 3, label: 'Send Both' },
];

const StyledInput = styled('input')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'block',
}));

const initialValues = {
  notificationType: '',
  greetingText: '',
  bodyText: '',
  buttonText: '',
  customHeader: '',
  emailSubject: '',
  partnerId: '',
  pushNotificationText: '',
  scheduleDateTime: '',
  url: '',
  customImageUrl: '',
  sendToAll: false,
  sendToPartnerUsers: false,
  excludePartners: [],
};

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 56,
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

const AddEditSchedulerNotifications = ({ isEdit }) => {
  const [imageLoading, setImageLoading] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const cloneId = searchParams.get('clone');

  useEffect(() => {
    if (id || cloneId) {
      getData(id);
    }
  }, [id]);

  const getData = (id) => {
    const params = { type: 1, id };

    listApi(APIs.SCHEDULER, params).then(async (response) => {
      const obj = {
        ...response?.data[0]?.data,
      };
      obj.scheduleDateTime = new Date(response?.data[0]?.dateTime);
      obj.type = response?.data[0]?.type;
      if (obj.partnerData) obj.partnerId = { label: obj.partnerData.name, value: obj.partnerData.id };

      obj.notificationType = options.filter((o) => o.value === obj.type);
      obj.notificationType = obj.notificationType[0];
      obj.excludePartners = obj?.excludePartners?.map((item) => ({
        label: `${item?.name}`,
        value: item.id,
      }));
      setData(obj);
    });
  };

  const handleFormSubmit = (values) => {
    const formData = {
      dateTime: values.scheduleDateTime,
      data: {
        greetingText: values?.greetingText,
        bodyText: values?.bodyText,
        buttonText: values?.buttonText,
        emailSubject: values?.emailSubject,
        customHeader: values?.customHeader,
        pushNotificationText: values?.pushNotificationText,
        type: values?.notificationType?.value,
        partnerId: values?.partnerId?.value,
        url: values?.url,
        customImageUrl: values?.customImageUrl,
        sendToAll: values?.sendToAll,
        sendToPartnerUsers: values?.sendToPartnerUsers,
      },
    };

    if (values?.sendToAll && values?.excludePartners.length)
      formData.data.excludePartners = values?.excludePartners.map((partner) => partner.value);
    else formData.data.excludePartners = [];

    if (id) formData.id = id;

    addEditApi(APIs.SCHEDULER_NOTIFICATIONS, formData).then((result) => {
      toast.success(`${id ? 'Updated' : 'Added'} Successfully`);
      navigate(ROUTES.SCHEDULER_NOTIFICATIONS);
    });
  };

  /* Image Upload Handler */
  const uploadImage = (e, type) => {
    setImageLoading(true);
    const file = e?.target?.files?.[0];
    const data = new FormData();
    data.append('file', file);

    const fieldName = type === 1 ? 'url' : 'customImageUrl';

    addEditApi(APIs.UPLOAD_IMAGE, data)
      .then((result) => {
        formik.setFieldValue(fieldName, result?.profileImg);
      })
      .finally(() => setImageLoading(false));
  };

  const partnerOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_PARTNERS, { search: inputValue })
      .then((result) => {
        const filter = result?.data?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));

        resolve(filter);
      })
      .catch((error) => {
        resolve([]);
      });
  };

  const loadPartners = debounce(partnerOptions, 300);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data || initialValues,
    validationSchema: newNotificationsSchema,
    onSubmit: handleFormSubmit,
  });

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <Page>
      <Container>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                {isEdit ? 'Edit Notifications' : ' Add Notifications'}
              </Typography>
              <div>
                <BackButton onClick={() => navigate(ROUTES.SCHEDULER_NOTIFICATIONS)} />

                <Button variant="contained" type="submit" startIcon={<Iconify icon="eva:save-outline" />}>
                  Save Notifications
                </Button>
              </div>
            </Stack>
            <Card>
              <Box sx={{ flexGrow: 1, padding: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions={options}
                      placeholder="Notification Type"
                      name="notificationType"
                      styles={customStyles}
                      value={values.notificationType}
                      onChange={(selectedOption) => {
                        setFieldValue('notificationType', selectedOption);
                      }}
                      onBlur={() => formik.setFieldTouched('notificationType', true)}
                    />
                    {touched.notificationType && errors.notificationType && (
                      <Typography variant="caption" color="red">
                        {errors.notificationType}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Greeting Text"
                      name="greetingText"
                      {...getFieldProps('greetingText')}
                      error={Boolean(touched.greetingText && errors.greetingText)}
                      helperText={touched.greetingText && errors.greetingText}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Email Subject"
                      name="emailSubject"
                      {...getFieldProps('emailSubject')}
                      error={Boolean(touched.emailSubject && errors.emailSubject)}
                      helperText={touched.emailSubject && errors.emailSubject}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Button Text"
                      name="buttonText"
                      {...getFieldProps('buttonText')}
                      error={Boolean(touched.buttonText && errors.buttonText)}
                      helperText={touched.buttonText && errors.buttonText}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      maxRows={4}
                      label="Body Text"
                      name="bodyText"
                      {...getFieldProps('bodyText')}
                      error={Boolean(touched.bodyText && errors.bodyText)}
                      helperText={touched.bodyText && errors.bodyText}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <label style={{ fontSize: '14px' }}>Select Partner</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Select Partner"
                      styles={customStyles}
                      loadOptions={loadPartners}
                      value={values?.partnerId}
                      onChange={(selectedOption) => {
                        setFieldValue('partnerId', selectedOption);
                      }}
                    />
                    {touched.partnerId && errors.partnerId && (
                      <Typography variant="caption" color="red">
                        {errors.partnerId}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Push Notification Text"
                      name="pushNotificationText"
                      {...getFieldProps('pushNotificationText')}
                      error={Boolean(touched.pushNotificationText && errors.pushNotificationText)}
                      helperText={touched.pushNotificationText && errors.pushNotificationText}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField fullWidth label="Custom Header" {...getFieldProps('customHeader')} name="customHeader" />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControlLabel
                      control={<Switch />}
                      label="Send To All"
                      checked={values?.sendToAll}
                      onChange={(e) => {
                        setFieldValue('sendToAll', e.target.checked);
                        if (e.target.checked) {
                          setFieldValue('sendToPartnerUsers', false);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControlLabel
                      control={<Switch />}
                      label="Send to partner users"
                      checked={values.sendToPartnerUsers}
                      onChange={(e) => {
                        setFieldValue('sendToPartnerUsers', e.target.checked);
                        if (e.target.checked) {
                          setFieldValue('sendToAll', false);
                        }
                      }}
                    />
                  </Grid>
                  {formik.values.sendToAll && (
                    <Grid item xs={12} md={6}>
                      <label style={{ fontSize: '14px' }}>Exclude Partners</label>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        isMulti
                        placeholder="Exclude Partners"
                        styles={customStyles}
                        loadOptions={loadPartners}
                        value={values?.excludePartners}
                        onChange={(option) => setFieldValue('excludePartners', option || [])}
                      />
                      {/* {touched.excludePartners && errors.excludePartners && (
                        <Typography variant="caption" color="red">
                          {errors.excludePartners}
                        </Typography>
                      )} */}
                    </Grid>
                  )}
                  <Grid item xs={12} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        value={values?.scheduleDateTime}
                        label="Schedule Date time"
                        name="scheduleDateTime"
                        onChange={(date) => {
                          setFieldValue('scheduleDateTime', date);
                        }}
                        // disablePast={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={Boolean(touched.scheduleDateTime && errors.scheduleDateTime)}
                            helperText={touched.scheduleDateTime && errors.scheduleDateTime}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Grid container marginTop="20px" alignItems="center">
                  {imageLoading ? (
                    <CircularProgress sx={{ marginTop: '10px' }} />
                  ) : (
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2" gutterBottom>
                        Main Image
                      </Typography>
                      <Avatar alt={'N'} src={values.url} sx={{ width: 100, height: 100, marginBottom: '10px' }} />

                      <input type="file" accept="image/*" onChange={(e) => uploadImage(e, 1)} disabled={imageLoading} />
                    </Grid>
                  )}

                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle2" gutterBottom>
                      Custom Image
                    </Typography>

                    <Avatar
                      alt={'N'}
                      src={values?.customImageUrl}
                      sx={{ width: 100, height: 100, marginBottom: '10px' }}
                    />

                    <input type="file" accept="image/*" onChange={(e) => uploadImage(e, 3)} disabled={imageLoading} />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
};

export default AddEditSchedulerNotifications;
