import {
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Box,
  FormControl,
  MenuItem,
  TextField,
  Avatar,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { APIs } from 'src/apis';
import { addEditApi, listApi } from 'src/apis/admin';
import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import { styled } from '@mui/system';
import { toast } from 'react-toastify';
import MoreMenu from 'src/components/table/MoreMenu';
import { useSelector } from 'react-redux';
import ImageModal from 'src/pages/openAI/questionBankList/imageModal';
import debounce from 'lodash/debounce';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { instructionsSchema } from 'src/utils/validationSchemas';
import Label from 'src/components/Label';
import { confirmAlert } from 'react-confirm-alert';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const TABLE_HEAD = [
  { id: 'action', label: '', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'url', label: 'Image/GIF', alignRight: false },
  { id: 'topText', label: 'Top Text', alignRight: false },
  { id: 'bottomText', label: 'Bottom Text', alignRight: false },
  { id: 'buttonText', label: 'Button Text', alignRight: false },
];

const StyledInput = styled('input')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'block',
}));

export default function ContestsInstructions() {
  const [instructionsList, setInstructionsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [instructionType, setInstructionType] = useState('');
  const [topText, setTopText] = useState('');
  const [bottomText, setBottomText] = useState('');
  const [buttonText, setButtonText] = useState('I Understand');
  const [url, setUrl] = useState('');
  const [imageLoading, setImageLoading] = useState(false);
  const [id, setEditId] = useState();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [refresh, setRefresh] = useState(false);
  const user = useSelector((state) => state.auth);

  useEffect(() => {
    getInstructionData();
  }, [searchFilter, refresh]);

  const getInstructionData = () => {
    setLoading(true);
    listApi(APIs.CONTEST_INSTRUCTIONS_LISTING, { search: searchFilter })
      .then((response) => {
        setInstructionsList(response?.data);
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if(id) values.id = id;

    addEditApi(APIs.CONTEST_INSTRUCTIONS, values)
      .then((res) => {
        getInstructionData();
        setModalOpen(false);
        setEditId(null);
        toast.success(res?.data?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        // Handle error
      })
      .finally(() => {
        setSubmitting(false);
        setRefresh(true);
      });
  };

  const handleOpenModal = (data) => {
    setInstructionType(data?.type || '');
    setTopText(data?.topText || '');
    setBottomText(data?.bottomText || '');
    setButtonText(data?.buttonText || '');
    setUrl(data?.url || '');

    if (data) setEditId(data?.id);

    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleEditPermission =
    user && user.roles && user.roles.some((role) => role?.title === 'Contest Instructions' && role?.edit === true);

  const isSuperAdmin = user?.isSuperAdmin;

  const getGameTypeText = (type) => {
    switch (type) {
      case 1:
        return 'Non Contest ame';
      case 2:
        return 'Contest Game';
      case 3:
        return `Pick'Em`;
      case 4:
        return 'Trivia Custom Contest';
      default:
        return '';
    }
  };

  const debouncedFilter = debounce((value) => {
    setSearchFilter(value);
  }, 600);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debouncedFilter(event.target.value);
  };

  /* Image Upload Handler */
  const uploadImage = (e, formik) => {
    setImageLoading(true);
    const file = e?.target?.files?.[0];
    const data = new FormData();
    data.append('file', file);
    addEditApi(APIs.UPLOAD_IMAGE, data)
      .then((result) => {
        setUrl(result?.profileImg);
        formik.setFieldValue('url', result?.profileImg);
      })
      .catch((error) => {
        // Handle error
      })
      .finally(() => setImageLoading(false));
  };

  const isItemSelected = selected.indexOf(name) !== -1;

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };

  const MenuConfirmClick = (row, key) => {
    if (key === 'status') {
      const data = {
        id: row.id,
        type: 8,
        active: !row.isActive,
      };
      confirmAlert({
        title: <h6>Confirm for Status Change</h6>,
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              addEditApi(APIs.ADD_EDIT_BLOCK, data).then((result) => {
                toast.success('Updated Successfully');
                setRefresh(!refresh);
              });
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }
    if (key === 'delete') {
      const data = {
        id: row?.id,
        type: 10,
      };
      confirmAlert({
        title: <h6>Confirm for Delete?</h6>,
        message: 'Are you sure to do this. This action will not be revert back.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              addEditApi(APIs.DELETE_REQUEST, data).then((result) => {
                toast.success('Deleted Successfully');
                setRefresh(!refresh);
              });
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }
  };

  return (
    <Page title="Contests Instructions">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Instructions
          </Typography>
          <Button variant="contained" onClick={() => handleOpenModal()} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Instructions
          </Button>
        </Stack>
        <Card>
          <Grid container spacing={2} padding={1}>
            <Grid>
              <UserListToolbar numSelected={selected.length} filterName={search} onFilterName={handleSearch} />
            </Grid>
          </Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {instructionsList.map((list) => (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={list.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        selected={isItemSelected}
                      >
                        <TableCell align="right">
                          {(handleEditPermission || isSuperAdmin) && (
                            <MoreMenu
                              options={[
                                {
                                  label: 'Edit',
                                  icon: 'eva:edit-fill',
                                  onClick: () => {
                                    handleOpenModal(list);
                                  },
                                },
                                {
                                  label: list?.isActive ? 'Mark Inactive' : 'Mark Active',
                                  icon: 'eva:edit-fill',
                                  onClick: () => MenuConfirmClick(list, 'status'),
                                },
                                {
                                  label: 'Delete Instruction',
                                  icon: 'eva:trash-2-outline',
                                  onClick: () => MenuConfirmClick(list, 'delete'),
                                },
                              ]}
                            />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={list?.isActive ? 'success' : 'error'}>
                            {list?.isActive ? 'Active' : 'InActive'}
                          </Label>
                        </TableCell>
                        <TableCell align="left">{getGameTypeText(list.type)}</TableCell>
                        <TableCell align="left">
                          <Avatar
                            alt={''}
                            src={list.url}
                            onClick={() => showFullImage(list.url)}
                            style={{ cursor: 'pointer' }}
                          />
                        </TableCell>
                        <TableCell align="left">{list.topText}</TableCell>
                        <TableCell align="left">{list.bottomText}</TableCell>
                        <TableCell align="left">{list.buttonText}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>

        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-title" variant="h6" component="h2">
              New Instructions
            </Typography>
            <Formik
              initialValues={{
                instructionType: instructionType || '',

                topText: topText || '',
                bottomText: bottomText || '',
                buttonText: 'I Understand',
                url: url || '',
              }}
              validationSchema={instructionsSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <TextField
                      select
                      id="instruction-type"
                      name="instructionType"
                      label="Type"
                      value={formik.values.instructionType}
                      onChange={formik.handleChange}
                      error={formik.touched.instructionType && Boolean(formik.errors.instructionType)}
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem value={1}>Non contest game</MenuItem>
                      <MenuItem value={2}>Contest Game</MenuItem>
                      <MenuItem value={3}>Pickem</MenuItem>
                      <MenuItem value={4}>Trivia custom contest</MenuItem>
                    </TextField>
                    <ErrorMessage name="instructionType" component="div" style={{ color: 'red' }} />

                    <Field
                      as={TextField}
                      id="top-text"
                      label="Top Text"
                      variant="outlined"
                      fullWidth
                      sx={{ mt: 2 }}
                      name="topText"
                      value={formik.values.topText}
                      onChange={formik.handleChange}
                      error={formik.touched.topText && Boolean(formik.errors.topText)}
                    />
                    <ErrorMessage name="topText" component="div" style={{ color: 'red' }} />

                    <Field
                      as={TextField}
                      id="bottom-text"
                      label="Bottom Text"
                      variant="outlined"
                      fullWidth
                      sx={{ mt: 2 }}
                      name="bottomText"
                      value={formik.values.bottomText}
                      onChange={formik.handleChange}
                      error={formik.touched.bottomText && Boolean(formik.errors.bottomText)}
                    />
                    <ErrorMessage name="bottomText" component="div" style={{ color: 'red' }} />

                    <Field
                      as={TextField}
                      id="button-text"
                      label="Button Text"
                      variant="outlined"
                      fullWidth
                      sx={{ mt: 2 }}
                      name="buttonText"
                      value={formik.values.buttonText}
                      onChange={formik.handleChange}
                      error={formik.touched.buttonText && Boolean(formik.errors.buttonText)}
                    />
                    <ErrorMessage name="buttonText" component="div" style={{ color: 'red' }} />

                    {imageLoading ? (
                      <CircularProgress sx={{ marginTop: '10px' }} />
                    ) : (
                      <Grid item xs={12} md={4}>
                        <Avatar
                          alt={'N'}
                          src={formik.values.url}
                          sx={{ width: 100, height: 100, marginBottom: '10px', marginTop: '10px' }}
                        />

                        <StyledInput
                          type="file"
                          accept="image/*"
                          onChange={(e) => uploadImage(e, formik)}
                          disabled={imageLoading}
                        />
                      </Grid>
                    )}
                  </FormControl>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button sx={{ border: '1px solid #e0e0e0' }} onClick={handleCloseModal}>
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained" sx={{ ml: 2 }}>
                      Submit
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      </Container>
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </Page>
  );
}
