import Axios from 'axios';
import { toast } from 'react-toastify';
import { dispatchAction, store } from '../redux/store';
import { handleLogout } from '../redux/actions/auth';

export const BASE_URL = 'https://app.playshotcaller.com/shotcall/api/v1';

export const AuthAPIs = {
  LOGIN: '/admin/login',
};
export const PartnerAuthAPIs = {
  GET_OTP: '/partner/checkEmail',
  VERIFY_OTP: '/partner/verifyOtp',
};
export const APIs = {
  LIST_USERS: '/admin/list_users',
  LIST_PARTNER_USERS: '/admin/list_partner_users',
  LIST_USERS_POINTS: 'admin/user_partner_points_history',
  USER_CONTEST_HISTORY: '/admin/user_contest_history',
  LIST_REPORTED_USERS: '/admin/list_reported_users',
  LIST_WALLET_USERS: '/admin/list_wallet_users',
  LIST_CONTESTS: '/admin/list_contests',
  LIST_CONTESTS_BULK: '/admin/list_contests_bulk',
  ADD_EDIT_CONTEST: '/admin/add_edit_contest',
  ADD_EDIT_CONTEST_BULK: '/admin/add_edit_bulk_contest',
  UPDATE_SPECIAL_QUIZ_URLS: '/admin/updateImagesUrl',
  LIST_ADMINS: '/admin/list_admins',
  FOLLOW_PARTNER_EMAIL: 'admin/follow_partner_email',
  ADD_EDIT_ADMIN_DEFAULT : 'admin/addEditAdminDefaults',
  LIST_ADMIN_DEFAULT : 'admin/adminDefaults',
  LIST_PARTNERS: '/admin/list_business',
  PARTNER_DASHBOARD: 'partner/dashboard',
  PARTNER_TOP_CONTESTS: 'partner/topContests',
  LIST_PARTNERS_CONTEST: 'partner/list_contests',
  LIST_PARTNERS_REWARDS: 'admin/list_products',
  GENERATE_DEEP_LINK: '/admin/partner_deeplink',
  LIST_REWARD_TYPES: '/admin/list_rewards_types',
  ADD_EDIT_REWARD: '/admin/add_edit_reward_types',
  LIST_REWARDS_POSITIONS: '/admin/list_users',
  SCHEDULER: 'admin/schedulers',
  SCHEDULER_NOTIFICATIONS: 'admin/schedule_notifications',
  ADD_EDIT_ADMIN: '/admin/add_edit_admin',
  ADD_EDIT_PARTNER: '/admin/add_edit_business',
  ADD_EDIT_PARTNER_REWARDS: 'admin/add_edit_partner_product',
  LIST_SERIES: 'admin/list_series',
  ADD_EDIT_SERIES: 'admin/add_edit_series',
  LIST_GAMES: '/game',
  LIST_FILTER_GAMES: '/game/filterGames',
  ADD_EDIT_GAME: '/game/addEdit',
  LIST_TEAMS: '/teamsAdmin',
  ADD_EDIT_TEAM: '/teams/addEdit',
  LIST_LEAGUES: '/leagues',
  ADD_EDIT_REWARD_RULES: '/admin/add_edit_reward_rules',
  ASSIGN_REWARD: '/admin/assign_reward',
  ASSIGN_PARTNER_POINTS: '/admin/assign_partner_points',
  LIST_REWARD_POSITIONS: '/admin/list_rewards_positions',
  LIST_NFT_PURCHASES: '/admin/list_nft_purchases',
  LIST_NFT_NEWS_LETTERS: '/admin/list_nft_newsletters',
  LIST_SUPPORT_REQUEST: '/admin/supportRequest',
  UPLOAD_IMAGE: '/users/image',
  ADD_EDIT_BLOCK: '/admin/block',
  ADD_EDIT_QUIZ: 'admin/remove_game_quiz',
  DELETE_REQUEST: '/admin/delete',
  DELETE_USER_LEADERBOARD: 'admin/delete_user_leaderboard',
  WINNER_LIST: 'admin/list_contest_winners',
  WINNER_SERIES_LIST: 'open/seriesLeaderboard',
  OVERALL_LEADERBOARD: 'open/leaderboard',
  CONTEST_STATS: '/users/ADMIN/questionStats',
  QUESTION_STATS: 'admin/questionStats',
  ENABLE_SPECIAL_QUIZ: '/admin/enable_special_quiz',
  CONTEST_FORM_LISTING: 'admin/list_contest_forms',
  CONTEST_WALLPAPER_LISTING: 'admin/list_contest_wallpapers',
  ADD_EDIT_CONTEST_WALLPAPER: 'admin/add_edit_contest_wallpapers',
  CONTEST_INSTRUCTIONS: '/admin/contestInstructions',
  CONTEST_INSTRUCTIONS_LISTING: '/admin/contestInstructions',
  LIST_WALLPAPER_PURCHASE: 'admin/list_contest_wallpaper_purchases',
  GENERATE_GAME_QUIZ: 'admin/generate_game_quiz',
  GAME_OF_WEEK: 'admin/game_of_week',
  QUIZ_LIST: 'admin/list_game_quiz',
  GENERATE_QUESTION: 'admin/generateQuestions',
  ADD_GENERATED_QUESTION: 'admin/saveAiQuestions',
  BULK_UPDATE_QUESTIONS: 'admin/bulkUpdateQuestions',
  VERIFY_QUESTION: 'admin/verifyQuestions',
  QUESTION_BANK_LIST: 'admin/listQuestionBank',
  AUTO_ASSIGN_REWARD: 'admin/autoAssignRewards',
  SEND_CONTEST_NOTIFICATIONS: 'admin/send_contest_notifications',
  GENERATE_IMAGE_AI: 'admin/generateImage',
  FAQs_LIST: 'admin/list_faqs',
  ADD_EDIT_FAQs: '/admin/add_edit_faq',
  SAVE_QUESTION_FROM_JSON: 'admin/saveQuestionFromJson',
  SAVE_QUIZ_RULES_FLOW: 'admin/special_quiz_rules',
  LIST_QUESTIONS_CRITERIA_BASED: 'admin/listQuestionsCriteriaBased',
  REFRESH_GAMES: 'admin/refreshGames',
};

const defaultOptions = () => ({
  headers: {
    authorization: store.getState().auth.accessToken ? `${store.getState().auth.accessToken}` : '',
  },
  transformResponse: Axios.defaults.transformResponse.concat((response) => {
    if (response?.message === 'Missing Auth') {
      dispatchAction(handleLogout(true));
    }
    return response;
  }),
});

export const AxiosInstance = () => {
  const instance = Axios.create({
    baseURL: `${BASE_URL}`,
    ...defaultOptions(),
  });
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      toast.error(error?.response?.data?.error || error?.response?.data?.message || error?.message);
      throw error;
    }
  );
  return instance;
};
