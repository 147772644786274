import { Fragment, useEffect, useState } from 'react';
import Page from '../../components/Page';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import AsyncSelect from 'react-select/async';
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Box,
  Grid,
  Button,
  IconButton,
  colors,
  Avatar,
} from '@mui/material';
import { useFormik, Form, FormikProvider, Field, FieldArray } from 'formik';
import Iconify from '../../components/Iconify';
import { addEditRewardSchema, addEditWithPartnerRewardSchema } from 'src/utils/validationSchemas';
import { addEditApi, listApi } from 'src/apis/admin';
import { APIs } from 'src/apis';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'src/router/routes';
import { toast } from 'react-toastify';
import { handleRewardType } from '../../utils/helper';
import { typedata } from 'src/contants/contants';
import BackButton from 'src/components/backButton';
import UploadImage from 'src/components/uploadImage';
import { IMAGE_MAX_SIZE } from 'src/contants/contants';

const initialValues = {
  type: '',
  title: '',
  shortDescription: '',
  emailBodyContent: '',
  descriptionPoints: [''],
  imageLink: '',
  sponsorImageLink: '',
  price: 0,
  partnerId: '',
};

export default function AddEditReward({ isEdit }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [rewardData, setRewardData] = useState();
  const [image, setImage] = useState();
  const [sponserimage, setSponserImage] = useState();
  const [isPartnerVisible, setIsPartnerVisible] = useState(false);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
  const [fileSizeExceeded1, setFileSizeExceeded1] = useState(false);

  useEffect(() => {
    if (id) {
      fetchRewardData(id);
    }
  }, [id]);

  const fetchRewardData = async (id) => {
    setLoading(true);
    try {
      const result = await listApi(APIs.LIST_REWARD_TYPES, { id });
      if (result?.data?.length && `${result?.data[0]?.id}` === id) {
        const data = cloneDeep(result?.data[0]);
        const typeswitchdata = handleRewardType(data.type);
        const temptypeswitch = {
          label: typeswitchdata,
          value: data.type,
        };
        data.type = temptypeswitch;
        if (data?.partnerId) {
          setIsPartnerVisible(true);
          data.partnerId = {
            label: (
              <div className="optionStyles">
                <img src={data?.partnerData?.profileImage} height="30px" width="30px" alt="Partner" />
                <div className="selectText">{data?.partnerData?.name}</div>
              </div>
            ),
            value: data?.partnerData?.id,
          };
        }
        setRewardData(data);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = (data) => {
    if (image) {
      toast.error('Please upload image first');
    } else {
      data.type = data?.type?.value;
      if (isPartnerVisible === false) {
        data.partnerId = 0;
        delete data.partnerData;
      }
      if (isPartnerVisible === true) {
        data.partnerId = data.partnerId.value;
      }

      addEditApi(APIs.ADD_EDIT_REWARD, { ...data }).then(
        () => toast.success('Added Successfully'),
        navigate(ROUTES.REWARDTYPES)
      );
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: rewardData || initialValues,
    validationSchema: isPartnerVisible ? addEditWithPartnerRewardSchema : addEditRewardSchema,
    onSubmit: handleFormSubmit,
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const promiseOptions = (inputValue, resolve) => {
    const filter = typedata.map((item) => ({
      label: item.reward,
      value: item.type,
    }));
    resolve(filter);
  };

  const loadRewardTypes = debounce(promiseOptions, 300);

  const handleImageChange = (e) => {
    setFileSizeExceeded(false);
    if (e?.target?.files?.[0]?.size > IMAGE_MAX_SIZE) {
      setFileSizeExceeded(true);
    } else {
      if (e?.target?.files?.[0]) {
        setImage(e?.target?.files?.[0]);
      }
    }
  };
  const handleSponserImageChange = (e) => {
    setFileSizeExceeded1(false);
    if (e?.target?.files?.[0]?.size > IMAGE_MAX_SIZE) {
      setFileSizeExceeded1(true);
    } else {
      if (e?.target?.files?.[0]) {
        setSponserImage(e?.target?.files?.[0]);
      }
    }
  };

  const uploadImage = () => {
    const data = new FormData();
    data.append('file', image);
    addEditApi(APIs.UPLOAD_IMAGE, data).then((result) => {
      setFieldValue('imageLink', result?.profileImg);
      setImage(null);
      toast.success('Uploaded Success');
    });
  };
  const uploadSponsorImage = () => {
    const data = new FormData();
    data.append('file', sponserimage);
    addEditApi(APIs.UPLOAD_IMAGE, data).then((result) => {
      setFieldValue('sponsorImageLink', result?.profileImg);
      setSponserImage(null);
      toast.success('Uploaded Success');
    });
  };

  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 55,
        border: '',
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };

  const partnerOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_PARTNERS, { search: inputValue }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: (
          <div className="optionStyles">
            <img src={item?.profileImage} height="30px" width="30px" />
            <div className="selectText">{item.name}</div>
          </div>
        ),
        value: item?.id,
      }));

      resolve(filter);
    });
  };

  const loadPartners = debounce(partnerOptions, 300);

  return (
    <Page title="Contest" loading={loading}>
      <Container>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                {isEdit ? 'Edit Reward' : 'Add Reward'}
              </Typography>
              <div>
                <BackButton onClick={() => navigate(ROUTES.REWARDTYPES)} />
                <Button variant="contained" type="submit" startIcon={<Iconify icon="eva:save-outline" />}>
                  Save Reward
                </Button>
              </div>
            </Stack>

            <Card>
              <Box sx={{ flexGrow: 1, padding: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Select Reward Type"
                      styles={customStyles}
                      loadOptions={loadRewardTypes}
                      value={values?.type}
                      onChange={(data) => {
                        if (data.value === '8') {
                          setIsPartnerVisible(true);
                        } else {
                          setIsPartnerVisible(false);
                        }
                        setFieldValue('type', data);
                      }}
                    />

                    {Boolean(touched.type && errors.type) && (
                      <Typography variant="caption" display="block" gutterBottom color={'red'}>
                        {errors.type}
                      </Typography>
                    )}
                  </Grid>
                  {isPartnerVisible && (
                    <Grid item xs={12} md={6}>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        placeholder="Partner"
                        styles={customStyles}
                        loadOptions={loadPartners}
                        value={values?.partnerId}
                        onChange={(data) => {
                          setFieldValue('partnerId', data);
                        }}
                      />
                      {Boolean(touched.partnerId && errors.partnerId) && (
                        <Typography variant="caption" display="block" gutterBottom color={'red'}>
                          {errors.partnerId}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Reward Title"
                      {...getFieldProps('title')}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      maxRows={4}
                      label="Short Description"
                      {...getFieldProps('shortDescription')}
                      error={Boolean(touched.shortDescription && errors.shortDescription)}
                      helperText={touched.shortDescription && errors.shortDescription}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      maxRows={4}
                      label="Email Body Content"
                      {...getFieldProps('emailBodyContent')}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      label="Price"
                      {...getFieldProps('price')}
                      error={Boolean(touched.price && errors.price)}
                      helperText={touched.price && errors.price}
                    />
                  </Grid>
                  <Grid container padding={2}>
                    <FieldArray
                      name="descriptionPoints"
                      render={(arrayHelpers) => (
                        <>
                          {values.descriptionPoints.map((item, index) => (
                            <Fragment key={index}>
                              <Grid item xs={8} md={10}>
                                <TextField
                                  fullWidth
                                  name={`descriptionPoints.${index}`}
                                  label={index === 0 ? 'Description Points' : ''}
                                  placeholder={
                                    index === 0 ? '1. Description Points' : `${index + 1}. Description Points`
                                  }
                                  {...getFieldProps(`descriptionPoints.${index}`)}
                                  error={Boolean(touched.descriptionPoints && errors.descriptionPoints)}
                                  helperText={touched.descriptionPoints && errors.descriptionPoints}
                                />
                              </Grid>
                              {values?.descriptionPoints?.length !== 1 && (
                                <IconButton
                                  size="large"
                                  sx={{ ml: 2, color: colors.red[500] }}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <Iconify icon="eva:minus-square-fill" />
                                </IconButton>
                              )}
                              <Grid item xs={1} md={1}>
                                <IconButton
                                  size="large"
                                  sx={{ ml: 2, color: 'primary.main' }}
                                  onClick={() => arrayHelpers.insert(index, '')}
                                >
                                  <Iconify icon="eva:plus-square-fill" />
                                </IconButton>
                              </Grid>
                            </Fragment>
                          ))}
                        </>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <UploadImage
                      title="Reward Image"
                      fieldName="imageLink"
                      image={image ? URL.createObjectURL(image) : values?.imageLink}
                      fileSizeExceeded={fileSizeExceeded}
                      onChange={handleImageChange}
                      uploadImage={uploadImage}
                      stateImage={image}
                      removeImage={() => setImage(null)}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <UploadImage
                      title="Sponsor Image"
                      fieldName="sponsorImageLink"
                      stateImage={sponserimage}
                      image={sponserimage ? URL.createObjectURL(sponserimage) : values?.sponsorImageLink}
                      fileSizeExceeded={fileSizeExceeded1}
                      onChange={handleSponserImageChange}
                      uploadImage={uploadSponsorImage}
                      removeImage={() => setSponserImage(null)}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </Grid>
              </Box>
            </Card>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
