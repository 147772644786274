import React, { useState, useEffect } from 'react';
import { addEditApi, listApi } from 'src/apis/admin';
import Page from '../../components/Page';
import { APIs } from '../../apis';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Dialog,
  Grid,
} from '@mui/material';
import { ROUTES } from 'src/router/routes';
import AsyncSelect from 'react-select/async';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import { UserListToolbar } from '../../sections/@dashboard/user';
import debounce from 'lodash/debounce';
import Scrollbar from '../../components/Scrollbar';
import moment from 'moment';
import MoreMenu from 'src/components/table/MoreMenu';
import { confirmAlert } from 'react-confirm-alert';
import { handleRewardType } from '../../utils/helper';
import { toast } from 'react-toastify';
import EditModal from './editModal';
import { DATE_FORMAT, DATE_FORMAT_TIME } from 'src/contants/contants';
import ImageModal from '../openAI/questionBankList/imageModal';

const TABLE_HEAD = [
  { id: 'contestName', label: 'Contest Name', alignRight: false },
  { id: 'RewardType', label: 'Reward Name', alignRight: false },
  { id: 'position', label: 'Position', alignRight: false },
  { id: 'rewardMap', label: 'Reward Map', alignRight: false },
  { id: 'expiryDate', label: 'Expiry Date', alignRight: false },
  { id: 'redeemTime', label: 'Redeem Time (In Mins)', alignRight: false },
  { id: 'winningPoints', label: 'Winning Points', alignRight: false },
];

const cursorPointer = { cursor: 'pointer' };

const handleExpiryDate = (exDate) => {
  return `${moment(exDate).format(DATE_FORMAT)}`;
};

export default function index() {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');
  const [success, setSuccess] = useState(false);
  const [editModalValue, setEditModalValue] = useState(false);
  const [modalRowData, setModalRowData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [contestFilterSelect, setContestFilterSelect] = useState([]);
  const [partnerFilterSelect, setPartnerFilterSelect] = useState();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    let contestIdArray = [];
    const data = {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      search: searchFilter,
    };
    if (id) {
      contestIdArray.push(Number(id));
    }
    if (contestFilterSelect.length) {
      contestIdArray = contestFilterSelect?.map((elem) => elem?.value);
    }
    if (partnerFilterSelect) {
      data.partnerId = partnerFilterSelect?.value
    }
    if (contestIdArray.length) {
      data.contestId = JSON.stringify(contestIdArray);
    }
    listApi(APIs.LIST_REWARD_POSITIONS, { ...data })
      .then((res) => {
        setUsers(res?.data);
        setTotalCount(res?.count);
      })
      .finally(() => setLoading(false));
  }, [rowsPerPage, searchFilter, page, success, refresh, contestFilterSelect, partnerFilterSelect]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const deleteRewardRules = (row) => {
    const data = {
      id: row.id,
      type: 5,
    };
    confirmAlert({
      title: 'Confirm for Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            addEditApi(APIs.DELETE_REQUEST, data).then((result) => {
              setSuccess((prev) => !prev);
              toast.success('Deleted Successfully');
            });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const closeModal = () => {
    setEditModalValue(!editModalValue);
    setRefresh(!refresh);
  };

  //Styles for filter //
  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 56,
        border: '1px solid #e0e0e0',
        // width: '240px',
        borderRadius: '8px',
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  }

  /*Contet Filter Api  */
  const promiseContestDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_CONTESTS, { search: inputValue, sendOnlyActive: true }).then((result) => {
      const filter = result?.data.map((item) => ({
        label: (
          <div className="optionStyles">
            <img src={item?.imageLink} height="30px" width="30px" />
            <div className="selectText">
              {item.name} | {moment(item.startDateTime).format(DATE_FORMAT_TIME)}
            </div>
          </div>
        ),
        value: item?.id,
      }));
      resolve(filter);
    });
  };

  const promisePartnerDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_PARTNERS, { search: inputValue, sendOnlyActive: true }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={item?.name} src={item?.profileImage} />
            <Typography variant="subtitle2" noWrap>
              {item?.name}
            </Typography>
          </Stack>
        ),
        value: item?.id,
      }));

      resolve(filter);
    });
  };

  const loadPartnerData = debounce(promisePartnerDataOptions, 300);
  const loadContestData = debounce(promiseContestDataOptions, 300);

  return (
    <Page title="Dashboard: Reward Rules">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Reward Rules
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to={`${ROUTES.REWARD_RULE_ADD}`}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Rules
          </Button>
        </Stack>
        <Card>
          <Grid container spacing={2} padding={1}>
            <Grid>
              <UserListToolbar numSelected={selected.length} filterName={search} onFilterName={handleSearch} />
            </Grid>
            <Grid className="filterInput" xs={12} md={3}>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isMulti
                isClearable
                placeholder="Filter by contest"
                styles={customStyles}
                loadOptions={loadContestData}
                value={contestFilterSelect}
                onChange={(data) => {
                  setContestFilterSelect(data);
                }}
              />
            </Grid>

            <Grid className="filterInput" xs={10} md={3} style={{ marginLeft: '10px' }}>
              <AsyncSelect
                isClearable
                cacheOptions
                defaultOptions
                placeholder="Filter by partner"
                styles={customStyles}
                loadOptions={loadPartnerData}
                value={partnerFilterSelect}
                onChange={(data) => {
                  setPartnerFilterSelect(data);
                }}
              />
            </Grid>
          </Grid>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((row, index) => {
                      const {
                        id,
                        contestName,
                        position,
                        rewardMap,
                        expiryDate,
                        Contest,
                        redeemTime,
                        RewardType,
                        winningPoints,
                      } = row;

                      const isItemSelected = selected.indexOf(redeemTime) !== -1;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={Contest?.name} src={Contest?.imageLink} onClick={() => showFullImage(Contest?.imageLink)} style={cursorPointer}  />
                              <div>
                                <p>
                                  <Typography variant="subtitle2" noWrap>
                                    {Contest?.name}
                                  </Typography>
                                </p>
                                <p> {moment(Contest?.startDateTime).format(DATE_FORMAT_TIME)}</p>
                              </div>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{RewardType?.title}</TableCell>
                          <TableCell align="left">{position}</TableCell>
                          <TableCell align="left">
                            {handleRewardType(row?.RewardType?.type)} | {row?.RewardType?.price}$
                          </TableCell>
                          <TableCell align="left">{handleExpiryDate(expiryDate)}</TableCell>
                          <TableCell align="left">{redeemTime}</TableCell>
                          <TableCell align="left">{winningPoints}</TableCell>
                          <TableCell align="right">
                            <MoreMenu
                              options={[
                                {
                                  label: 'Edit',
                                  icon: 'eva:edit-fill',
                                  onClick: () => {
                                    setEditModalValue(!editModalValue);
                                    setModalRowData(row);
                                  },
                                },
                                {
                                  label: 'Delete',
                                  icon: 'eva:trash-2-outline',
                                  onClick: () => deleteRewardRules(row),
                                },
                              ]}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
      <Dialog open={editModalValue} fullWidth>
        <EditModal closeModal={closeModal} data={modalRowData} />
      </Dialog>
    </Page>
  );
}
