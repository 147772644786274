import {
    Avatar,
    Button,
    Card,
    CircularProgress,
    Container,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    Grid,
} from '@mui/material';
import { UserListToolbar } from '../../../sections/@dashboard/user';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import Iconify from 'src/components/Iconify';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import { addEditApi, listApi } from 'src/apis/admin';
import { APIs } from 'src/apis';
import { ROUTES } from 'src/router/routes';
import Scrollbar from 'src/components/Scrollbar';
import moment from 'moment';
import { contestTypes, DATE_FORMAT_TIME } from 'src/contants/contants';
import MoreMenu from 'src/components/table/MoreMenu';
import { openLinkNewTab } from 'src/utils/helper';
import ImageModal from 'src/pages/openAI/questionBankList/imageModal';
import Label from 'src/components/Label';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';


const TABLE_HEAD = [
    { id: '' },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'contestType', label: 'Contest Type', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'partnerId', label: 'Partner', alignRight: false },
    { id: 'startDateTime', label: 'Start Date Time', alignRight: false },
    { id: 'teams', label: 'Teams', alignRight: false },
];

const ContestBulkCreate = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openImageModal, setOpenImageModal] = useState(false);
    const [imageSrc, setImageSrc] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [searchFilter, setSearchFilter] = useState('');
    const [partnerFilterSelect, setPartnerFilterSelect] = useState();
    const [typeFilterSelect, setTypeFilterSelect] = useState();

    useEffect(() => {
        getData();
    }, [refresh,partnerFilterSelect, typeFilterSelect, page, rowsPerPage, searchFilter]);

    const getData = () => {
        const data = {
            skip: page * rowsPerPage,
            limit: rowsPerPage,
            search: searchFilter,
        };

        if (partnerFilterSelect) {
            data.partnerId = partnerFilterSelect.value;
        }

        if (typeFilterSelect) {
            data.contestType = typeFilterSelect.value;
        }

        setLoading(true);
        listApi(APIs.LIST_CONTESTS_BULK, data)
            .then((response) => {
                setData(response?.data);
                setTotalCount(response?.count);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //Styles for filter //
    const customStyles = {
        control: (provided, state) => {
            return {
                ...provided,
                minHeight: 56,
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
            };
        },
        menu: (provided, state) => {
            return {
                ...provided,
                zIndex: 2,
            };
        },
    };

    //function to open a full size image
    const showFullImage = (src) => {
        setOpenImageModal(true);
        setImageSrc(src);
    };

    /*Clone Handler */

    const cloneData = (data) => {
        confirmAlert({
            title: 'Confirm to clone',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        navigate(`/bulk-contests/add?clone=${data.id}`);
                    },
                },
                {
                    label: 'No',
                },
            ],
        });
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
        debounce(() => {
            setSearchFilter(event.target.value);
        }, 600)();
    };

    /*Contet Filter Api  */
    const promisePartnerDataOptions = (inputValue, resolve) => {
        listApi(APIs.LIST_PARTNERS, { search: inputValue, sendOnlyActive: true }).then((result) => {
            const filter = result?.data?.map((item) => ({
                label: (
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar alt={item?.name} src={item?.profileImage} />
                        <Typography variant="subtitle2" noWrap>
                            {item?.name}
                        </Typography>
                    </Stack>
                ),
                value: item?.id,
            }));

            resolve(filter);
        });
    };

    const loadPartnerData = debounce(promisePartnerDataOptions, 300);

    return (
        <Page title="Bulk Contests">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Bulk Contests
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to={`${ROUTES.BULK_CONTESTS_ADD}`}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                        Setup Contests
                    </Button>
                </Stack>

                <Card>
                    <Grid container spacing={2} padding={1}>
                        {/* <Grid> */}
                        <UserListToolbar filterName={search} onFilterName={handleSearch} />
                        {/* </Grid> */}

                        <Grid className="filterInput" xs={12} md={3.5}>
                            <AsyncSelect
                                isClearable
                                cacheOptions
                                defaultOptions
                                placeholder="Filter by partner"
                                styles={customStyles}
                                loadOptions={loadPartnerData}
                                value={partnerFilterSelect}
                                onChange={(data) => {
                                    setPartnerFilterSelect(data);
                                }}
                            />
                        </Grid>

                        <Grid className="filterInput" xs={12} md={3} marginLeft={3}>
                            <Select
                                fullWidth
                                isClearable
                                styles={customStyles}
                                placeholder='Filter by Contest Type'
                                options={contestTypes}
                                name={'filter'}
                                onChange={(data) => setTypeFilterSelect(data)}
                            />
                        </Grid>
                    </Grid>
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 25]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage=""
                        showFirstButton
                        showLastButton
                    />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            {loading ? (
                                <Stack alignItems="center">
                                    <CircularProgress />
                                </Stack>
                            ) : (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {TABLE_HEAD.map((headCell) => (
                                                <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                                                    {headCell.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {data?.map((row) => {
                                            const { contestType, status, id, name, startDateTime, partnerData, homeTeamData, awayTeamData } = row;
                                            return (
                                                <TableRow key={row.id}>
                                                    <TableCell>
                                                        <MoreMenu
                                                            options={[
                                                                {
                                                                    label: 'Edit',
                                                                    icon: 'eva:edit-fill',
                                                                    onClick: () => openLinkNewTab(`/bulk-contests/${row.id}`),
                                                                },
                                                                {
                                                                    label: 'Clone data',
                                                                    icon: 'eva:copy-outline',
                                                                    onClick: () => cloneData(row),
                                                                },
                                                                // {
                                                                //     label: row?.isActive ? 'Mark inActive' : 'Mark Active',
                                                                //     icon: 'eva:edit-fill',
                                                                //     onClick: () => MenuConfirmClick(row),
                                                                // },
                                                                // {
                                                                //     label: 'Delete',
                                                                //     icon: 'eva:trash-2-outline',
                                                                //     onClick: () => {
                                                                //         confirmAlert({
                                                                //             title: 'Confirm for Delete',
                                                                //             message: 'Are you sure to do this.',
                                                                //             buttons: [
                                                                //                 {
                                                                //                     label: 'Yes',
                                                                //                     onClick: () => {
                                                                //                         addEditApi(APIs.DELETE_REQUEST, { type: 12, id: row.id }).then((result) => {
                                                                //                             toast.success('Deleted Successfully');
                                                                //                             setRefresh((prev) => !prev);
                                                                //                         });
                                                                //                     },
                                                                //                 },
                                                                //                 {
                                                                //                     label: 'No',
                                                                //                 },
                                                                //             ],
                                                                //         });
                                                                //     },
                                                                // },
                                                            ]}
                                                        />
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        <Label variant="ghost" color={status === 2 ? 'success' : 'error'}>
                                                            {status === 2 ? 'Final Submit' : 'Draft'}
                                                        </Label>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {contestType === 1 ? 'Global' : contestType === 2 ? 'Location' : contestType === 3 ? 'Location + Global' : 'Link Based'}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {name}
                                                    </TableCell>

                                                    <TableCell align="left" sx={{ minWidth: '200px' }}>
                                                        {`${partnerData?.name}`}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {startDateTime !== null ? moment(startDateTime).format(DATE_FORMAT_TIME) : '-'}
                                                    </TableCell>

                                                    <TableCell align="left" sx={{ minWidth: '200px' }}>
                                                        {`${awayTeamData?.teamName} VS ${homeTeamData?.teamName}`}
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            )}
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 15, 25]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage=""
                        showFirstButton
                        showLastButton
                    />

                </Card>
            </Container>
            <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
        </Page>
    );
};

export default ContestBulkCreate;
