import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  CircularProgress,
  Grid,
  Button,
  Avatar,
  Dialog,
} from '@mui/material';
import { addEditApi, listApi } from 'src/apis/admin';
import debounce from 'lodash/debounce';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/material.css';
import MoreMenu from 'src/components/table/MoreMenu';
import { DATE_FORMAT_TIME, SERIES_TABLE_HEAD } from 'src/contants/contants';
import Iconify from 'src/components/Iconify';
import { APIs } from 'src/apis';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import Scrollbar from 'src/components/Scrollbar';
import Page from 'src/components/Page';
import Label from 'src/components/Label';
import { confirmAlert } from 'react-confirm-alert';
import { ROUTES } from 'src/router/routes';
import moment from 'moment';
import { useSelector } from 'react-redux';
import SeriesWinnerList from './winnerList';

export default function SeriesList() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [series, setSeries] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState();
  const [openWinnerList, setOpenWinnerList] = useState(false);
  
  const user = useSelector((state) => state.auth);
  const handleEditPermission =
    user && user.roles && user.roles.some((role) => role?.title === 'Series List' && role?.edit === true);

  const isSuperAdmin = user?.isSuperAdmin;

  useEffect(() => {
    setLoading(true);
    let ApiPayload = {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      search: searchFilter,
      fromAdmin: true,
    };

    listApi(APIs.LIST_SERIES, ApiPayload)
      .then((res) => {
        setSeries(res?.data);
        setTotalCount(res?.count);
      })
      .finally(() => setLoading(false));
  }, [refresh, rowsPerPage, searchFilter, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };
  const statusSeries = (row) => {
    const data = {
      id: row.id,
      type: 6,
      active: !row.isActive,
    };
    confirmAlert({
      title: 'Confirm for Status Change',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            addEditApi(APIs.ADD_EDIT_BLOCK, data).then((result) => {
              setRefresh((v) => !v);
            });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  /*close Winner list Modal*/
  const closeWinnerList = () => {
    setSelectedSeries('');
    setOpenWinnerList(!openWinnerList);
  };
  

  return (
    <Page title="Games">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Series
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to={`${ROUTES.SERIESADD}`}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Series
          </Button>
        </Stack>

        <Card>
          <Grid container spacing={2} columns={16} alignItems="center">
            <Grid item xs={5}>
              <UserListToolbar filterName={search} onFilterName={handleSearch} />
            </Grid>
          </Grid>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {SERIES_TABLE_HEAD.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          <TableSortLabel style={{ width: '100px' }} hideSortIcon active={false} direction={'asc'}>
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {series?.map((row, index) => {
                      const { id, name, partnerData, isActive, endDateTime } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                          <TableCell align="left">
                            {(handleEditPermission || isSuperAdmin) && (
                              <MoreMenu
                                options={[
                                  {
                                    label: 'Edit',
                                    icon: 'eva:edit-fill',
                                    onClick: () => navigate(`/series/edit/${id}`),
                                  },
                                  {
                                    label: isActive ? 'Inactive Series' : 'Active Series',
                                    icon: 'eva:edit-fill',
                                    onClick: () => statusSeries(row),
                                  },
                                  {
                                    label: 'View leaderBoard/Winners',
                                    icon: 'eva:copy-outline',
                                    onClick: () => {
                                      setSelectedSeries(row);
                                      setOpenWinnerList(!openWinnerList);
                                    },
                                  },
                                ]}
                              />
                            )}
                          </TableCell>
                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={partnerData?.name} src={partnerData?.profileImage} />
                              <Typography variant="subtitle2" noWrap>
                                {partnerData?.name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={isActive ? 'success' : 'error'}>
                              {isActive ? 'Active' : 'InActive'}
                            </Label>
                          </TableCell>
                          <TableCell align="left">{moment(endDateTime).format(DATE_FORMAT_TIME)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
        <Dialog key="winnerList" open={openWinnerList} fullWidth>
          <SeriesWinnerList closeModal={closeWinnerList} seriesId={selectedSeries} />
        </Dialog>
      </Container>
    </Page>
  );
}
