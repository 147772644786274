import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Dialog,
  Avatar,
} from '@mui/material';
import Page from 'src/components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import { UserListToolbar } from '../../sections/@dashboard/user';
import { addEditApi, listApi } from 'src/apis/admin';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { APIs } from 'src/apis';
import MoreMenu from '../../components/table/MoreMenu';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { DATE_FORMAT } from '../../contants/contants';
import AddEditModal from './addEditModal';
import { useSelector } from 'react-redux';

const TABLE_HEAD = [
  { id: '' },
  { id: 'question', label: 'Question', alignRight: false },
  { id: 'answer', label: 'Answer', alignRight: false },
  { id: 'createdAt', label: 'Created at', alignRight: false },
  { id: 'isActive', label: 'Status', alignRight: false },
];

const ACTION_CONSTANT = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  STATUS: 'STATUS',
};

const FAQs = () => {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [questionList, setQuestionList] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [openModal, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const user = useSelector((state) => state.auth);

  useEffect(() => {
    listApi(APIs.FAQs_LIST, { skip: page * rowsPerPage, limit: rowsPerPage, search: searchFilter }).then((res) => {
      setQuestionList(res?.data);
      setTotalCount(res?.count);
    });
  }, [rowsPerPage, searchFilter, page, success]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  const handleClose = () => {
    setModalOpen(false);
    setTimeout(() => {
      setSelectedRow('');
    }, 100);
  };

  const handleFormSubmit = (payload) => {
    addEditApi(APIs.ADD_EDIT_FAQs, { ...payload }).then((result) => {
      setSuccess((s) => !s);
      toast.success(`${payload?.id ? 'Updated' : 'Saved'} Successfully`);
      handleClose();
    });
  };

  const ActionClick = (identifier, row) => {
    const isAdmin = user.isSuperAdmin;

    if (isAdmin && identifier === ACTION_CONSTANT.EDIT) {
      setSelectedRow({ question: row?.question, answer: row?.answer, id: row?.id });
      setModalOpen(true);
      return;
    }

    if (isAdmin && identifier === ACTION_CONSTANT.DELETE) {
      confirmAlert({
        title: <h6>Confirm for Delete</h6>,
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              addEditApi(APIs.DELETE_REQUEST, { type: 9, id: row?.id }).then((result) => {
                setSuccess((s) => !s);
                toast.success('Delete Successfully');
              });
            },
          },
          {
            label: 'No',
          },
        ],
      });
      return;
    }

    const { edit, delete: allowDelete } = user.roles.find((role) => role.title === 'FAQs') || {};

    if (identifier === ACTION_CONSTANT.EDIT && edit) {
      setSelectedRow({ question: row?.question, answer: row?.answer, id: row?.id });
      setModalOpen(true);
    }

    if (identifier === ACTION_CONSTANT.DELETE && allowDelete) {
      confirmAlert({
        title: <h6>Confirm for Delete</h6>,
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              addEditApi(APIs.DELETE_REQUEST, { type: 9, id: row?.id }).then((result) => {
                setSuccess((s) => !s);
                toast.success('Delete Successfully');
              });
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }

    if (identifier === ACTION_CONSTANT.STATUS && (edit || allowDelete)) {
      let payload = { type: 7, id: row?.id, active: !row.isActive };
      confirmAlert({
        title: <h6>Confirm for Status Change</h6>,
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              addEditApi(APIs.ADD_EDIT_BLOCK, { ...payload }).then((result) => {
                setSuccess((s) => !s);
                toast.success('Status Changed Successfully');
              });
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }
  };

  const handleEditPermission =
    user && user.roles && user.roles.some((role) => role?.title === 'FAQs' && role?.edit === true);

  const handlePermission =
    user &&
    user.roles &&
    user.roles.some((role) => (role?.title === 'FAQs' && role?.edit === true) || role?.delete === true);

  const handleDeletePermission =
    user && user.roles && user.roles.some((role) => role?.title === 'FAQs' && role?.delete === true);

  const isSuperAdmin = user?.isSuperAdmin;

  return (
    <Page title="FAQs">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            FAQs
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              setSelectedRow('');
              setModalOpen(true);
            }}
          >
            Add FAQ
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={search} onFilterName={handleSearch} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                        <TableSortLabel hideSortIcon active={false} direction={'asc'}>
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {questionList.map((row, index) => {
                    const { id, question, answer, createdAt, isActive } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="right">
                          {(handlePermission || isSuperAdmin) && (
                            <MoreMenu
                              options={[
                                (handleEditPermission || isSuperAdmin) && {
                                  label: 'Edit',
                                  icon: 'eva:edit-fill',
                                  onClick: () => ActionClick(ACTION_CONSTANT.EDIT, row),
                                },
                                (handleDeletePermission || isSuperAdmin) && {
                                  label: 'Delete',
                                  icon: 'eva:trash-2-outline',
                                  onClick: () => ActionClick(ACTION_CONSTANT.DELETE, row),
                                },
                                (handleEditPermission || isSuperAdmin) && {
                                  label: isActive ? 'Inactive' : 'Active',
                                  icon: 'eva:edit-fill',
                                  onClick: () => ActionClick(ACTION_CONSTANT.STATUS, row),
                                },
                              ].filter(Boolean)}
                            />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar />
                            <Typography variant="subtitle2" noWrap>
                              {question}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{answer}</TableCell>
                        <TableCell align="left">{moment(createdAt).format(DATE_FORMAT)}</TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={isActive ? 'success' : 'error'}>
                            {isActive ? 'Active' : 'InActive'}
                          </Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>
      <Dialog open={openModal} onClose={handleClose} fullWidth>
        <AddEditModal
          selectedRow={selectedRow}
          handleFormSubmit={handleFormSubmit}
          handleClose={handleClose}
          loading={loading}
        />
      </Dialog>
    </Page>
  );
};

export default FAQs;
