import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTES } from './routes';

const ProtectedRoute = ({ children, roles, permissions }) => {
  const user = useSelector((state) => state.auth);

  const hasRequiredPermissions = () => {
    if (user?.isSuperAdmin) return true;
    return user?.roles?.some((role) => roles.includes(role.title) && permissions.every((perm) => role[perm] === true));
  };

  if (!hasRequiredPermissions()) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return children;
};

export default ProtectedRoute;
