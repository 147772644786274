import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Card, Container, Typography } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link, Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/Iconify';
import { handleLogin } from 'src/redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { LoginSchema } from 'src/utils/validationSchemas';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const isLoading = useSelector((state) => state?.auth?.loading);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleFormSubmit = (payload) => {
    dispatch(handleLogin(payload));
  };

  const formik = useFormik({
    initialValues: {
      emailId: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: handleFormSubmit,
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome ShotCaller Admin
            </Typography>
            <img src="/static/illustrations/illustration_login.png" alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Sign in
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>

            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    // autoComplete="username"
                    type="email"
                    label="Email address"
                    {...getFieldProps('emailId')}
                    error={Boolean(touched.emailId && errors.emailId)}
                    helperText={touched.emailId && errors.emailId}
                  />

                  <TextField
                    fullWidth
                    // autoComplete="current-password"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    {...getFieldProps('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
                  <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
                    Forgot password?
                  </Link>
                </Stack>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
                  Login
                </LoadingButton>
              </Form>
            </FormikProvider>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
