import {
  Avatar,
  Button,
  Card,
  CircularProgress,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';

import { addEditApi, listApi } from 'src/apis/admin';
import { APIs } from 'src/apis';

import { ROUTES } from 'src/router/routes';
import Scrollbar from 'src/components/Scrollbar';
import moment from 'moment';
import { DATE_FORMAT_TIME } from 'src/contants/contants';
import MoreMenu from 'src/components/table/MoreMenu';
import { openLinkNewTab } from 'src/utils/helper';
import ImageModal from 'src/pages/openAI/questionBankList/imageModal';
import Label from 'src/components/Label';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

const TABLE_HEAD = [
  { id: '' },
  { id: 'notificationType', label: 'Notification Type', alignRight: false },
  { id: 'isActive', label: 'Scheduler Status', alignRight: false },
  { id: 'jobCompleted', label: 'Job Completed', alignRight: false },
  { id: 'scheduleDateTime', label: 'Schedule Date Time', alignRight: false },
  { id: 'greetingText', label: 'Greeting Text', alignRight: false },
  { id: 'emailSubject', label: 'Email Subject', alignRight: false },
  { id: 'buttonText', label: 'Button Text', alignRight: false },
  { id: 'bodyText', label: 'Body Text', alignRight: false },
  { id: 'selectSinglePartner', label: 'Select Single Partner', alignRight: false },
  { id: 'pushNotificationText', label: 'Push Notification Text', alignRight: false },
  { id: 'customHeader', label: 'Custom Header', alignRight: false },

  { id: 'mainImage', label: 'Main Image', alignRight: false },
  { id: 'customImage', label: 'Custom Image', alignRight: false },
];

const SchedulerNotifications = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [refresh]);

  const getData = () => {
    setLoading(true);
    listApi(APIs.SCHEDULER, { type: 1 })
      .then((response) => {
        setData(response?.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  //Styles for filter //
  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 56,
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };

  const options = {
    1: 'Send Email Only',
    2: 'Send Push Only',
    3: 'Send Both',
  };

  /* Active and Inactive Handler  */

  const MenuConfirmClick = (row) => {
    const data = {
      id: row.id,
      type: 9,
      active: !row.isActive,
    };

    confirmAlert({
      title: 'Confirm for Status Change',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            addEditApi(APIs.ADD_EDIT_BLOCK, data).then((result) => {
              setRefresh(!refresh);
            });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  /*Clone Handler */

  const cloneNotification = (data) => {
    confirmAlert({
      title: 'Confirm to clone',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            navigate(`/notifications/add?clone=${data.id}`);
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  return (
    <Page>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Notifications
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to={`${ROUTES.SCHEDULER_NOTIFICATIONS_ADD}`}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Notifications
          </Button>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data?.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell>
                            <MoreMenu
                              options={[
                                {
                                  label: 'Edit',
                                  icon: 'eva:edit-fill',
                                  onClick: () => openLinkNewTab(`/notifications/${row.id}`),
                                },
                                {
                                  label: 'Clone Notifications',
                                  icon: 'eva:copy-outline',
                                  onClick: () => cloneNotification(row),
                                },
                                {
                                  label: row?.isActive ? 'Mark inActive' : 'Mark Active',
                                  icon: 'eva:edit-fill',
                                  onClick: () => MenuConfirmClick(row),
                                },
                                {
                                  label: 'Delete Notifications',
                                  icon: 'eva:trash-2-outline',
                                  onClick: () => {
                                    confirmAlert({
                                      title: 'Confirm for Delete',
                                      message: 'Are you sure to do this.',
                                      buttons: [
                                        {
                                          label: 'Yes',
                                          onClick: () => {
                                            addEditApi(APIs.DELETE_REQUEST, { type: 11, id: row.id }).then((result) => {
                                              toast.success('Deleted Successfully');
                                              setRefresh((prev) => !prev);
                                            });
                                          },
                                        },
                                        {
                                          label: 'No',
                                        },
                                      ],
                                    });
                                  },
                                },
                              ]}
                            />
                          </TableCell>
                          <TableCell>{options[row?.data?.type]}</TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={row?.isActive ? 'success' : 'error'}>
                              {row?.isActive ? 'Active' : 'InActive'}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={row?.jobCompleted ? 'success' : 'error'}>
                              {row?.jobCompleted ? 'Completed' : 'Pending'}
                            </Label>
                          </TableCell>
                          <TableCell>
                            {row.dateTime !== null ? moment(row.dateTime).format(DATE_FORMAT_TIME) : '-'}
                          </TableCell>

                          <TableCell>{row.data?.greetingText}</TableCell>
                          <TableCell>{row.data?.emailSubject}</TableCell>
                          <TableCell>{row.data?.buttonText}</TableCell>
                          <TableCell>{row.data?.bodyText}</TableCell>
                          <TableCell>
                          <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar
                                alt={""}
                                src={row?.data?.partnerData?.profileImage}
                                onClick={() => showFullImage(row?.data?.partnerData?.profileImage)}
                                style={{ cursor: 'pointer' }}
                              />
                              <Typography variant="subtitle2" noWrap>
                              {row?.data?.partnerData?.name}
                              </Typography>
                            </Stack>
                           
                            </TableCell>
                          <TableCell>{row.data?.pushNotificationText}</TableCell>
                          <TableCell>{row.data?.customHeader}</TableCell>

                          <TableCell>
                            {row.data?.url && (
                              <Avatar onClick={() => showFullImage(row?.data?.url)} src={row?.data?.url} />
                            )}
                          </TableCell>
                          <TableCell>
                            {row.data?.customImageUrl && (
                              <Avatar
                                src={row?.data?.customImageUrl}
                                onClick={() => showFullImage(row?.data?.customImageUrl)}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </Page>
  );
};

export default SchedulerNotifications;
