import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import { UserListToolbar } from '../../../sections/@dashboard/user';
import { addEditApi, listApi } from 'src/apis/admin';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { APIs } from '../../../apis';
import { addEditPartnerSchema } from 'src/utils/validationSchemas';
import { useFormik, Form, FormikProvider } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import React from 'react';
import { DATE_FORMAT_TIME } from 'src/contants/contants';

const TABLE_HEAD = [
  { id: 'reportedto', label: 'Reported To', alignRight: false },
  { id: 'repotedby', label: 'Reported By', alignRight: false },
  { id: 'reason', label: 'Reason', alignRight: false },
  { id: 'date', label: 'Reported Date', alignRight: false },
  { id: '' },
];

export default function ReportedUsers() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [openModal, setModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    listApi(APIs.LIST_REPORTED_USERS, { skip: page * rowsPerPage, limit: rowsPerPage, search: searchFilter })
      .then((res) => {
        setUsers(res?.data);
        setTotalCount(res?.count);
      })
      .finally(() => setLoading(false));
  }, [refresh, rowsPerPage, searchFilter, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  const handleClose = (_, reason) => {
    if (reason !== 'backdropClick') {
      setModalOpen(false);
    }
  };

  const handleFormSubmit = (data, { resetForm }) => {
    addEditApi(APIs.ADD_EDIT_PARTNER, {
      ...data,
      phoneNumber: data?.phoneNumber.slice(data?.countryCode?.length),
    }).then(() => {
      resetForm();
      setModalOpen(false);
      setRefresh((prev) => !prev);
    });
  };

  const formik = useFormik({
    initialValues: {
      emailId: '',
      password: '',
      name: '',
      phoneNumber: '',
      countryCode: '',
      address: '',
    },
    validationSchema: addEditPartnerSchema,
    onSubmit: handleFormSubmit,
  });
  const { errors, touched, values, setFieldValue, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Reported Users">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Reported Users
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar filterName={search} onFilterName={handleSearch} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          <TableSortLabel hideSortIcon active={false} direction={'asc'}>
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {users.map((row, index) => {
                      const { id, reportedBy, reason, reportedUser, createdAt } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={reportedUser?.profileImg} />
                              <Typography variant="subtitle2" noWrap>
                                {reportedUser?.name}
                              </Typography>
                              <Typography variant="subtitle2" noWrap>
                                {reportedUser?.emailId}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={reportedBy?.profileImg} />
                              <Typography variant="subtitle2" noWrap>
                                {reportedBy?.name}
                              </Typography>
                              <Typography variant="subtitle2" noWrap>
                                {reportedBy?.emailId}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{reason}</TableCell>
                          <TableCell align="left">{moment(createdAt).format(DATE_FORMAT_TIME)}</TableCell>

                          <TableCell align="right">{/*<UserMoreMenu />*/}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>

      <Dialog open={openModal} onClose={handleClose} fullWidth>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>Add New Partner</DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                margin="normal"
                label="Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />

              <TextField
                fullWidth
                margin="normal"
                label="Email address"
                {...getFieldProps('emailId')}
                error={Boolean(touched.emailId && errors.emailId)}
                helperText={touched.emailId && errors.emailId}
              />

              <PhoneInput
                value={values?.phoneNumber}
                onChange={(value, data) => {
                  setFieldValue('phoneNumber', value);
                  setFieldValue('countryCode', data?.dialCode);
                }}
                country={'us'}
                isValid={!(touched.phoneNumber && errors.phoneNumber)}
                inputStyle={{ width: '100%' }}
                containerStyle={{ marginTop: '20px', marginBottom: '10px' }}
              />

              <TextField
                fullWidth
                margin="normal"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />

              <TextField
                fullWidth
                margin="normal"
                label="Address"
                {...getFieldProps('address')}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>

              <LoadingButton type="submit" variant="contained" loading={loading}>
                Save Partner
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </Page>
  );
}
