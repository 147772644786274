import React, { Fragment, useState } from 'react';
import { APIs } from 'src/apis';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import {
  Avatar,
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
  colors,
} from '@mui/material';
import { partnerWallpaperSchema } from 'src/utils/validationSchemas';
import { addEditApi } from 'src/apis/admin';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import Iconify from 'src/components/Iconify';
const tempWallpaper = { mainWallpaper: '', previewWallpaper: '', price: 0, quantity: 0 };

const WallpaperModal = ({ closeModal, partnerId }) => {
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  if(partnerId.wallpapers && partnerId.wallpapers.length === 0) {
    partnerId.wallpapers.push(tempWallpaper);
  }

  const handleFormSubmit = (data, { resetForm }) => {
    setLoading(true);

    addEditApi(APIs.ADD_EDIT_PARTNER, {
      ...data,
    })
      .then(() => {
        toast.success('Added Successfully'), closeModal();
      })
      .finally(setLoading(false));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: partnerId,
    validationSchema: partnerWallpaperSchema,
    onSubmit: handleFormSubmit,
  });
  const { errors, touched, values, setFieldValue, handleSubmit, getFieldProps } = formik;
  const uploadImage = (e, type, index) => {
    setImageLoading(true);
    const file = e?.target?.files?.[0];
    const data = new FormData();
    data.append('file', file);

    addEditApi(APIs.UPLOAD_IMAGE, data)
      .then((result) => {
        if (type === 1) {
          setFieldValue(`wallpapers.${index}.mainWallpaper`, result?.profileImg);
        } else {
          setFieldValue(`wallpapers.${index}.previewWallpaper`, result?.profileImg);
        }
      })
      .finally(() => setImageLoading(false));
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography variant="h4" gutterBottom>
            Partner Wallpaper - {partnerId?.name}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ width: 1100 }}>
          <Card>
            <Box sx={{ flexGrow: 1, padding: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={<Switch {...getFieldProps('isNft')} checked={values?.isNft} />}
                    label="is NFT ?"
                    sx={{ flexGrow: 1, m: 0 }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    {...getFieldProps('wallpaperDescription')}
                    error={Boolean(touched.wallpaperDescription && errors.wallpaperDescription)}
                    helperText={touched.wallpaperDescription && errors.wallpaperDescription}
                  />
                </Grid>

                {/* <Grid item xs={12} md={12}> */}
                {/* <Typography variant="h5" gutterBottom>
                    Wallpapers:
                  </Typography> */}
                {/* </Grid> */}

                <Grid container padding={2} alignItems="center">
                  <FieldArray
                    name="wallpapers"
                    render={(arrayHelpers) => (
                      <>
                        {values?.wallpapers?.map((item, index) => (
                          <Fragment key={index}>
                            <Grid container marginTop="20px" alignItems="center" spacing={2}>
                              <Grid item xs={4} md={3}>
                                <Typography variant="subtitle2" gutterBottom>
                                  Main Wallpaper
                                </Typography>

                                <Avatar
                                  alt={'N'}
                                  src={item?.mainWallpaper}
                                  sx={{ width: 100, height: 100, marginBottom: '10px' }}
                                />

                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => uploadImage(e, 1, index)}
                                  disabled={imageLoading}
                                />

                                {touched.wallpapers?.[index]?.mainWallpaper &&
                                  errors?.wallpapers?.[index]?.mainWallpaper && (
                                    <div className="field-error">{errors?.wallpapers?.[index]?.mainWallpaper}</div>
                                  )}
                              </Grid>

                              <Grid item xs={4} md={3}>
                                <Typography variant="subtitle2" gutterBottom>
                                  Preview Wallpaper
                                </Typography>

                                <Avatar
                                  alt={'N'}
                                  src={item?.previewWallpaper}
                                  sx={{ width: 100, height: 100, marginBottom: '10px' }}
                                />

                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => uploadImage(e, 2, index)}
                                  disabled={imageLoading}
                                />

                                {touched.wallpapers?.[index]?.previewWallpaper &&
                                  errors?.wallpapers?.[index]?.previewWallpaper && (
                                    <div className="field-error">{errors?.wallpapers?.[index]?.previewWallpaper}</div>
                                  )}
                              </Grid>

                              <Grid item xs={1} md={2}>
                                <TextField
                                  fullWidth
                                  type="number"
                                  label="Price"
                                  {...getFieldProps(`wallpapers.${index}.price`)}
                                />

                                {touched.wallpapers?.[index]?.price && errors?.wallpapers?.[index]?.price && (
                                  <div className="field-error">{errors?.wallpapers?.[index]?.price}</div>
                                )}
                              </Grid>
                              <Grid item xs={1} md={2}>
                                <TextField
                                  fullWidth
                                  label="Quantity"
                                  type="number"
                                  {...getFieldProps(`wallpapers.${index}.quantity`)}
                                />

                                {touched.wallpapers?.[index]?.price && errors?.wallpapers?.[index]?.quantity && (
                                  <div className="field-error">{errors?.wallpapers?.[index]?.quantity}</div>
                                )}
                              </Grid>
                              <Grid xs={1} md={2} display="flex" justifyContent="end">
                                {values?.wallpapers?.length && (
                                  <IconButton
                                    size="large"
                                    sx={{ ml: 2, color: colors.red[500] }}
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                  >
                                    <Iconify icon="eva:minus-square-fill" />
                                  </IconButton>
                                )}

                                <IconButton
                                  size="large"
                                  sx={{ ml: 2, color: 'primary.main' }}
                                  onClick={() => {
                                    arrayHelpers.insert(index + 1, tempWallpaper);
                                  }}
                                >
                                  <Iconify icon="eva:plus-square-fill" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Fragment>
                        ))}
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={() => closeModal()}>
            Cancel
          </Button>
          <LoadingButton size="large" type="submit" variant="contained" loading={loading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
};

export default WallpaperModal;
