import { APIs } from "src/apis";
import debounce from 'lodash/debounce';
import AsyncSelect from 'react-select/async';
import { useFormik, Form, FormikProvider } from 'formik';
import { Button, Grid, Typography } from "@mui/material";
import { addEditApi, listApi } from "src/apis/admin";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const initialValues = {
    excludePartnersFromAllNotification: []
}

const customStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            minHeight: 55,
            border: '',
        };
    },
    menu: (provided, state) => {
        return {
            ...provided,
            zIndex: 2,
        };
    },
};

export default function ContestSettings({ closeModal }) {

    const [settingsData, setSettingsData] = useState(false);

    useEffect(() => {
        listApi(APIs.LIST_ADMIN_DEFAULT).then((result) => {
            const response = result?.data;
            response.excludePartnersFromAllNotification =  response?.excludePartnersFromAllNotification?.map((item) => ({
                label: item?.name,
                value: item?.id,
            }));
            setSettingsData(response);
        });
    }, []);

    const handleFormSubmit = (payload) => {
        const dataToSend = {
            id : 2
        };  

        dataToSend.excludePartnersFromAllNotification = payload?.excludePartnersFromAllNotification?.map((item) => item?.value)

        addEditApi(APIs.ADD_EDIT_ADMIN_DEFAULT, dataToSend)
        .then((result) => {
            toast.success('Updated Successfully');
        })
        .finally(() => {
            closeModal()
        });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: settingsData || initialValues,
        onSubmit: handleFormSubmit,
    });
    const { errors, touched, values, handleSubmit, setFieldValue } = formik;

    const partnerOptions = (inputValue, resolve) => {
        listApi(APIs.LIST_PARTNERS, { search: inputValue }).then((result) => {
            const filter = result?.data?.map((item) => ({
                label: item?.name,
                value: item?.id,
            }));

            resolve(filter);
        });
    };

    const loadPartners = debounce(partnerOptions, 300);

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <div className="modalContainer">
                    <div className="modalheadingrow">
                        <Typography id="modal-title" variant="h6" component="h2">
                            Settings
                        </Typography>
                    </div>

                    <Grid className="formContainer">
                        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                            <Grid item xs={12}>
                                <label style={{ fontSize: '12px' }}>Exclude Partners from All Notifications</label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    isMulti
                                    placeholder="Select Partners to Exclude"
                                    styles={customStyles}
                                    loadOptions={loadPartners}
                                    value={values?.excludePartnersFromAllNotification}
                                    onChange={(data) => {
                                        setFieldValue('excludePartnersFromAllNotification', data || []);
                                    }}
                                />
                                {Boolean(touched.excludePartnersFromAllNotification && errors.excludePartnersFromAllNotification) && (
                                    <Typography variant="caption" display="block" gutterBottom color={'red'}>
                                        {errors.excludePartnersFromAllNotification}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <div className="modalFooterContainer">
                        <Typography variant="h4" gutterBottom>
                            <Button sx={{ border: '1px solid #e0e0e0' }} onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" sx={{ ml: 2 }}>
                                Save
                            </Button>
                        </Typography>
                    </div>
                </div>
            </Form>
        </FormikProvider >
    )
}