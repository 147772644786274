import React, { useEffect, useState } from 'react';
import './index.css';
import { Typography, Grid, Button, TextField, Tooltip } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { teamSchema } from 'src/utils/validationSchemas';
import Iconify from 'src/components/Iconify';
import Select from 'react-select';
import { APIs } from 'src/apis';
import { addEditApi, listApi } from 'src/apis/admin';
import { toast } from 'react-toastify';
import UploadImage from 'src/components/uploadImage';
import { IMAGE_MAX_SIZE } from 'src/contants/contants';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ImageModal from 'src/pages/openAI/questionBankList/imageModal';

const initialValues = {
  league_id: '',
  teamName: '',
  teamAlias: '',
  image_url: '',
  hash_tag: '',
};

/*Styles from React Select */

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 55,
      border: '',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

const TeamModal = ({ closeModal, selectedTeam, setRefresh }) => {
  const [leagues, setLeagues] = useState();
  const [image, setImage] = useState();
  const [selectedData, setSelectedData] = useState();
  const [sizeError, setSizeError] = useState(false);
  const [imageData, setImageData] = useState();
  const [imageCount, setImageCount] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedQuestionImage, setSelectedQuestionImage] = useState();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);

  useEffect(() => {
    listApi(APIs.LIST_LEAGUES).then((result) => {
      setLeagues(result);
    });
    const SelectedData = {
      id: selectedTeam?.id,
      league_id: selectedTeam?.League,
      teamName: selectedTeam?.teamName,
      teamAlias: selectedTeam?.teamAlias,
      image_url: selectedTeam?.image_url,
      hash_tag: selectedTeam?.hash_tag,
    };
    setSelectedData(SelectedData);
  }, []);

  /*FORM SUBMIT HANDLER */
  const handleFormSubmit = (payload) => {
    const ApiPayload = {
      league_id: payload?.league_id?.id,
      teamName: payload?.teamName,
      teamAlias: payload?.teamAlias,
      image_url: payload?.image_url,
    };

    if(payload.hash_tag)
      ApiPayload.hash_tag = payload.hash_tag

    if (selectedTeam) {
      ApiPayload.id = selectedTeam?.id;
    }

    addEditApi(APIs.ADD_EDIT_TEAM, { ...ApiPayload }).then(() => {
      toast.success('Updated Successfully');
      setRefresh((s) => !s);
      closeModal();
    });
  };
  const handleImageChange = (e) => {
    setSizeError(false);
    if (e?.target?.files?.[0]?.size > IMAGE_MAX_SIZE) {
      setSizeError(true);
    } else {
      setImage(e?.target?.files?.[0]);
    }
  };
  const uploadImage = () => {
    const data = new FormData();
    data.append('file', image);
    addEditApi(APIs.UPLOAD_IMAGE, data).then((result) => {
      setFieldValue('image_url', result?.profileImg);
      setImage(null);
      toast.success('Uploaded Success');
    });
  };

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };

   //generate question handler
   const GenerateButtonHandler = (questionHeader) => {
      setSubmitLoading(true);
      listApi(APIs.GENERATE_IMAGE_AI, { headerText: questionHeader }).then((res) => {
        setImageData(res?.data);
        toast.success('Images Generated Successfully');
        setSubmitLoading(false);
      });
  };

  /*FORMIK INTIMIALIZATION */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: selectedData || initialValues,
    validationSchema: teamSchema,
    onSubmit: handleFormSubmit,
  });

  /*DISTRCUTING FORMIK  */
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        <div className="modalContainer">
          <div className="modalheadingrow">
            <Typography variant="h4" gutterBottom>
              {selectedTeam?.id ? 'Edit Team' : 'Add Team'}
            </Typography>
          </div>
          <Grid container className="formContainer" spacing={4}>
            <Grid item xs={12} md={12}>
              <Select
                cacheOptions
                defaultOptions
                placeholder="Select League"
                styles={customStyles}
                options={leagues}
                name="league_id"
                getOptionLabel={(v) => v?.leagueName}
                getOptionValue={(v) => v?.id}
                value={values?.league_id}
                onChange={(data) => {
                  setFieldValue('league_id', data);
                }}
              />

              {Boolean(touched.league_id && errors.league_id) && (
                <Typography variant="caption" display="block" gutterBottom color={'red'}>
                  {errors.league_id}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Team Name"
                {...getFieldProps('teamName')}
                error={Boolean(touched.teamName && errors.teamName)}
                helperText={touched.teamName && errors.teamName}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Team Alias"
                {...getFieldProps('teamAlias')}
                error={Boolean(touched.teamAlias && errors.teamAlias)}
                helperText={touched.teamAlias && errors.teamAlias}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Team HashTags (Add with #)"
                {...getFieldProps('hash_tag')}
                error={Boolean(touched.hash_tag && errors.hash_tag)}
                helperText={touched.hash_tag && errors.hash_tag}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <UploadImage
                title="Team Image"
                fieldName="image_url"
                stateImage={image}
                image={image ? URL.createObjectURL(image) : values?.image_url}
                fileSizeExceeded={sizeError}
                onChange={handleImageChange}
                uploadImage={uploadImage}
                removeImage={() => setImage(null)}
                touched={touched}
                errors={errors}
              />
            </Grid>

            {imageData?.length > 0 && (
              // SHOWCASE OF HEADER IMAGES
              <div>
                <div style={{ display: 'flex', alignItems: 'center', margin: '20px 7px' }}>
                  {imageCount >= 1 && (
                    <Tooltip title="Previous images">
                      <ReadMoreIcon
                        style={{
                          marginLeft: '10px',
                          fontSize: '30px',
                          cursor: 'pointer',
                          color: 'red',
                          transform: 'scaleX(-1)',
                        }}
                        onClick={() => {
                          setImageCount(imageCount - 1);
                        }}
                      />
                    </Tooltip>
                  )}
                  {imageData?.slice(imageCount * 4, imageCount * 4 + 4)?.map((img, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: '100px',
                          padding: '1px',
                          border: `3px solid ${selectedQuestionImage === img ? '#2065d2' : 'white'}`,
                          height: '100px',
                          marginLeft: '10px',
                          cursor: 'pointer',
                          position: 'relative',
                        }}
                      >
                        <img
                          src={img?.original}
                          style={{
                            width: '100px',
                            height: '92px',
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedQuestionImage(img);
                            setFieldValue('image_url', img?.thumbnail);
                          }}
                        />
                        <div
                          style={{
                            width: '30px',
                            height: '30px',
                            position: 'absolute',
                            top: '1px',
                            right: '1px',
                            padding: '2px',
                            backgroundColor: '#00000060',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <FullscreenIcon onClick={() => showFullImage(img?.thumbnail)} style={{ fill: '#ffffff' }} />
                        </div>
                      </div>
                    );
                  })}
                  <Tooltip title="Show more images">
                    <ReadMoreIcon
                      style={{
                        marginLeft: '10px',
                        fontSize: '30px',
                        cursor: 'pointer',
                        color: 'red',
                      }}
                      onClick={() => {
                        setImageCount(imageCount + 1);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            )}

            <Grid item xs={12} md={12}>
           
              <TextField
                fullWidth
                {...getFieldProps('imageHeaderText')}
                label="Image Header Text"
              />
              <div style={{marginTop: '10px'}}>
              <Button
                  variant="contained"
                  onClick={() => {
                    GenerateButtonHandler(values?.imageHeaderText);
                  }}
                  disabled={!values?.imageHeaderText}
                >
                  {submitLoading ? 'Generating...' : 'Generate'}
                </Button>

                <Button
                  variant="contained"
                  onClick={(e) => {
                    setImageCount(0);
                    setImageData('');
                    setFieldValue(`imageHeaderText`, '');
                  }}
                  style={{ marginLeft: '10px' }}
                >
                  {'Reset/Cancel'}
                </Button>
              </div>
               
              </Grid>
          </Grid>

          <div className="modalFooterContainer">
            <Typography variant="h4" gutterBottom>
              <Button onClick={closeModal} style={{ marginRight: '10px' }}>
                Cancel
              </Button>

              <Button variant="contained" type="submit" startIcon={<Iconify icon="eva:save-outline" />}>
                Save
              </Button>
            </Typography>
          </div>
        </div>
        <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
      </Form>
    </FormikProvider>
  );
};

export default TeamModal;
