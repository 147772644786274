import React from 'react';
import './index.css';
import {

  TextField,
  Button,
  Container,
  Typography,
  Grid,
  Switch,
  FormControlLabel
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import Select from 'react-select';
import { addEditApi, listApi } from 'src/apis/admin';
import { APIs } from 'src/apis/client';
import Iconify from 'src/components/Iconify';
import { toast } from 'react-toastify';
import { CHAT_TYPE_OPTIONS, TWITTER_TYPE_OPTIONS } from '../constants';


const COMMONN_FIELD = [
  { label: 'Category', identifier: 'category', type: 'text', isBasic: true, gridSize: 2 },
  { label: 'Sub Category', identifier: 'subCategory', type: 'text', isBasic: true, gridSize: 2 },
  { label: 'Brand', identifier: 'brand', type: 'text', isBasic: true, gridSize: 2 },
  { label: 'Topic', identifier: 'topic', type: 'text', isBasic: true, gridSize: 3 },
  { label: 'Sub Topic', identifier: 'subtopic', type: 'text', isBasic: true, gridSize: 3 },
  { label: 'Timer', identifier: 'timer', placeholder: 'Enter 0 for no timer', type: 'number', isBasic: true, gridSize: 2 },
  { label: 'TimerPoints', identifier: 'timerPoints', placeholder: 'Enter 0 for no timer', type: 'number', isBasic: true, gridSize: 2 },
  { label: 'Points', identifier: 'points', type: 'number', isBasic: true, gridSize: 2 },
  { label: 'Difficulty Level', identifier: 'difficultyLevel', type: 'text', isBasic: true, gridSize: 2 },
  { label: 'Generate Image Auto', identifier: 'generateImageAuto', isSwitch: true, gridSize: 2 },
  { label: 'Verify Question', identifier: 'isVerified', isSwitch: true, gridSize: 2 },
  { label: 'Source Url', identifier: 'source', type: 'text', isBasic: true, gridSize: 8 },
  { label: 'Source Name', identifier: 'sourceName', type: 'text', isBasic: true, gridSize: 3 },
  { label: 'Chat Type', identifier: 'chatType', options: CHAT_TYPE_OPTIONS, gridSize: 3 },
  { label: 'Twitter Type', identifier: 'twitterType', options: TWITTER_TYPE_OPTIONS, gridSize: 3 },
 
];

const BulkUpdateQuesionsModal = ({ closeModal, selectedQuestions }) => {
  const initData = {};

  const handleFormSubmit = (payload) => {

    if ('twitterType' in payload) {
      payload.twitterType = payload?.twitterType?.value === 'NA' ? null : payload?.twitterType?.value;
    }

    if ('chatType' in payload) {
      payload.chatType = payload?.chatType?.value === 'NA' ? null : payload?.chatType?.value;
    }

    payload = Object.keys(payload).reduce((obj, key) => {
      if (payload[key] !== '' && payload[key]) {
        obj[key] = payload[key];
      }
      return obj;
    }, {});

    payload.ids = selectedQuestions.map((question) => question.id);
    console.log('payload', payload);

    addEditApi(APIs.BULK_UPDATE_QUESTIONS, payload)
      .then((res) => {
        toast.success('Updated Successfully');
      })
      .catch((err) => {
        toast.error('Something went wrong');
      })
      .finally(() => {
        closeModal(true);
      });
  }

  COMMONN_FIELD.map(field => {
    initData[field.identifier] = ''
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initData },
    onSubmit: handleFormSubmit,
  });

  const { errors, touched, values, handleSubmit, setFieldValue } = formik;

  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 55,
        border: '',
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };

  return (
    <div className="modalWinnerContainer">
      <Container className="modalInnerContainer" style={{height: '78vh', overflowY: 'scroll'}}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <div className="modalheadingWinnerrow">
              <Typography variant="h4" gutterBottom>
                {"Bulk Update Question Bank"}
              </Typography>
              <Typography variant="h4" gutterBottom>
                <Button variant="contained" type='submit' startIcon={<Iconify icon="eva:save-outline" />}>
                  Update Data
                </Button>
              </Typography>
              <Typography variant="h4" gutterBottom>
                <Button onClick={closeModal} style={{ marginRight: '10px' }}>
                  Cancel
                </Button>
              </Typography>

            </div>

            <Grid containerspacing={2} padding={2}>
              Questions Selected: {selectedQuestions.map((question) => question.id).join(', ')}
            </Grid>
            <Grid container spacing={2} padding={2} style={{ paddingTop: '0px', paddingBottom: '20px' }}>
              {COMMONN_FIELD.map((field, fieldIndex) => {
                return (
                  <Grid item xs={12} md={field.gridSize} key={fieldIndex}>
                    {field?.isBasic ? (
                      <TextField
                        fullWidth
                        key={fieldIndex}
                        multiline={field?.isMutline}
                        rows={5}
                        // maxRows={4}
                        label={field.label}
                        type={field.type}
                        name={field.identifier}
                        value={values?.[field.identifier]}
                        placeholder={field?.placeholder}
                        defaultValue={values?.[field.identifier]}
                        onChange={(e) => {
                          setFieldValue(field.identifier, e.target.value);
                        }}
                        error={Boolean(touched?.[field.identifier] && errors?.[field.identifier])}
                        helperText={
                          errors?.[field.identifier] ? (
                            <div style={{ color: 'red' }}>{errors?.[field.identifier]}</div>
                          ) : (
                            <div style={{ visibility: 'hidden' }}>hidden</div>
                          )
                        }
                      />
                    ) : field?.isSwitch ? (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values?.[field.identifier]}
                            onChange={(event) => setFieldValue(field.identifier, event.target.checked)}
                          />
                        }
                        label={field.label}
                      />
                    ) : (
                      <>
                        <label style={{ fontSize: '14px' }}>{field.label}</label>
                        <Select
                          cacheOptions
                          defaultOptions
                          placeholder={field.label}
                          styles={customStyles}
                          options={field.options}
                          name={field.identifier}
                          value={values?.[field.identifier]}
                          getOptionLabel={(v) => v?.label}
                          getOptionValue={(v) => v?.value}
                          onChange={(data) => setFieldValue(field.identifier, data)}
                        />
                        {errors?.[field.identifier] ? (
                          <div style={{ color: 'red', fontSize: '0.75rem' }}>{errors?.[field.identifier]}</div>
                        ) : (
                          <div style={{ visibility: 'hidden' }}>hidden</div>
                        )}
                      </>
                    )}
                  </Grid>
                )
              })
              }
            </Grid>
          </Form>

        </FormikProvider>
      </Container>
    </div>
  );
};

export default BulkUpdateQuesionsModal;
