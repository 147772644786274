import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import Router from './router';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { toast, ToastContainer, Slide } from 'react-toastify';

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider>
          <ScrollToTop />
          <BaseOptionChartStyle /> 
          
          <Router />
          <ToastContainer transition={Slide} hideProgressBar position={toast.POSITION.BOTTOM_RIGHT} autoClose={3000} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}
