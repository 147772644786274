import React, { useState } from 'react';
import {
  Button,
  Dialog,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  Stack,
  Avatar,
  Typography,
} from '@mui/material';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';

const TABLE_HEAD = [
  { id: 'mainWallpaper', label: ' Main Wallpaper' },
  { id: 'previewWallpaper', label: 'Preview Wallpaper' },
  { id: 'price', label: 'Price' },
];

const DialogModal = ({ openDialog, handleClose, selectedRow }) => {
  const [selected, setSelected] = useState([]);
  return (
    <Dialog open={openDialog} fullWidth disableEscapeKeyDown>
      <Page style={{ padding: '20px', minHeight: '50vh', width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          Wallpapers
        </Typography>
        <Scrollbar>
          <>
            {selectedRow?.wallpapers?.length > 0 ? (
              <TableContainer sx={{ minWidth: 300 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell key={headCell.id} align={(headCell.align = 'center')}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {selectedRow?.wallpapers?.map((row, index) => {
                      const { mainWallpaper, previewWallpaper, price } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            <Stack direction="row" justifyContent="center">
                              <img src={mainWallpaper} alt={index} />
                            </Stack>
                          </TableCell>
                          <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                            <Stack direction="row" justifyContent="center">
                              <img src={previewWallpaper} alt={index} />
                            </Stack>
                          </TableCell>
                          <TableCell align="center" width="32%" fontSize="17px">
                            <Typography variant="h6" gutterBottom>
                              {price}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: '40vh',
                  alignItems: 'center',
                }}
              >
                <Typography gutterBottom>No Data Found</Typography>
              </div>
            )}
          </>
        </Scrollbar>
        <Stack direction="row" justifyContent="end" spacing={2} paddingTop="10px" paddingBottom="10px">
          <Button onClick={handleClose}>Cancel</Button>
        </Stack>
      </Page>
    </Dialog>
  );
};
export default DialogModal;
