import React, { useEffect, useState, useMemo } from 'react';
import './index.css';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  CircularProgress,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { listApi } from 'src/apis/admin';
import { APIs } from 'src/apis/client';
import { UserPartnerPointsListTableHeading } from 'src/contants/contants';


const PartnerPoints = ({ closeModal, userId }) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState();
  const [timeInterval, setTimeInterval] = useState(false);

  const noDataHandler = () => {
    setTimeout(() => {
      setTimeInterval(true);
    }, 2000);
  };
  const boxSX = {
    '&:hover': {
      cursor: 'pointer',
      color: '#1769aa',
    },
  };

  ///Getting Particular Contest Winner List/////////
  useEffect(() => {
    setLoading(true);
    listApi(APIs.LIST_USERS_POINTS, { userId: userId?.id })
      .then((result) => {
        setList(result?.data);
      })
      .finally(setLoading(false));
  }, []);

  return (
    <div className="modalWinnerContainer">
      <Container className="modalInnerContainer">
        <div className="modalheadingWinnerrow">
          <Typography variant="h4" gutterBottom>
            {userId?.name}
          </Typography>
        </div>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: 500 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {UserPartnerPointsListTableHeading.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {!!list?.length > 0 ? (
                    <TableBody>
                      {list?.map((row, index) => {
                        const { id, partnerData, totalPoints, redeemed } = row;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox">
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={partnerData?.name} src={partnerData?.profileImage} />
                                <Typography variant="subtitle2" noWrap>
                                  {partnerData?.name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>{totalPoints}</TableCell>
                            <TableCell>{redeemed}</TableCell>
                            <TableCell>{totalPoints - redeemed}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    ''
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!!list?.length == 0 && (
          <Typography variant="subtitle2" align="center" style={{ marginTop: '20px', marginBottom: '90px' }}>
            {noDataHandler()}
            {!timeInterval ? 'Loading...' : 'No Data Found'}
          </Typography>
        )}
        <div className="modalWinnnerFooterContainer">
          <Typography variant="h4" gutterBottom>
            <Button onClick={closeModal} style={{ marginRight: '10px' }}>
              Cancel
            </Button>
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default PartnerPoints;
