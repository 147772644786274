import React from 'react';
import { Button } from '@mui/material';
import Iconify from '../../components/Iconify';

const BackButton = ({ onClick }) => {
  return (
    <Button
      style={{ marginRight: '10px', color: 'black', backgroundColor: 'transparent' }}
      startIcon={<Iconify icon="eva:arrow-back-outline" />}
      onClick={onClick}
    >
      Back
    </Button>
  );
};

export default BackButton;
