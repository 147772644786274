import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import Iconify from '../../../components/Iconify';
import { APIs } from '../../../apis';
import debounce from 'lodash/debounce';
import { listApi } from 'src/apis/admin';
import moment from 'moment';
import { ROUTES } from 'src/router/routes';
import { Typography, Box, Grid, Button } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { cloneRewardSchema } from 'src/utils/validationSchemas';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { DATE_FORMAT_TIME } from 'src/contants/contants';

const initialValues = {
  contestValue: '',
};

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 55,
      border: '',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

const CloneRewardModal = ({ closeModal, selectedContest }) => {
  const navigate = useNavigate();

  const handleFormSubmit = (payload) => {
    navigate(`/reward-rules/edit/${payload?.contestValue?.value}?intowhichcontestid=${selectedContest}`);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: cloneRewardSchema,
    onSubmit: handleFormSubmit,
  });

  const promiseContestDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_CONTESTS, { search: inputValue }).then((result) => {
      const filter = result?.data.map((item) => ({
        label: (
          <div className="optionStyles">
            <img src={item?.imageLink} height="30px" width="30px" />
            <div className="selectText">
              {item.name} | {moment(item.startDateTime).format(DATE_FORMAT_TIME)}
            </div>
          </div>
        ),
        value: item.id,
      }));
      resolve(filter);
    });
  };

  const loadContestData = debounce(promiseContestDataOptions, 300);
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        <div className="modalContainer">
          <div className="modalheadingrow">
            <Typography variant="h4" gutterBottom>
              Clone Rewards From Which Contest ?
            </Typography>
          </div>
          <Box sx={{ flexGrow: 1, padding: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  placeholder="Select Contest"
                  styles={customStyles}
                  loadOptions={loadContestData}
                  value={values?.contestValue}
                  onChange={(data) => {
                    setFieldValue('contestValue', data);
                  }}
                />
                {Boolean(touched.contestValue && errors.contestValue) && (
                  <Typography variant="caption" display="block" gutterBottom color={'red'}>
                    {errors.contestValue}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
          <div className="modalFooterContainer">
            <Typography variant="h4" gutterBottom>
              <Button onClick={closeModal} style={{ marginRight: '10px' }}>
                Cancel
              </Button>

              <Button variant="contained" type="submit" startIcon={<Iconify icon="eva:save-outline" />}>
                Clone Rewards
              </Button>
            </Typography>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};
export default CloneRewardModal;
