import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import {
  AppWidgetSummary,
} from '../../../sections/@dashboard/app';
import { useSelector } from 'react-redux';
import { APIs } from 'src/apis';
import { listApi } from 'src/apis/admin';
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import Scrollbar from 'src/components/Scrollbar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useParams } from 'react-router-dom';
import { DATE_FORMAT, DATE_FORMAT_TIME, openLeaderboardLink, toolTipMessage } from 'src/contants/contants';
import ImageModal from 'src/pages/openAI/questionBankList/imageModal';


const TABLE_HEAD = [
  { id: 'image', label: 'Wallpaper Image', alignRight: false },
  { id: 'contestName', label: 'Contest Name', alignRight: false },
  { id: 'username', label: 'Purchased By ', alignRight: false },
  { id: 'pricePaid', label: 'Price Paid', alignRight: false },
  { id: 'createdAt', label: 'Purchase Date', alignRight: false },
];

const TOP_GAMERS_HEAD = [
  { id: '#', label: '#', alignRight: false },
  { id: 'name', label: 'Gamer Name', alignRight: false },
  { id: 'points', label: 'Points', alignRight: false },
  { id: 'enteries', label: 'Entries (#)', alignRight: false },
];

const TOP_CONTESTS_HEAD = [
  { id: '#', label: '#', alignRight: false },
  { id: 'name', label: 'Contest Name', alignRight: false },
  { id: 'points', label: 'Entries (#)', alignRight: false },
];

const PartnerDashboard = () => {
  const { name, isLoggedIn, id: loggedInId } = useSelector((state) => state.auth);
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
  });
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [topContestData, setTopContestData] = useState([]);
  const [dashboardData, setDashboardData] = useState();
  const [contest, setContest] = useState();
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(false);
  const [topGamerloading, setTopGamesLoading] = useState(false);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const { id } = useParams();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);
  const [entriesSort, setEnteriesSort] = useState(false);

  const handleButtonClick = (option) => {
    setSelectedOption(option);
    switch (option) {
      case 'All':
        setSelectedDate({
          startDate: moment('2020-01-01').toDate(),
          endDate: moment().toDate(),
        });
        break;
      case 'Daily':
        setSelectedDate({
          startDate: moment().toDate(),
          endDate: moment().toDate(),
        });
        break;
      case 'Weekly':
        setSelectedDate({
          startDate: moment().startOf('week').toDate(),
          endDate: moment().endOf('week').toDate(),
        });
        break;
      case 'Monthly':
        setSelectedDate({
          startDate: moment().startOf('month').toDate(),
          endDate: moment().endOf('month').toDate(),
        });
        break;
      case 'Reset':
        setSelectedDate({
          startDate: moment('2020-01-01').toDate(),
          endDate: moment().toDate(),
        });
        setContest('');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (((selectedDate?.endDate && selectedDate?.startDate) || contest?.value) && !id) {
      setLoading(true);
      listApi(APIs.PARTNER_DASHBOARD, {
        startDate: selectedDate?.startDate,
        endDate: selectedDate?.endDate,
        contestId: contest?.value,
      }).then((res) => {
        setDashboardData(res?.data);
        setLoading(false);
      });
    } else if (((selectedDate?.endDate && selectedDate?.startDate) || contest?.value) && id) {
      setLoading(true);
      listApi(APIs.PARTNER_DASHBOARD, {
        startDate: selectedDate?.startDate,
        endDate: selectedDate?.endDate,
        contestId: contest?.value,
        partnerId: id,
      }).then((res) => {
        setDashboardData(res?.data);
        setLoading(false);
      });
    }
  }, [selectedDate, contest]);

  useEffect(() => {
    setTopGamesLoading(true)
    listApi(APIs.OVERALL_LEADERBOARD, {
      partnerId: id || loggedInId
    }).then((res) => {
      if(entriesSort) {
        setLeaderboardData(res?.data?.sort((a, b) => b?.contestentrycount - a?.contestentrycount)?.splice(0, 5));
      } else   setLeaderboardData(res?.data?.splice(0, 5));
    })
    .finally(() => setTopGamesLoading(false));
  }, [entriesSort]);

  useEffect(() => {
    listApi(APIs.PARTNER_TOP_CONTESTS, {
      partnerId: id || loggedInId
    }).then((res) => {
      setTopContestData(res?.data?.splice(0, 5));
    });
  }, []);

  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 55,
        border: '',
        marginBottom: 10,
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };
  const contestOptions = (inputValue, resolve) => {
    if (id) {
      listApi(APIs.LIST_PARTNERS_CONTEST, { search: inputValue, partnerId: id }).then((result) => {
        const filter = result?.data.map((item) => ({
          label: (
            <div style={{ display: 'flex' }}>
              <img src={item?.imageLink} height="30px" width="30px" />
              <div style={{ paddingLeft: '10px' }}>{item.name}</div>
            </div>
          ),
          value: item?.id,
        }));
        resolve(filter);
      });
    } else {
      listApi(APIs.LIST_PARTNERS_CONTEST, { search: inputValue }).then((result) => {
        const filter = result?.data.map((item) => ({
          label: (
            <div style={{ display: 'flex' }}>
              <img src={item?.imageLink} height="30px" width="30px" />
              <div style={{ paddingLeft: '10px' }}>{item.name}</div>
            </div>
          ),
          value: item?.id,
        }));
        resolve(filter);
      });
    }
  };

  const loadContest = debounce(contestOptions, 300);

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };

  return (
    <Page title="Partner Dashboard">
      {!isLoggedIn && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
          sx={{ padding: '10px' }}
        >
        </Grid>
      )}

      <Container maxWidth="xl" sx={{ mt: 3 }} >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            marginBottom: 5,
            ...(isDesktop &&
              !isLoggedIn && {
              paddingLeft: 15,
              paddingRight: 15,
            }),
          }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Typography align="justify" variant="h4">
              Hi, {dashboardData?.partnerName}

            </Typography>
            {id && <a href={openLeaderboardLink + id} target="_blank" rel="noreferrer">View Open Leaderboard</a>}
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <Button
              variant="contained"
              onClick={() => handleButtonClick('All')}
              sx={{
                height: '70%',
                width: '100%',
                backgroundColor: selectedOption === 'All' ? '#FC2759' : '',
                // color: selectedOption === 'All' ? 'white' : 'initial',
              }}
            >
              All
            </Button>
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <Button
              variant="contained"
              onClick={() => handleButtonClick('Daily')}
              sx={{
                height: '70%',
                width: '100%',
                backgroundColor: selectedOption === 'Daily' ? '#FC2759' : '',
                // color: selectedOption === 'Daily' ? 'white' : 'initial',
              }}
            >
              Daily
            </Button>
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <Button
              variant="contained"
              onClick={() => handleButtonClick('Weekly')}
              sx={{
                height: '70%',
                width: '100%',
                backgroundColor: selectedOption === 'Weekly' ? '#FC2759' : '',
                // color: selectedOption === 'Daily' ? 'white' : 'initial',
              }}
            >
              Weekly
            </Button>
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <Button
              variant="contained"
              onClick={() => handleButtonClick('Monthly')}
              sx={{
                height: '70%',
                width: '100%',
                backgroundColor: selectedOption === 'Monthly' ? '#FC2759' : '',
                // color: selectedOption === 'Daily' ? 'white' : 'initial',
              }}
            >
              Monthly
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AsyncSelect
              cacheOptions
              defaultOptions
              placeholder="Filter by Contest"
              styles={customStyles}
              loadOptions={loadContest}
              value={contest}
              onChange={(data) => {
                setContest(data);
                // onChangeHandler();
              }}
            />
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                value={selectedDate?.startDate}
                label="Start Date"
                onChange={(newDate) => {
                  setSelectedDate({ ...selectedDate, startDate: newDate });
                  // onChangeHandler();
                }}
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                value={selectedDate?.endDate}
                label="End Date"
                onChange={(newDate) => {
                  setSelectedDate({ ...selectedDate, endDate: newDate });
                  // onChangeHandler();
                }}
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <Button
              variant="contained"
              onClick={() => handleButtonClick('Reset')}
              sx={{
                height: '70%',
                width: '100%',
                // backgroundColor: selectedOption === 'Monthly' ? '#FC2759' : '',
                // color: selectedOption === 'Daily' ? 'white' : 'initial',
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={6} sm={6} md={2}>

            <AppWidgetSummary
              title="New Gamers"
              description="The # of new gamers who have registered to play"
              total={dashboardData?.newSignup}
              color="info"
              icon={'material-symbols:login'}
            />

          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <AppWidgetSummary
              description="# of gamers who have accessed game via your partner link"
              title="# Playing Contests"
              total={dashboardData?.contestPlayFromLink}
              icon={'material-symbols:quiz'}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={2}>
            <AppWidgetSummary
              description="# of gamers within a specific date range"
              title="# Players Played"
              total={dashboardData?.contestTotalPlayed}
              icon={'material-symbols:quiz'}
            />
          </Grid>

          {/* <Grid item xs={6} sm={6} md={2}>
            <AppWidgetSummary
              title="Link Impressions"
              total={dashboardData?.redirect}
              color="warning"
              icon={'ooui:article-redirect-ltr'}
            />
          </Grid> */}

          <Grid item xs={6} sm={6} md={2}>
            <AppWidgetSummary
              description="# of partner Wallpaper items sold"
              title="# Wallpapers Sold"
              total={dashboardData?.totalWallpaperSell}
              color="error"
              icon={'material-symbols:wallpaper'}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <AppWidgetSummary
              description="Dollar ($) value of Wallpaper items sold"
              title="$ Wallpapers Sold"
              total={dashboardData?.wallpaperPriceTotal}
              icon={'ic:outline-price-change'}
            />
          </Grid>
        </Grid>

        <Scrollbar>
          <TableContainer
            sx={{
              minWidth: 800,
              paddingTop: 8,
              paddingBottom: 15,
              ...(isDesktop &&
                !isLoggedIn && {
                paddingLeft: 15,
                paddingRight: 15,
              }),
            }}
          >


            {/* <-- TOP CONTESTS SECTION START --> */}

            <Typography align="justify" variant="h4" >
              ⭐ Your Top Contests
            </Typography>
            {loading ? (
              <Stack alignItems="center">
                <CircularProgress />
              </Stack>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    {TOP_CONTESTS_HEAD.map((headCell) => (
                      <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                        <TableSortLabel hideSortIcon active={false} direction={'asc'}>
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {topContestData?.map((row, index) => {
                    const { name, imageLink, startDateTime, score } = row;
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">
                          {index + 1}
                        </TableCell>

                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Tooltip title={toolTipMessage.viewImage}>
                              <Avatar alt={name} src={imageLink} style={{ cursor: 'pointer' }} onClick={() => showFullImage(imageLink)} />
                            </Tooltip>
                            <div>
                              <p>
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </p>
                              <p> {moment(startDateTime).format(DATE_FORMAT_TIME)}</p>
                            </div>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          {score}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
            <hr></hr>

            {/* <-- TOP GAMERS SECTION START --> */}
            <Typography align="justify" variant="h4" style={{ marginTop: 30 }}>
              🎮 Your Top Gamers
             
              <FormControlLabel
                sx={{ float: 'right' }}
                control={
                  <Switch
                    checked={entriesSort}
                    onChange={(event) => setEnteriesSort(event.target.checked)}
                  />
                }
                label={`Sort By Entries`}
              />
            </Typography>

            <span className='text'>(Deafult sort by Points)</span>
            {topGamerloading ? (
              <Stack alignItems="center">
                <CircularProgress />
              </Stack>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    {TOP_GAMERS_HEAD.map((headCell) => (
                      <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                        <TableSortLabel hideSortIcon active={false} direction={'asc'}>
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {leaderboardData?.map((row, index) => {
                    const { user_id, userData, rank, score, contestentrycount } = row;
                    return (
                      <TableRow
                        hover
                        key={user_id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">
                          {rank}
                        </TableCell>

                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={userData?.name} src={userData?.profileImg} />
                            <div>
                              <p>
                                <Typography variant="subtitle2" noWrap>
                                  {userData?.name}
                                </Typography>
                              </p>
                            </div>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          {score} xp
                        </TableCell>
                        <TableCell align="left">
                          {contestentrycount}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
            <hr></hr>


            {/* <-- WALLPAPER PURCHASE SECTION START --> */}
            <Typography align="justify" variant="h4" style={{ marginTop: 30 }}>
              🛍️ Wallpaper Purchases
            </Typography>
            {loading ? (
              <Stack alignItems="center">
                <CircularProgress />
              </Stack>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                        <TableSortLabel hideSortIcon active={false} direction={'asc'}>
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {dashboardData?.wallpaperPurchaseList?.map((row, index) => {
                    const { userId, userData, pricePaid, contestData, wallpaperUrl, createdAt } = row;
                    return (
                      <TableRow
                        hover
                        key={userId}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2} style={{ cursor: 'pointer' }}>
                            <Tooltip title={toolTipMessage.viewImage}>
                              <Avatar alt={userData?.name} src={wallpaperUrl} onClick={() => showFullImage(wallpaperUrl)} />
                            </Tooltip>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={contestData?.imageLink} />
                            <div>
                              <p>
                                <Typography variant="subtitle2" noWrap>
                                  {contestData?.name}
                                </Typography>
                              </p>
                            </div>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={userData?.name} src={userData?.profileImg} />
                            <div>
                              <p>
                                <Typography variant="subtitle2" noWrap>
                                  {userData?.name}
                                </Typography>
                              </p>
                            </div>
                          </Stack>

                        </TableCell>

                        <TableCell align="left">
                          <Typography sx={{ width: '70px' }} noWrap>
                            {pricePaid || '-'}$
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography sx={{ width: '120px' }} noWrap>
                            {moment(createdAt).format(DATE_FORMAT) || '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}


          </TableContainer>
        </Scrollbar>


      </Container>
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </Page>
  );
};

export default PartnerDashboard;
