import React, { useState } from 'react';
import { APIs } from 'src/apis';
import AsyncSelect from 'react-select/async';
import { constant, debounce } from 'lodash';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { assignPartnerPointsSchema } from 'src/utils/validationSchemas';
import { addEditApi, listApi } from 'src/apis/admin';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

const initialValues = {
  points: 0,
  partnerId: '',
  contestId:'',
};
const AssignPartnerPoints = ({ closeModal, userId }) => {
  const [loading, setLoading] = useState(false);
  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 55,
        border: '',
        marginBottom: 10,
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };
  const partnerOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_PARTNERS, { search: inputValue }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: (
          <div className="optionStyles">
            <img src={item?.profileImage} height="30px" width="30px" />
            <div className="selectText">{item.name}</div>
          </div>
        ),
        value: item?.id,
      }));

      resolve(filter);
    });
  };
  const loadPartners = debounce(partnerOptions, 300);
  const contestOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_CONTESTS, { search: inputValue }).then((result) => {
      const filter = result?.data.map((item) => ({
        label: (
          <div style={{ display: 'flex' }}>
            <img src={item?.imageLink} height="30px" width="30px" />
            <div style={{ paddingLeft: '10px' }}>
              {item.name}
            </div>
          </div>
        ),
        value: item?.id,
      }));
      resolve(filter);
    });
  };

  const loadContest = debounce(contestOptions, 300);

  const handleFormSubmit = (data, { resetForm }) => {
    setLoading(true);
    data.partnerId = data?.partnerId?.value;
    addEditApi(APIs.ASSIGN_PARTNER_POINTS, {
      ...data,
      userId: userId?.id,
      contestId: data?.contestId?.value,
    })
      .then(() => {
        toast.success('Added Successfully'), closeModal();
      })
      .finally(setLoading(false));
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: assignPartnerPointsSchema,
    onSubmit: handleFormSubmit,
  });
  const { errors, touched, values, setFieldValue, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <DialogTitle>Assign Partners Points - {userId?.name}</DialogTitle>
        <DialogContent>
          <Grid item xs={12} md={6}>
            <AsyncSelect
              cacheOptions
              defaultOptions
              placeholder="Partner"
              styles={customStyles}
              loadOptions={loadPartners}
              value={values?.partnerId}
              onChange={(data) => {
                setFieldValue('partnerId', data);
              }}
            />
            {Boolean(touched.partnerId && errors.partnerId) && (
              <Typography variant="caption" display="block" gutterBottom color={'red'}>
                {errors.partnerId}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <AsyncSelect
              cacheOptions
              defaultOptions
              placeholder="Contest"
              styles={customStyles}
              loadOptions={loadContest}
              value={values?.contestId}
              onChange={(data) => {
                setFieldValue('contestId', data);
              }}
            />
            {Boolean(touched.contestId && errors.contestId) && (
              <Typography variant="caption" display="block" gutterBottom color={'red'}>
                {errors.contestId}
              </Typography>
            )}
          </Grid>
          <TextField
            fullWidth
            type="number"
            margin="normal"
            label="Points"
            {...getFieldProps('points')}
            error={Boolean(touched.points && errors.points)}
            helperText={touched.points && errors.points}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closeModal();
              formik.resetForm({
                values: initialValues,
              });
            }}
          >
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={loading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
};

export default AssignPartnerPoints;
