import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Dialog,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Grid,
} from '@mui/material';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';
import { listApi } from 'src/apis/admin';
import { APIs } from 'src/apis/';
import { pieChartColors, statsHeaderOptions } from 'src/contants/contants';
import QuestionStatsPieChart from '../statsPieChart';
import { YoutubeSearchedForOutlined } from '@material-ui/icons';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

//Styles for filter //
const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 56,
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

const selectMessage = 'Please select atleast 1 question to show stats'

const QuestionStats = () => {
  const [partnerFilterSelect, setPartnerFilterSelect] = useState([]);
  const [questionFilterSelect, setQuestionFilterSelect] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handleSubmit = () => {
    setLoading(true);
    const data = {};

    if (startDate && endDate) {
      data.startDate = startDate
      data.endDate = endDate
    }

    if (partnerFilterSelect) {
      data.partnerIds = partnerFilterSelect.map((item) => item.value);
    }

    if (questionFilterSelect) {
      data.questions = questionFilterSelect.map((item) => item.value);
    }

    listApi(APIs.QUESTION_STATS, { ...data })
      .then((res) => {
        setTableData(res?.data);
        setRowsPerPage(res?.data?.length);
        setTotalCount(res?.data?.length);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  /*User Filter Api  */
  const promiseQuestionDataOptions = (inputValue, resolve) => {
    listApi(APIs.QUESTION_BANK_LIST, { search: inputValue, fromJsonCreate: true, isVerified: true }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: (
          <div className="optionStyles">
            <img src={item?.questionHImage} height="30px" width="30px" />
            <div className="selectText">
              {item?.id} | {item?.question}
            </div>
          </div>
        ),
        value: item
      }));
      resolve(filter);
    });
  };

  const promisePartnerDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_PARTNERS, { search: inputValue }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={item?.name} src={item?.profileImage} />
            <Typography variant="subtitle2" noWrap>
              {item?.name}
            </Typography>
          </Stack>
        ),
        value: item?.id,
      }));
      resolve(filter);
    });
  };

  const loadPartnerData = debounce(promisePartnerDataOptions, 300);
  const loadQuestionsData = debounce(promiseQuestionDataOptions, 300);

  /*HANLDER FOR PAGES CHANGING */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /*HANLDER FOR ROW PAGES */

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const countTotal = (options) => {
    let sum = 0;
    if (options?.length > 0) {
      options?.map((item) => {
        sum += Object.values(item)[0]
      })
    }
    return sum
  }

  return (
    <Page title="Question Stats">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Question Stats
          </Typography>
        </Stack>

        <Card>
          <Grid container spacing={2} padding={1}>
            <Grid xs={12} md={6} style={{ paddingTop: '20px', marginLeft: '20px' }}>
              <label>Select Questions to Filter</label>
              <AsyncSelect
                isMulti
                isClearable
                cacheOptions
                defaultOptions
                placeholder="Filter by Questions"
                styles={customStyles}
                loadOptions={loadQuestionsData}
                value={questionFilterSelect}
                onChange={(data) => {
                  setQuestionFilterSelect(data);
                }}
              />
            </Grid>
            <Grid xs={12} md={4} style={{ paddingTop: '20px', marginLeft: '20px' }}>
              <label>Select Partner to Filter</label>
              <AsyncSelect
                isMulti
                isClearable
                cacheOptions
                defaultOptions
                placeholder="Filter by Partner"
                styles={customStyles}
                loadOptions={loadPartnerData}
                value={partnerFilterSelect}
                onChange={(data) => {
                  setPartnerFilterSelect(data);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} padding={1}>
            <Grid xs={12} md={2} style={{ paddingTop: '20px', marginLeft: '20px' }}>
              <label>Start Date</label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  onChange={(date) => setStartDate(date)}
                  disablePast={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                    />
                  )}
                />

              </LocalizationProvider>

            </Grid>
            <Grid xs={12} md={2} style={{ paddingTop: '20px', marginLeft: '20px' }}>
              <label>End Date</label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  onChange={(date) => setEndDate(date)}
                  disablePast={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={12} md={2} style={{ paddingTop: '50px', marginLeft: '10px' }}>
              <Button
                variant="contained"
                type="submit"
                onClick={handleSubmit}
                startIcon={<YoutubeSearchedForOutlined />}
                disabled={questionFilterSelect.length === 0 || loading}
              >
                {loading ? 'Building...' : 'Show Stats'}
              </Button>
              {questionFilterSelect.length === 0 &&
                <Typography variant="subtitle2" break-spaces style={{ color: 'red' }}>
                  {selectMessage}
                </Typography>
              }
            </Grid>
          </Grid>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {statsHeaderOptions.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {!!tableData?.length > 0 ? (
                    <TableBody>
                      {tableData?.map((row, index) => {
                        const {
                          id, question, questionHImage, options, correct, nameOptions,
                          countOptions, choices
                        } = row

                        return (
                          <TableRow
                            hover
                            key={index}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell align="center">{id}</TableCell>
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={id} src={questionHImage} />
                                <Typography variant="subtitle2" break-spaces style={{ width: '120px' }}>
                                  {question}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">
                              {options?.map((option, index) => (
                                <p style={{ width: 270, marginBottom: 5 }}>
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    {nameOptions && nameOptions.length ?
                                      (
                                        <Typography variant="subtitle2" break-spaces>
                                          {nameOptions[index]} : {(Object.values(option)[0]).toFixed(1)}%
                                        </Typography>
                                      ) : (
                                        <Typography variant="subtitle2" break-spaces>
                                          {Object.keys(option)[0]} : {(Object.values(option)[0]).toFixed(1)}%
                                        </Typography>
                                      )
                                    }
                                  </Stack>
                                </p>
                              ))}
                            </TableCell>
                            <TableCell align="center">
                              <QuestionStatsPieChart
                                title="Stats"
                                chartData={options?.map((option, index) => {
                                  return { label: nameOptions && nameOptions.length ? nameOptions[index] : Object.keys(option)[0], value: parseFloat((Object.values(option)[0]).toFixed(1)) };
                                })}
                                chartColors={pieChartColors}
                              />
                            </TableCell>
                            <TableCell align="left">
                              {countOptions?.map((option, index) => (
                                <Typography variant="subtitle2">
                                  <li style={{ width: 200, marginBottom: 5, color: pieChartColors[index] }}>
                                    {nameOptions && nameOptions.length ? nameOptions[index] : Object.keys(option)[0]} : {Object.values(option)[0]}
                                  </li>
                                </Typography>
                              ))}
                              {countOptions?.length > 0 &&
                                <Typography variant="subtitle2" break-spaces>
                                  Total : {countTotal(countOptions)}
                                </Typography>
                              }
                            </TableCell>

                            <TableCell sx={{ minWidth: 300 }}>
                              <Typography variant="subtitle2">
                                {correct && correct?.map(index =>
                                  <p style={{ width: 270, marginBottom: 5 }}>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      {
                                        <Typography variant="subtitle2" break-spaces>
                                          {nameOptions && nameOptions.length ? nameOptions[index - 1] : choices[index - 1]}
                                        </Typography>
                                      }
                                    </Stack>
                                  </p>
                                )}
                                <p>({correct && correct.join(', ')})</p>
                              </Typography>
                            </TableCell>

                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <Typography variant="subtitle2" break-spaces style={{ color: 'red' }}>
                          {selectMessage}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>
    </Page>
  );
};

export default QuestionStats;
