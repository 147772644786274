import { FormikProvider, useFormik, Form } from 'formik';
import { Dialog, Typography, Button, Grid } from '@mui/material';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { addEditApi } from 'src/apis/admin';
import { APIs } from 'src/apis';
import { toast } from 'react-toastify';
import { useState } from 'react';

const initialValues = {
  question: [],
};

const JSONViewerModal = ({ isOpen, closeModal }) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  
  //function to handle the form submit
  const handleFormSubmit = (payload, { resetForm }) => {
    setSubmitLoading(true);
    addEditApi(APIs.SAVE_QUESTION_FROM_JSON, payload)
      .then((res) => {
        toast.success('Updated Successfully');
        closeModal(true);
        resetForm();
      })
      .finally(() => setSubmitLoading(false));
  };

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialValues,
    onSubmit: handleFormSubmit,
  });

  const { values, handleSubmit, setFieldValue } = formik;

  return (
    <Dialog open={isOpen} fullWidth>
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <div className="modalContainer">
            <div className="modalheadingrow">
              <Typography variant="h4" gutterBottom>
                Add JSON Questions
              </Typography>
            </div>
            <Grid className="formContainer">
              <Grid>
                <JSONInput
                  name="QuizQuestions"
                  colors={{ background: '#fefffe', default: 'black', colon: 'black', keys: '#386fa4' }}
                  style={{
                    body: { fontSize: 14 },
                    contentBox: { backgroundColor: 'white', border: '2px solid grey', color: 'grey' },
                    warningBox: { backgroundColor: 'lightyellow' },
                  }}
                  locale={locale}
                  height="400px"
                  width="100"
                  placeholder={values?.question || []}
                  onChange={(data) => {
                    console.log(data);
                    if (
                      !Array.isArray(data) ||
                      (Array.isArray(data) && data.length > 0 && Object.keys(data[0]).length > 0)
                    ) {
                      setFieldValue('question', data?.jsObject ?? null);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <div className="modalFooterContainer">
              <Typography variant="h4" gutterBottom>
                <Button onClick={closeModal} style={{ marginRight: '10px' }}>
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  // startIcon={<Iconify icon="eva:save-outline" />}
                >
                  {submitLoading ? 'Submiting...' : 'Submit'}
                </Button>
              </Typography>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default JSONViewerModal;
