import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Dialog,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Grid,
} from '@mui/material';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import { UserListToolbar } from '../../../sections/@dashboard/user';
import {listApi } from 'src/apis/admin';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { APIs } from '../../../apis';
import MoreMenu from 'src/components/table/MoreMenu';
import WinnerList from '../../contests/winnerList';
import { DATE_FORMAT, DATE_FORMAT_TIME } from 'src/contants/contants';
import ImageModal from '../../openAI/questionBankList/imageModal';
import { useSelector } from 'react-redux';
import ContestStats from '../../contests/contestStatsModal';

const TABLE_HEAD = [
  { id: '' },
  { id: 'name', label: 'Contest Name', alignRight: false },
  { id: 'contestType', label: 'Contest Type', alignRight: false },
  { id: 'status', label: 'Contest Status', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'startDateTime', label: 'Start DateTime', alignRight: false },
  { id: 'endDateTime', label: 'End DateTime', alignRight: false },
  { id: 'teams', label: 'Teams', alignRight: false },
  { id: 'weekName', label: 'Week Name', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'isAgeNeeded', label: 'Is Age Needed?', alignRight: false },
  { id: 'minimumAge', label: 'Minimum Age', alignRight: false },
  { id: 'termsLink', label: 'TermsLink', alignRight: false },
  { id: 'rulesLink', label: 'Rules Link', alignRight: false },
  { id: 'createdAt', label: 'Created at', alignRight: false },
];

export default function PartnerContests() {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedContest, setSelectedContest] = useState();
  const [openWinnerList, setOpenWinnerList] = useState(false);
  const [openContestStats, setContestStats] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);
  const user = useSelector((state) => state.auth);

  useEffect(() => {
    listing();
  }, [refresh, rowsPerPage, searchFilter, page]);
  const id = useParams();

  useEffect(() => {
    if (id?.id > 0) {
      setSelectedContest(id);
      setOpenWinnerList(true);
    }
  }, [id]);

  const listing = () => {
    setLoading(true);
    const data = {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      search: searchFilter,
    };

    listApi(APIs.LIST_CONTESTS, { ...data, partnerId: user?.id })
      .then((res) => {
        setUsers(res?.data);
        setTotalCount(res?.count);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  /*close Winner list Modal*/

  const closeWinnerList = () => {
    setSelectedContest('');
    setOpenWinnerList(!openWinnerList);
  };

  const closeContestStats = () => {
    setSelectedContest('');
    setContestStats(!openContestStats);
  };

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };

  return (
    <Page title="Contests">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Contests
          </Typography>
        </Stack>

        <Card>
          <Grid container spacing={2} padding={1}>
            <Grid>
              <UserListToolbar numSelected={selected.length} filterName={search} onFilterName={handleSearch} placeholder="Search with Name" />
            </Grid>

          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {users.map((row, index) => {
                      const {
                        id,
                        contestType,
                        imageLink,
                        name,
                        isActive,
                        description,
                        weekName,
                        price,
                        startDateTime,
                        isAgeNeeded,
                        minimumAge,
                        termsLink,
                        rulesLink,
                        createdAt,
                        endDateTime,
                        gameIds
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      const options = [
                        {
                          label: 'Contest Stats',
                          icon: 'raphael:piechart',
                          onClick: () => {
                            setSelectedContest(row);
                            setContestStats(!openContestStats);
                          },
                        },
                        {
                          label: 'View leaderBoard/Winners',
                          icon: 'eva:copy-outline',
                          onClick: () => {
                            setSelectedContest(row);
                            setOpenWinnerList(!openWinnerList);
                          },
                        },

                      ];
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="right">
                              <MoreMenu options={options} />
                          </TableCell>

                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar
                                alt={name}
                                src={imageLink}
                                onClick={() => showFullImage(imageLink)}
                                style={{ cursor: 'pointer' }}
                              />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {contestType === 1 ? 'Global' : contestType === 2 ? 'Location' : contestType === 3 ?  'Location + Global' : 'Link Based'}
                          </TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={isActive ? 'success' : 'error'}>
                              {isActive ? 'Active' : 'InActive'}
                            </Label>
                          </TableCell>
                          <TableCell align="left">{description}</TableCell>
                          <TableCell align="left">
                            {startDateTime !== null ? moment(startDateTime).format(DATE_FORMAT_TIME) : '-'}
                          </TableCell>
                          <TableCell align="left">
                            {endDateTime !== null ? moment(endDateTime).format(DATE_FORMAT_TIME) : '-'}
                          </TableCell>
                          <TableCell align="left" sx={{minWidth: '200px'}}>
                            {gameIds.length > 0 ? `${gameIds[0]?.AwayTeam?.teamName} VS ${gameIds[0]?.HomeTeam?.teamName}` : '-'}
                          </TableCell>
                          <TableCell align="left">{weekName}</TableCell>
                          <TableCell align="left">{price}</TableCell>
                          <TableCell align="left">{isAgeNeeded.toString()}</TableCell>
                          <TableCell align="left">{minimumAge}</TableCell>
                          <TableCell align="left">{termsLink}</TableCell>
                          <TableCell align="left">{rulesLink}</TableCell>
                          <TableCell align="left">{moment(createdAt).format(DATE_FORMAT)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>
      <Dialog key="winnerList" open={openWinnerList} fullWidth>
        <WinnerList closeModal={closeWinnerList} selectedContestId={selectedContest} fromPartner={true} />
      </Dialog>
      <Dialog key="contestStats" open={openContestStats} fullWidth>
        <ContestStats closeModal={closeContestStats} selectedContestId={selectedContest} />
      </Dialog>
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </Page>
  );
}
