import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Dialog,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Grid,
} from '@mui/material';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import { UserListToolbar } from '../../../sections/@dashboard/user';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';
import { listApi } from 'src/apis/admin';
import { APIs } from 'src/apis/';
import moment from 'moment';
import { DATE_FORMAT_TIME, DATE_FORMAT } from 'src/contants/contants';

/*TABLE HEADING */

const TABLE_HEAD = [
  { id: 'name', label: ' Name', alignRight: false },
  { id: 'dob', label: 'Date Of Birth', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
  { id: 'age', label: 'Age', alignRight: false },
  { id: 'tshirt', label: 'T-Shirt Size', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
];

//Styles for filter //
const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 56,
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

const ContestForm = () => {
  const [selected, setSelected] = useState([]);
  const [userFilterSelect, setUserFilterSelect] = useState();
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchFilter, setSearchFilter] = useState('');
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    listing();
  }, [rowsPerPage, searchFilter, page, userFilterSelect]);

  const listing = () => {
    setLoading(true);
    let data = {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      search: searchFilter,
    };

    if (userFilterSelect) {
      data.userId = userFilterSelect.value;
    }

    listApi(APIs.CONTEST_FORM_LISTING, { ...data })
      .then((res) => {
        setUserList(res?.data);
        setTotalCount(res?.count);
      })
      .finally(() => setLoading(false));
  };

  /*User Filter Api  */
  const promiseUserDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_USERS, { search: inputValue, sendOnlyActive: true }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={item?.name} src={item?.profileImg} />
            <Typography variant="subtitle2" noWrap>
              {item?.name}
            </Typography>
          </Stack>
        ),
        value: item?.id,
      }));
      resolve(filter);
    });
  };

  const loadUserData = debounce(promiseUserDataOptions, 300);

  /*HANLDER FOR PAGES CHANGING */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /*HANLDER FOR ROW PAGES */

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /*HANLDER FOR SEARCH BOX */

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  return (
    <Page title="Contests Forms">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Contests Forms
          </Typography>
        </Stack>

        <Card>
          <Grid container spacing={2} padding={1}>
            <Grid>
              <UserListToolbar 
              numSelected={selected.length} 
              filterName={search} 
              placeholder =  "Search by Name, Email, Address"
              onFilterName={handleSearch} />
            </Grid>
            <Grid xs={12} md={3.5} style={{ paddingTop: '20px' }}>
              <AsyncSelect
                isClearable
                cacheOptions
                defaultOptions
                placeholder="Filter by User"
                styles={customStyles}
                loadOptions={loadUserData}
                value={userFilterSelect}
                onChange={(data) => {
                  setUserFilterSelect(data);
                }}
              />
            </Grid>
          </Grid>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {TABLE_HEAD.map((headCell) => (
                          <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {userList.map((row, index) => {
                        const {
                          addressLine1,
                          addressLine2,
                          age,
                          city,
                          countryCode,
                          createdAt,
                          dob,
                          email,
                          firstName,
                          id,
                          lastName,
                          phoneNumber,
                          pinCode,
                          state,
                          tshirtSize,
                        } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                {/* <Avatar alt={name} src={imageLink} /> */}
                                <Typography variant="subtitle2" noWrap>
                                  {`${firstName} ${lastName}`}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                              {moment(dob).format(DATE_FORMAT)}
                            </TableCell>
                            <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                              {email}
                            </TableCell>
                            <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                              {`${countryCode} ${phoneNumber}`}
                            </TableCell>
                            <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                              {age}
                            </TableCell>  
                            <TableCell align="left">
                              {tshirtSize}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ whiteSpace: 'nowrap' }}
                            >{`${addressLine1} ${addressLine2} , ${city} , ${state} , ${pinCode}`}</TableCell>
                            <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                              {moment(createdAt).format(DATE_FORMAT_TIME)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {totalCount === 0 && (
                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                      <Typography gutterBottom>No Data Found</Typography>
                    </div>
                  )}
                </>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>
    </Page>
  );
};

export default ContestForm;
