import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  CircularProgress,
  Dialog,
} from '@mui/material';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import { UserListToolbar, UserMoreMenu } from '../../../sections/@dashboard/user';
import { listApi } from 'src/apis/admin';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { createDispatchHook, useSelector } from 'react-redux';
import { APIs } from '../../../apis';
import MoreMenu from '../../../components/table/MoreMenu';
import React from 'react';
import EditModal from '../../rewardrules/editModal';
import AssignReward from './assignReward';
import { DATE_FORMAT } from 'src/contants/contants';
import ContestHistory from './contestHistory';
import PartnerPoints from './partnerPoints';
import AssignPartnerPoints from './assignPartnerPoints';
import ImageModal from 'src/pages/openAI/questionBankList/imageModal';

const TABLE_HEAD = [
  { id: '' },
  { id: 'name', label: 'Username', alignRight: false },
  { id: 'score', label: 'Scores', alignRight: false },
  { id: 'tshirtSize', label: 'T-shirt Size', alignRight: false },
  { id: 'twitter', label: 'Twitter', alignRight: false },
  { id: 'instagram', label: 'Instagram', alignRight: false },
  { id: 'dob', label: 'DOB', alignRight: false },
  { id: 'lat', label: 'Latitude', alignRight: false },
  { id: 'long', label: 'longitude', alignRight: false },
  { id: 'walletAddress', label: 'WalletAddress', alignRight: false },
  { id: 'currentAddress', label: 'CurrentAddress', alignRight: false },
  { id: 'isCampusUser', label: 'Campus User', alignRight: false },
  { id: 'createdAt', label: 'Created at', alignRight: false },
  { id: 'active', label: 'Status', alignRight: false },
];

export default function User() {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editModalValue, setEditModalValue] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalRowData, setModalRowData] = useState();
  const [openPartnerModal, setOpenPartnerModal] = useState(false);
  const [openAssignPointsModal, setOpenAssignPointsModal] = useState(false);
  const [userId, setUserId] = useState();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);

  const user = useSelector((state) => state.auth);

  <MoreMenu
    options={[
      {
        label: 'Edit',
        icon: 'eva:edit-fill',
        onClick: () => navigate(`/contests-wallpapers/edit/${id}`),
      },
      {
        label: 'Clone',
        icon: 'eva:copy-outline',
        onClick: () => cloneContest(row),
      },

      {
        label: user?.isActive ? 'Inactive' : 'Active',
        icon: 'eva:edit-fill',
        onClick: () => changeStatusHandler(row),
      },
    ]}
  />;

  useEffect(() => {
    setLoading(true);
    listApi(APIs.LIST_USERS, { skip: page * rowsPerPage, limit: rowsPerPage, search: searchFilter })
      .then((res) => {
        setUsers(res?.data);
        setTotalCount(res?.count);
      })
      .finally(() => setLoading(false));
  }, [rowsPerPage, searchFilter, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  const closeModal = () => {
    setEditModalValue(!editModalValue);
  };

  const closeList = () => {
    setUserId('');
    setOpenModal(!openModal);
  };

  const closePartnerList = () => {
    setUserId('');
    setOpenPartnerModal(!openPartnerModal);
  };
  const closeAssignPoints = () => {
    setUserId('');
    setOpenAssignPointsModal(!openAssignPointsModal);
  };

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };
  const handleEditPermission =
    user && user.roles && user.roles.some((role) => role?.title === 'Users list' && role?.edit === true);

  const isSuperAdmin = user?.isSuperAdmin;

  console.log("renderrr")

  return (
    <Page title="Users">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={search} onFilterName={handleSearch} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          <TableSortLabel hideSortIcon active={false} direction={'asc'}>
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {users.map((row, index) => {
                      const {
                        id,
                        name,
                        emailId,
                        createdAt,
                        profileImg,
                        isCampusUser,
                        dob,
                        lat,
                        long,
                        walletAddress,
                        active,
                        score,
                        currentAddress,
                        tshirtSize,
                        twitter,
                        instagram,
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {' '}
                          <TableCell align="right">
                            {(handleEditPermission || isSuperAdmin) && (
                              <MoreMenu
                                options={[
                                  {
                                    label: 'Assign Reward',
                                    icon: 'eva:edit-fill',
                                    onClick: () => {
                                      setEditModalValue(!editModalValue);
                                      setModalRowData(row);
                                    },
                                  },
                                  {
                                    label: 'Contest History',
                                    icon: 'tabler:history',
                                    onClick: () => {
                                      setOpenModal(!openModal);
                                      setUserId({ id, name });
                                    },
                                  },
                                  {
                                    label: 'Partner Points Logs',
                                    icon: 'tabler:history',
                                    onClick: () => {
                                      setOpenPartnerModal(!openModal);
                                      setUserId({ id, name });
                                    },
                                  },
                                  {
                                    label: 'Assign Partner Points',
                                    icon: 'tabler:history',
                                    onClick: () => {
                                      setOpenAssignPointsModal(!openAssignPointsModal);
                                      setUserId({ id, name });
                                    },
                                  },
                                ]}
                              />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar
                                alt={name}
                                src={profileImg}
                                onClick={() => showFullImage(profileImg)}
                                style={{ cursor: 'pointer' }}
                              />
                              <Stack>
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                                <Typography variant="subtitle2" noWrap>
                                  {emailId}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{score}</TableCell>
                          <TableCell align="left">
                            <Typography sx={{ width: '100px' }} noWrap>
                              {tshirtSize || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{twitter || '-'}</TableCell>
                          <TableCell align="left">{instagram || '-'}</TableCell>
                          <TableCell align="left">{dob || '-'}</TableCell>
                          <TableCell align="left">{lat || '-'}</TableCell>
                          <TableCell align="left">{long || '-'}</TableCell>
                          <TableCell align="left">{walletAddress || '-'}</TableCell>
                          <TableCell align="left">{currentAddress}</TableCell>
                          <TableCell align="left">{isCampusUser ? 'Yes' : 'No'}</TableCell>
                          <TableCell align="left">{moment(createdAt).format(DATE_FORMAT)}</TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={active !== 1 ? 'error' : 'success'}>
                              {active !== 1 ? 'InActive' : 'Active'}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>
      <Dialog open={editModalValue} fullWidth>
        <AssignReward closeModal={closeModal} data={modalRowData} />
      </Dialog>
      <Dialog open={openModal} fullWidth>
        <ContestHistory closeModal={closeList} userId={userId} />
      </Dialog>
      <Dialog open={openPartnerModal}>
        <PartnerPoints closeModal={closePartnerList} userId={userId} />
      </Dialog>
      <Dialog open={openAssignPointsModal}>
        <AssignPartnerPoints closeModal={closeAssignPoints} userId={userId} />
      </Dialog>
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </Page>
  );
}
