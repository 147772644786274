import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Switch,
  Grid,
  Button,
} from '@mui/material';
import { addEditApi, listApi } from 'src/apis/admin';
import debounce from 'lodash/debounce';
import moment from 'moment';
import 'react-phone-input-2/lib/material.css';
import MoreMenu from 'src/components/table/MoreMenu';
import { GAMES_TABLE_HEAD } from 'src/contants/contants';
import QuiModal from './quizModal';
import { DATE_FORMAT_TIME } from 'src/contants/contants';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import GameWeekModal from './gameWeekModal';
import QuizList from './quizList';
import Label from 'src/components/Label';
import Iconify from 'src/components/Iconify';
import { APIs } from 'src/apis';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import Scrollbar from 'src/components/Scrollbar';
import Page from 'src/components/Page';
import GameModal from './gameModal';
import Select from 'react-select';
import { useSelector } from 'react-redux';

const MODAL_CONSTANT = {
  ADD_EDIT: 'addEdit',
  SPECIAL_QUIZ: 'speicalQuiz',
  GAME_WEEK: 'gameWeek',
  QUIZ_LIST: 'quizList',
};

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 55,
      border: '',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

export default function GamesPage() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedGame, setSelectedGame] = useState({});
  const [gameOfWeek, setGameOfWeek] = useState(false);
  const [leagueData, setLeagueData] = useState();
  const [leagues, setLeagues] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [activeModal, setActiveModal] = useState('');

  const user = useSelector((state) => state.auth);

  const handleEditPermission =
    user && user.roles && user.roles.some((role) => role?.title === 'Games List' && role?.edit === true);

  const isSuperAdmin = user?.isSuperAdmin;
  useEffect(() => {
    listApi(APIs.LIST_LEAGUES).then((result) => {
      setLeagues(result);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    let ApiPayload = {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      search: searchFilter,
      fromAdmin: true,
      fromAdminListing: true,
    };
    if (gameOfWeek) {
      ApiPayload.gameOfWeek = true;
    }
    if (leagueData) {
      ApiPayload.leagueId = leagueData;
    }
    listApi(APIs.LIST_GAMES, ApiPayload)
      .then((res) => {
        setUsers(res?.games);
        setTotalCount(res?.count);
      })
      .finally(() => setLoading(false));
  }, [refresh, rowsPerPage, searchFilter, page, gameOfWeek, leagueData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  const GameQuizConfirmBox = (row) => {
    const data = {
      gameId: row?.id,
    };
    confirmAlert({
      title: 'Confirm for Generate Game Quiz',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            addEditApi(APIs.GENERATE_GAME_QUIZ, data).then((result) => {
              toast.success('Generate Game Quiz Successfully');
              setRefresh((prev) => !prev);
            });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const refreshGames = () =>{
    confirmAlert({
      title: 'Refresh Games Data?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            addEditApi(APIs.REFRESH_GAMES, {}).then((result) => {
              toast.success('It will take 2 mins to refresh the games Successfully');
              setRefresh((prev) => !prev);
            });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  }


  ////////CLOSE QUIZ MODAL///////
  const onCloseModal = () => {
    setSelectedGame({});
    setOpenModal(false);
    setActiveModal('');
    setRefresh(!refresh);
  };

  const getActiveModal = (identifier, onCloseModal, selectedGame) => {
    if (identifier === MODAL_CONSTANT.QUIZ_LIST) {
      return <QuizList closeModal={onCloseModal} selectedGame={selectedGame} />;
    }
    if (identifier === MODAL_CONSTANT.SPECIAL_QUIZ) {
      return <QuiModal closeModal={onCloseModal} selectedGame={selectedGame} />;
    }
    if (identifier === MODAL_CONSTANT.GAME_WEEK) {
      return <GameWeekModal closeModal={onCloseModal} selectedGame={selectedGame} />;
    }
    if (identifier === MODAL_CONSTANT.ADD_EDIT) {
      return <GameModal closeModal={onCloseModal} selectedGame={selectedGame} />;
    }
  };

  return (
    <Page title="Games">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Games
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setOpenModal(true);
              setActiveModal(MODAL_CONSTANT.ADD_EDIT);
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Game
          </Button>
        </Stack>

        <Card style={{ minHeight: '400px' }}>
          <Grid container spacing={2} columns={16} alignItems="center">
            <Grid item xs={5}>
              <UserListToolbar filterName={search} onFilterName={handleSearch} />
            </Grid>
            <Grid item xs={5} md={3}>
              <p>Filter By:</p>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(date) => {
                      setGameOfWeek(date?.target?.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Game of Week"
              />
            </Grid>
            <Grid item xs={5} md={4}>
              <Select
                cacheOptions
                defaultOptions
                placeholder="Select League"
                styles={customStyles}
                options={leagues}
                name="leagueId"
                getOptionLabel={(v) => v?.leagueName}
                getOptionValue={(v) => v?.id}
                onChange={(data) => setLeagueData(data?.id)}
                isClearable
              />
            </Grid>
            <Grid item xs={5} md={4}>
              <Button
                variant="contained"
                onClick={() => {
                  refreshGames()
                }}
                startIcon={<Iconify icon="material-symbols:refresh" />}
              >
                Refresh Games
              </Button>
            </Grid>
          </Grid>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                users?.length > 0 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        {GAMES_TABLE_HEAD.map((headCell) => (
                          <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                            <TableSortLabel style={{ width: '100px' }} hideSortIcon active={false} direction={'asc'}>
                              {headCell.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((row, index) => {
                        const {
                          id,
                          leagueId,
                          HomeTeam,
                          AwayTeam,
                          awayPoints,
                          homePoints,
                          status,
                          duration,
                          League,
                          quarter,
                          quizActive,
                          userPlayCount,
                          startTime,
                          sportId,
                          gameFeedId,
                          broadcast,
                          specialQuiz,
                          specialQuizType,
                          specialQuizData,
                          gameOfWeek,
                          emailSubject,
                          emailBodyContent,
                          emailImage,
                          clock,
                          gameQuarterDuration,
                          maxQuarterToPlay,
                        } = row;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox">
                            <TableCell align="center">
                              {(handleEditPermission || isSuperAdmin) && (
                                <MoreMenu
                                  options={[
                                    {
                                      label: 'Edit',
                                      icon: 'eva:edit-fill',
                                      onClick: () => {
                                        setSelectedGame({
                                          id,
                                          startTime,
                                          League,
                                          HomeTeam,
                                          AwayTeam,
                                          status,
                                          gameQuarterDuration,
                                          clock,
                                          maxQuarterToPlay,
                                        });
                                        setActiveModal(MODAL_CONSTANT.ADD_EDIT);
                                        setOpenModal(true);
                                      },
                                    },
                                    {
                                      label: 'Clone Game',
                                      icon: 'eva:copy-outline',
                                      onClick: () => {
                                        setSelectedGame({
                                          startTime,
                                          League,
                                          HomeTeam,
                                          AwayTeam,
                                          status,
                                          gameQuarterDuration,
                                          clock,
                                          maxQuarterToPlay,
                                        });
                                        setActiveModal(MODAL_CONSTANT.ADD_EDIT);
                                        setOpenModal(true);
                                      },
                                    },
                                    {
                                      label: 'Special Quiz',
                                      icon: 'material-symbols:quiz-outline',
                                      onClick: () => {
                                        setSelectedGame({
                                          id: id,
                                          gameName: `${AwayTeam?.teamName} VS ${HomeTeam?.teamName}`,
                                          specialQuiz,
                                          specialQuizType,
                                          specialQuizData,
                                        });
                                        setActiveModal(MODAL_CONSTANT.SPECIAL_QUIZ);
                                        setOpenModal(true);
                                      },
                                    },
                                    {
                                      label: 'Generate Game Quiz',
                                      icon: 'ph:game-controller-fill',
                                      onClick: () => GameQuizConfirmBox(row),
                                    },
                                    {
                                      label: 'Game Of Week',
                                      icon: 'fluent:games-16-filled',
                                      onClick: () => {
                                        setSelectedGame({
                                          id: id,
                                          gameName: `${AwayTeam?.teamName} VS ${HomeTeam?.teamName}`,
                                          gameOfWeek,
                                          emailSubject,
                                          emailBodyContent,
                                          emailImage,
                                        });
                                        setActiveModal(MODAL_CONSTANT.GAME_WEEK);
                                        setOpenModal(true);
                                      },
                                    },
                                    {
                                      label: 'Quiz List',
                                      icon: 'ic:round-list',
                                      onClick: () => {
                                        setSelectedGame({
                                          gameId: id,
                                          startTime: moment(startTime).format(DATE_FORMAT_TIME),
                                          gameName: `${AwayTeam?.teamName} VS ${HomeTeam?.teamName}`,
                                        });
                                        setActiveModal(MODAL_CONSTANT.QUIZ_LIST);
                                        setOpenModal(true);
                                      },
                                    },
                                  ]}
                                />
                              )}
                            </TableCell>
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {AwayTeam?.teamName} VS {HomeTeam?.teamName}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">{League?.leagueCode}</TableCell>
                            <TableCell align="left">{moment(startTime).format(DATE_FORMAT_TIME)}</TableCell>
                            <TableCell align="left">
                              <Label variant="ghost" color={quizActive > 0 ? 'success' : 'error'}>
                                {quizActive > 0 ? 'Active' : 'InActive'}
                              </Label>
                            </TableCell>
                            <TableCell align="center">{gameOfWeek ? 'True' : 'False'} </TableCell>
                            <TableCell align="left">{awayPoints}</TableCell>
                            <TableCell align="left">{homePoints}</TableCell>
                            <TableCell align="left">{status}</TableCell>
                            <TableCell align="left">{quarter}</TableCell>
                            <TableCell align="left">{duration}</TableCell>
                            <TableCell align="left">{userPlayCount}</TableCell>
                            <TableCell align="left">{sportId}</TableCell>
                            <TableCell align="left">{gameFeedId}</TableCell>
                            <TableCell align="left">{broadcast}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )
              )}
              {users?.length === 0 && loading === false && (
                <div style={{ display: 'flex', justifyContent: 'center', margin: '100px 0px' }}>No Data Found</div>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>
      <Dialog open={openModal} fullWidth>
        {getActiveModal(activeModal, onCloseModal, selectedGame)}
      </Dialog>
    </Page>
  );
}
