import { combineReducers } from 'redux';
import auth from './auth';
import navConfigReducer from './navConfigReducer';

const rootReducer = combineReducers({
  auth,
  navConfig: navConfigReducer,
});

export default rootReducer;
