import { Fragment, useEffect, useState } from 'react';
import Page from 'src/components/Page';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import AsyncSelect from 'react-select/async';
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Box,
  Grid,
  Button,
  IconButton,
  colors,
  Avatar,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import Iconify from 'src/components/Iconify';
import { addEditContestWallpaperSchema } from 'src/utils/validationSchemas';
import { addEditApi, listApi } from 'src/apis/admin';
import { APIs } from 'src/apis';
import moment from 'moment';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'src/router/routes';
import BackButton from 'src/components/backButton';
import { DATE_FORMAT_TIME } from 'src/contants/contants';

const tempWallpaper = { mainWallpaper: '', previewWallpaper: '', price: '' };

const initialValues = {
  isNft: false,
  title: '',
  description: '',
  shortDescription: '',
  wallpapers: [{ mainWallpaper: '', previewWallpaper: '', price: '' }],
  contestId: '',
};

export default function AddEditContestWallpapers({ isEdit }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const cloneId = searchParams.get('clone-id');
  const [loading, setLoading] = useState(false);
  const [contestData, setContestData] = useState();
  const [imageLoading, setImageLoading] = useState(false);


  useEffect(() => {
    if (id || cloneId) {
      setLoading(true);
      listApi(APIs.CONTEST_WALLPAPER_LISTING, { id: id || cloneId })
        .then((result) => {
          const data = cloneDeep(result?.data);

          data.contestId = {
            label: (
              <div style={{ display: 'flex' }}>
                <img src={data?.Contest?.imageLink} height="30px" width="30px" />
                <div style={{ paddingLeft: '10px' }}>
                  {data?.Contest?.name} | {moment(data?.Contest?.startDateTime).format(DATE_FORMAT_TIME)}
                </div>
              </div>
            ),
            value: data?.Contest?.id,
          };
          setContestData(data);
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  /*Submit Handler */

  const handleFormSubmit = (data) => {
    if (cloneId) {
      delete data.id;
    }
    data.contestId = data?.contestId?.value;
    addEditApi(APIs.ADD_EDIT_CONTEST_WALLPAPER, { ...data }).then(() => navigate(ROUTES.CONTESTS_WALLPAPERS));
  };

  /* Initialization Formik */

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: contestData || initialValues,
    validationSchema: addEditContestWallpaperSchema,
    onSubmit: handleFormSubmit,
  });
  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;
  /*Promise for Contest Option hanlder */

  const contestOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_CONTESTS, { search: inputValue }).then((result) => {
      const filter = result?.data.map((item) => ({
        label: (
          <div style={{ display: 'flex' }}>
            <img src={item?.imageLink} height="30px" width="30px" />
            <div style={{ paddingLeft: '10px' }}>
              {item.name} | {moment(item.startDateTime).format(DATE_FORMAT_TIME)}
            </div>
          </div>
        ),
        value: item?.id,
      }));

      resolve(filter);
    });
  };

  const loadContest = debounce(contestOptions, 300);

  /*Image Upload Handler */

  const uploadImage = (e, type, index) => {
    setImageLoading(true);
    const file = e?.target?.files?.[0];
    const data = new FormData();
    data.append('file', file);

    addEditApi(APIs.UPLOAD_IMAGE, data)
      .then((result) => {
        if (type === 1) {
          setFieldValue(`wallpapers.${index}.mainWallpaper`, result?.profileImg);
        } else {
          setFieldValue(`wallpapers.${index}.previewWallpaper`, result?.profileImg);
        }
      })
      .finally(() => setImageLoading(false));
  };

  /*Styles */

  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 55,
        border: '',
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };

  return (
    <Page title="ContestWallpaper">
      <Container>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                {isEdit ? 'Edit Contest Wallpaper' : 'Add Contest Wallpaper'}
              </Typography>
              <div>
                <BackButton onClick={() => navigate(ROUTES.CONTESTS_WALLPAPERS)} />
                <Button variant="contained" type="submit" startIcon={<Iconify icon="eva:save-outline" />}>
                  Save Contest Wallpaper
                </Button>
              </div>
            </Stack>

            {loading ? (
              <Card style={{ padding: '20px', display: 'flex', justifyContent: 'center', minHeight: '63vh' }}>
                <CircularProgress />
              </Card>
            ) : (
              <Card>
                <Box sx={{ flexGrow: 1, padding: 4 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        placeholder="Contest"
                        styles={customStyles}
                        loadOptions={loadContest}
                        value={values?.contestId}
                        onChange={(data) => {
                          setFieldValue('contestId', data);
                        }}
                      />
                      {Boolean(touched.contestId && errors.contestId) && (
                        <Typography variant="caption" display="block" gutterBottom color={'red'}>
                          {errors.contestId}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Title"
                        {...getFieldProps('title')}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Short Description"
                        {...getFieldProps('shortDescription')}
                        error={Boolean(touched.shortDescription && errors.shortDescription)}
                        helperText={touched.shortDescription && errors.shortDescription}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Description"
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={<Switch {...getFieldProps('isNft')} checked={values?.isNft} />}
                        label="is NFT ?"
                        sx={{ flexGrow: 1, m: 0 }}
                      />
                    </Grid>

                    <Grid container padding={2} display="flex" justifyContent="center">
                      {imageLoading && <CircularProgress />}
                    </Grid>

                    <Grid container padding={2} alignItems="center">
                      <FieldArray
                        name="wallpapers"
                        render={(arrayHelpers) => (
                          <>
                            {values?.wallpapers?.map((item, index) => (
                              <Fragment key={index}>
                                <Grid container marginTop="20px" alignItems="center">
                                  <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle2" gutterBottom>
                                      Main Wallpaper
                                    </Typography>

                                    <Avatar
                                      alt={'N'}
                                      src={item?.mainWallpaper}
                                      sx={{ width: 100, height: 100, marginBottom: '10px' }}
                                    />

                                    <input
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) => uploadImage(e, 1, index)}
                                      disabled={imageLoading}
                                    />

                                    {touched.wallpapers?.[index]?.mainWallpaper &&
                                      errors?.wallpapers?.[index]?.mainWallpaper && (
                                        <div className="field-error">{errors?.wallpapers?.[index]?.mainWallpaper}</div>
                                      )}
                                  </Grid>

                                  <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle2" gutterBottom>
                                      Preview Wallpaper
                                    </Typography>

                                    <Avatar
                                      alt={'N'}
                                      src={item?.previewWallpaper}
                                      sx={{ width: 100, height: 100, marginBottom: '10px' }}
                                    />

                                    <input
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) => uploadImage(e, 2, index)}
                                      disabled={imageLoading}
                                    />

                                    {touched.wallpapers?.[index]?.previewWallpaper &&
                                      errors?.wallpapers?.[index]?.previewWallpaper && (
                                        <div className="field-error">
                                          {errors?.wallpapers?.[index]?.previewWallpaper}
                                        </div>
                                      )}
                                  </Grid>

                                  <Grid item xs={12} md={1}>
                                    <TextField
                                      fullWidth
                                      label="Price"
                                      {...getFieldProps(`wallpapers.${index}.price`)}
                                    />

                                    {touched.wallpapers?.[index]?.price && errors?.wallpapers?.[index]?.price && (
                                      <div className="field-error">{errors?.wallpapers?.[index]?.price}</div>
                                    )}
                                  </Grid>
                                  <Grid xs={12} md={3} display="flex" justifyContent="end">
                                    {values?.wallpapers?.length !== 1 && (
                                      <IconButton
                                        size="large"
                                        sx={{ ml: 2, color: colors.red[500] }}
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                      >
                                        <Iconify icon="eva:minus-square-fill" />
                                      </IconButton>
                                    )}

                                    <IconButton
                                      size="large"
                                      sx={{ ml: 2, color: 'primary.main' }}
                                      onClick={() => {
                                        arrayHelpers.insert(index + 1, tempWallpaper);
                                      }}
                                    >
                                      <Iconify icon="eva:plus-square-fill" />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Fragment>
                            ))}
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            )}
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
