import React, { useState } from 'react';
import { Typography, Grid, Button, IconButton, colors, Avatar } from '@mui/material';
import Iconify from './Iconify';
import { IMAGE_MAX_SIZE } from 'src/contants/contants';
import ImageModal from 'src/pages/openAI/questionBankList/imageModal';

const UploadImage = ({
  warning,
  isWarning,
  title,
  fieldName,
  image,
  fileSizeExceeded,
  onChange,
  uploadImage,
  removeImage,
  stateImage,
  touched,
  errors,
  loading,
  isResetButton,
  removeImageHandler,
}) => {
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };
  return (
    <>
      {title}
      {isWarning ? (
        <p style={{ color: 'red', fontSize: '12px' }}>{warning}</p>
      ) : (
        <p style={{ visibility: 'hidden' }}>hidden</p>
      )}
      <Avatar
        alt={'N'}
        src={image}
        sx={{ width: 100, height: 100 }}
        onClick={() => showFullImage(image)}
        style={{ cursor: 'pointer' }}
      />

      <input type="file" accept="image/*" onChange={onChange} />
      <div>
        {fileSizeExceeded && (
          <p style={{ color: 'red', fontSize: '14px' }}>File size exceeded the limit of {IMAGE_MAX_SIZE / 100000} MB</p>
        )}
      </div>

      {stateImage || isResetButton ? (
        <>
          {stateImage && image && (
            <Button
              onClick={uploadImage}
              size="small"
              variant="outlined"
              startIcon={<Iconify icon="eva:save-outline" />}
            >
              {loading ? 'uploading' : 'upload Image'}
            </Button>
          )}

          {image && isResetButton ? (
            <IconButton size="large" sx={{ ml: 2, color: colors.red[500] }} onClick={removeImageHandler}>
              <Iconify icon="eva:minus-square-fill" />
            </IconButton>
          ) : (
            isResetButton !== true && (
              <IconButton size="large" sx={{ ml: 2, color: colors.red[500] }} onClick={removeImage}>
                <Iconify icon="eva:minus-square-fill" />
              </IconButton>
            )
          )}
        </>
      ) : (
        ''
      )}

      {Boolean(touched?.[fieldName] && errors?.[fieldName]) && (
        <Typography variant="caption" display="block" gutterBottom color={'red'}>
          {errors?.[fieldName]}
        </Typography>
      )}
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </>
  );
};

export default UploadImage;
