import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
} from '@mui/material';
import Page from 'src/components/Page';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import Iconify from 'src/components/Iconify';
import { UserListToolbar, UserMoreMenu } from 'src/sections/@dashboard/user';
import { addEditApi, listApi } from 'src/apis/admin';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { APIs } from 'src/apis';
import 'react-phone-input-2/lib/material.css';
import AddEditModal from './addEditModal';
import { DATE_FORMAT } from 'src/contants/contants';
import { confirmAlert } from 'react-confirm-alert';

const TABLE_HEAD = [
  { id: '', label: '', alignRight: false },
  { id: 'name', label: 'Partner Reward Name', alignRight: false },
  { id: 'partnerName', label: 'PartnerName', alignRight: false },
  { id: 'points', label: 'Points', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'isActive', label: 'Active', alignRight: false },
  { id: 'createdAt', label: 'Created at', alignRight: false },
];

export default function PartnersRewards() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [openModal, setModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');

  useEffect(() => {
    listApi(APIs.LIST_PARTNERS_REWARDS, { skip: page * rowsPerPage, limit: rowsPerPage, search: searchFilter }).then(
      (res) => {
        setUsers(res?.data);
        setTotalCount(res?.count);
      }
    );
  }, [refresh, rowsPerPage, searchFilter, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  const handleClose = (_, reason) => {
    setModalOpen(false);
    setSelectedRow('');
    // if (reason === 'backdropClick') {
    //   setModalOpen(false);
    // }
  };
  const statusPartnersRewards = (row) => {
    const data = {
      id: row.id,
      type: 7,
      active: !row.isActive,
    };
    confirmAlert({
      title: 'Confirm for Status Change',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            addEditApi(APIs.ADD_EDIT_BLOCK, data).then((result) => {
              setRefresh((v) => !v);
            });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };
  return (
    <Page title="Partners">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Partners Rewards
          </Typography>
          <Button variant="contained" onClick={() => setModalOpen(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Partner Reward
          </Button>
        </Stack>

        <Card>
          <UserListToolbar filterName={search} onFilterName={handleSearch} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                        <TableSortLabel hideSortIcon active={false} direction={'asc'}>
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {users.map((row, index) => {
                    const { id, name, description, createdAt, points, imageLink, isActive, partnerData } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell>
                          <UserMoreMenu
                            options={[
                              {
                                label: 'Edit',
                                icon: 'eva:edit-fill',

                                onClick: () => {
                                  setSelectedRow(row);
                                  setModalOpen(true);
                                },
                              },
                              ,
                              {
                                label: isActive ? 'Inactive Partner Reward' : 'Active Partner Reward',
                                icon: 'eva:edit-fill',
                                onClick: () => statusPartnersRewards(row),
                              },
                            ]}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={imageLink} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={partnerData?.name} src={partnerData?.profileImage} />
                            <Typography variant="subtitle2" noWrap>
                              {partnerData?.name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{points}</TableCell>
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle5" noWrap>
                              {description}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={isActive ? 'success' : 'error'}>
                            {isActive ? 'Active' : 'InActive'}
                          </Label>
                        </TableCell>
                        <TableCell align="left">{moment(createdAt).format(DATE_FORMAT)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>

      <AddEditModal
        setSelectedRow={setSelectedRow}
        selectedRow={selectedRow}
        openModal={openModal}
        handleClose={handleClose}
        setModalOpen={setModalOpen}
        setRefresh={setRefresh}
      />
    </Page>
  );
}
