import React, { useState } from 'react';
import { APIs } from 'src/apis';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { generateDeepLinkSchema } from 'src/utils/validationSchemas';
import { addEditApi } from 'src/apis/admin';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';


const GenerateDeepLinkModal = ({ closeModal, partnerId }) => {
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState();

  const initialValues = {
    alias: partnerId?.type === 2 ? `support_${(partnerId.name.split(' ')[0]).toLowerCase()}` : partnerId?.type === 3 ? `${(partnerId.name.split(' ')[0]).toLowerCase()}_profile` : '',
  };

  const handleFormSubmit = (data, { resetForm }) => {
    setLoading(true);
    data.partnerId = partnerId?.id;

      addEditApi(APIs.GENERATE_DEEP_LINK, {
        ...data, type: partnerId?.type
      })
      .then((res) => {
        setLink(res?.deepLinkUrl);
        toast.success('Deep Link Generated'), resetForm();
      })
      .catch((err) => toast.error(err?.response?.data?.message))
      .finally(setLoading(false));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: generateDeepLinkSchema,
    onSubmit: handleFormSubmit,
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <DialogTitle>Generate {partnerId?.type === 1 ? 'Deep' : partnerId?.type === 3 ? 'Profile'  : 'Wallpaper'} Link - {partnerId?.name}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Alias"
            {...getFieldProps('alias')}
            error={Boolean(touched.alias && errors.alias)}
            helperText={touched.alias && errors.alias}
          />
          {link ? (
            <>
              <Typography>Deep Link</Typography>
              <a href={link}>{link}</a>
            </>
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closeModal();
              formik.resetForm({
                values: initialValues,
              });
            }}
          >
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={loading}>
            Save
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
};

export default GenerateDeepLinkModal;
