import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Dialog,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Grid,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import { listApi, addEditApi } from 'src/apis/admin';
import { APIs } from 'src/apis';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';
import moment from 'moment';
import DialogModal from './dialoagBox';
import Iconify from 'src/components/Iconify';
import MoreMenu from 'src/components/table/MoreMenu';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import { confirmAlert } from 'react-confirm-alert';
import { ROUTES } from 'src/router/routes';
import Label from 'src/components/Label';
import { useSelector } from 'react-redux';
import { DATE_FORMAT_TIME } from 'src/contants/contants';
import ImageModal from 'src/pages/openAI/questionBankList/imageModal';

/*TABLE HEADING */

const TABLE_HEAD = [
  { id: '' },
  { id: 'name', label: 'Contest Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'shortDescription', label: 'Short Description ', alignRight: false },
  { id: 'wallpapers', label: 'Wallpapers', alignRight: false },
];

const cursorPointer = { cursor: 'pointer' };

//Styles for filter //

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 56,
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 2,
    };
  },
};

const ContestWallpaperPage = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [userFilterSelect, setUserFilterSelect] = useState();
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchFilter, setSearchFilter] = useState('');
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);

  const user = useSelector((state) => state.auth);

  const handleEditPermission =
    user && user.roles && user.roles.some((role) => role?.title === 'Contest Wallpapers' && role?.edit === true);

  const isSuperAdmin = user?.isSuperAdmin;

  useEffect(() => {
    listing();
  }, [rowsPerPage, searchFilter, page, userFilterSelect]);

  /*Api List Function */

  const listing = () => {
    setLoading(true);
    let data = {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      search: searchFilter,
    };

    if (userFilterSelect) {
      data.contestId = userFilterSelect.value;
    }

    listApi(APIs.CONTEST_WALLPAPER_LISTING, { ...data })
      .then((res) => {
        setUserList(res?.data);
        setTotalCount(res?.count);
      })
      .finally(() => setLoading(false));
  };

  /*HANLDER FOR PAGES CHANGING */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /*HANLDER FOR ROW PAGES */

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /*HANLDER FOR SEARCH BOX */

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  /*User Filter Api  */

  const promiseUserDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_CONTESTS, { search: inputValue, sendOnlyActive: true }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={item?.name} src={item?.imageLink} />
            <Typography variant="subtitle2" noWrap>
              {item?.name}
            </Typography>
          </Stack>
        ),
        value: item?.id,
      }));
      resolve(filter);
    });
  };

  const loadUserData = debounce(promiseUserDataOptions, 300);

  /*Handler when click on row */

  const handlerRowClick = (e, row) => {
    setOpenDialog(true);
    setSelectedRow(row);
  };

  /*Handler for closing modal */

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedRow('');
  };

  /* Active and Unactive Handler  */

  const changeStatusHandler = (row) => {
    const data = {
      id: row.id,
      type: 5,
      active: !row.isActive,
    };

    confirmAlert({
      title: 'Confirm for Status Change',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            addEditApi(APIs.ADD_EDIT_BLOCK, data).then((result) => {
              listing();
            });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  /*Clone Handler */

  const cloneContest = (data) => {
    confirmAlert({
      title: 'Confirm to clone',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            navigate(`/contests-wallpapers/add?clone-id=${data?.id}`);
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

//function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };

  return (
    <>
      <Page title="Contests Forms">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Contest Wallpaper
            </Typography>
            <Button
              variant="contained"
              component={RouterLink}
              to={`${ROUTES.CONTESTS_WALLPAPERS}/${ROUTES.ADD}`}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Contest Wallpaper
            </Button>
          </Stack>

          <Card>
            <Grid container spacing={2} padding={1}>
              <Grid>
                <UserListToolbar numSelected={selected.length} filterName={search} onFilterName={handleSearch} />
              </Grid>
              <Grid xs={12} md={3.5} style={{ paddingTop: '20px' }}>
                <AsyncSelect
                  isClearable
                  cacheOptions
                  defaultOptions
                  placeholder="Filter by Contest"
                  styles={customStyles}
                  loadOptions={loadUserData}
                  value={userFilterSelect}
                  onChange={(data) => {
                    setUserFilterSelect(data);
                  }}
                />
              </Grid>
            </Grid>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                {loading ? (
                  <Stack alignItems="center">
                    <CircularProgress />
                  </Stack>
                ) : (
                  <>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {TABLE_HEAD.map((headCell) => (
                            <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                              {headCell.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {userList.map((row, index) => {
                          const { Contest, title, description, shortDescription, id, isActive } = row;
                          const isItemSelected = selected.indexOf(name) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell align="right">
                                {(handleEditPermission || isSuperAdmin) && (
                                  <MoreMenu
                                    options={[
                                      {
                                        label: 'Edit',
                                        icon: 'eva:edit-fill',
                                        onClick: () => navigate(`/contests-wallpapers/edit/${id}`),
                                      },
                                      {
                                        label: 'Clone',
                                        icon: 'eva:copy-outline',
                                        onClick: () => cloneContest(row),
                                      },

                                      {
                                        label: isActive ? 'Inactive' : 'Active',
                                        icon: 'eva:edit-fill',
                                        onClick: () => changeStatusHandler(row),
                                      },
                                    ]}
                                  />
                                )}
                              </TableCell>
                              <TableCell align="left">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Avatar alt={Contest?.name} src={Contest?.imageLink}  onClick={() => showFullImage(Contest?.imageLink)} style={cursorPointer} />  
                                  <div>
                                    <p>
                                      <Typography variant="subtitle2" noWrap>
                                        {Contest?.name}
                                      </Typography>
                                    </p>
                                    <p>{moment(Contest?.startDateTime).format(DATE_FORMAT_TIME)}</p>
                                  </div>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                <Label variant="ghost" color={isActive ? 'success' : 'error'}>
                                  {isActive ? 'Active' : 'InActive'}
                                </Label>
                              </TableCell>
                              <TableCell align="left">{title}</TableCell>
                              <TableCell align="left">{description}</TableCell>
                              <TableCell align="left">{shortDescription}</TableCell>
                              <TableCell align="center">
                                <WallpaperIcon style={cursorPointer} onClick={(e) => handlerRowClick(e, row)} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                    {totalCount === 0 && (
                      <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                        <Typography gutterBottom>No Data Found</Typography>
                      </div>
                    )}
                  </>
                )}
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 15, 25]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage=""
              showFirstButton
              showLastButton
            />
          </Card>
        </Container>
      </Page>
      <DialogModal openDialog={openDialog} handleClose={handleClose} selectedRow={selectedRow} />
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </>
  );
};

export default ContestWallpaperPage;
