import React, { useState } from 'react';
import { Button, Dialog, Stack } from '@mui/material';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';

const DialogModal = ({ openDialog, handleClose, selectedRow }) => {
  return (
    <Dialog open={openDialog}  disableEscapeKeyDown sx={{ m: 0, p: 2,}} >
        <Stack direction="row" justifyContent="center" spacing={2} paddingTop="10px" paddingBottom="10px">
        <h2>Wallpaper Image</h2>
      </Stack>
      <img src={selectedRow?.wallpaperUrl} height={500} width={350} />
      <Stack direction="row" justifyContent="end" spacing={2} padding="10px">
        <Button onClick={handleClose}>Cancel</Button>
      </Stack>
    </Dialog>
  );
};
export default DialogModal;
