import React from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Button, DialogActions, DialogContent, TextField, DialogTitle } from '@mui/material';
import { addEditFAQsSchema } from 'src/utils/validationSchemas';
import { LoadingButton } from '@mui/lab';

const FORM_FILED = [
  { label: 'Question', identifier: 'question' },
  { label: 'Answer', identifier: 'answer', isMutiline: true },
];

const initialValues = { question: '', answer: '' };

const AddEditModal = ({ selectedRow, handleFormSubmit, handleClose, loading }) => {
  const formik = useFormik({
    initialValues: selectedRow !== '' ? selectedRow : initialValues,
    validationSchema: addEditFAQsSchema,
    onSubmit: handleFormSubmit,
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <DialogTitle>{selectedRow !== '' ? 'Edit' : 'Add'} FAQ</DialogTitle>
        <DialogContent>
          {FORM_FILED.map((field, i) => {
            return (
              <div>
                <TextField
                  fullWidth
                  margin="normal"
                  label={field.label}
                  multiline={field.isMutiline}
                  rows={4}
                  {...getFieldProps(field.identifier)}
                  error={Boolean(touched?.[field.identifier] && errors?.[field.identifier])}
                  helperText={touched?.[field.identifier] && errors?.[field.identifier]}
                />
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={loading}>
            {selectedRow !== '' ? 'Update' : 'Save'} FAQ
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
};

export default AddEditModal;
