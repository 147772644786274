export const TWITTER_TYPE_OPTIONS = [
    { label: 'NA', value: 'NA' },
    { label: 'FAVORITES_ALL', value: 'FAVORITES_ALL' },
    { label: 'FAVORITES_SINGLE', value: 'FAVORITES_SINGLE' },
    { label: 'VIBE', value: 'VIBE' },
    { label: 'CHAT_MESSAGE', value: 'CHAT_MESSAGE' },
  ];
  
  export const CHAT_TYPE_OPTIONS = [
    { label: 'NA', value: 'NA' },
    { label: 'USER_RESPONSE', value: 'USER_RESPONSE' },
  ];