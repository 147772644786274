import React, { Fragment, useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import './index.css';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  colors,
  Grid,
  CircularProgress,
  Avatar,
  Typography,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { addEditPartnerRewardSchema } from 'src/utils/validationSchemas';
import { APIs } from 'src/apis';
import { addEditApi, listApi } from 'src/apis/admin';
import { IconButton } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import AsyncSelect from 'react-select/async';

const initialValues = {
  name: '',
  description: '',
  points: '',
  imageLink: '',
  partnerId: '',
};

const AddEditModal = ({ openModal, handleClose, setModalOpen, setRefresh, setSelectedRow, selectedRow }) => {
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [image, setImage] = useState();
  const [row, setRow] = useState();
  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 55,
        border: '',
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };

  useEffect(() => {

      const modifyRow = {
        ...selectedRow,
        partnerId: {
          label: (
            <div className="optionStyles">
              <img src={selectedRow?.partnerData?.profileImage} height="30px" width="30px" />
              <div className="selectText">{selectedRow?.partnerData?.name}</div>
            </div>
          ),
          value: selectedRow?.partnerId,
        },
      };

      setRow(modifyRow);
    
  }, [selectedRow]);
  const partnerOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_PARTNERS, { search: inputValue }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: (
          <div className="optionStyles">
            <img src={item?.profileImage} height="30px" width="30px" />
            <div className="selectText">{item.name}</div>
          </div>
        ),
        value: item?.id,
      }));

      resolve(filter);
    });
  };
  const loadPartners = debounce(partnerOptions, 300);
  const handleFormSubmit = (data, { resetForm }) => {
    setLoading(true);
    data.partnerId = data?.partnerId?.value;
    addEditApi(APIs.ADD_EDIT_PARTNER_REWARDS, {
      ...data,
    })
      .then(() => {
        toast.success(selectedRow ? 'Updated Successfully' : 'Added Successfully'), resetForm();
        setModalOpen(false);
        setRefresh((prev) => !prev);
        setSelectedRow('');
      })
      .finally(setLoading(false));
  };

  const handleImageChange = (e) => {
    if (e?.target?.files?.[0]) {
      setImage(e?.target?.files?.[0]);
    }
  };

  const uploadImage = () => {
    setImageLoading(true);
    const data = new FormData();
    data.append('file', image);
    addEditApi(APIs.UPLOAD_IMAGE, data)
      .then((result) => {
        setFieldValue('imageLink', result?.profileImg);
        setImage(null);
      })
      .finally(() => setImageLoading(false));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: selectedRow? row : initialValues,
    validationSchema: addEditPartnerRewardSchema,
    onSubmit: handleFormSubmit,
  });
  const { errors, touched, values, setFieldValue, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog open={openModal} fullWidth disableEscapeKeyDown>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>{selectedRow ? 'Edit Partner Reward' : 'Add New Partner Reward'}</DialogTitle>
          <DialogContent>
            <Grid item xs={12} md={6}>
              <AsyncSelect
                cacheOptions
                defaultOptions
                placeholder="Partner"
                styles={customStyles}
                loadOptions={loadPartners}
                value={values?.partnerId}
                onChange={(data) => {
                  setFieldValue('partnerId', data);
                }}
              />
              {Boolean(touched.partnerId && errors.partnerId) && (
                <Typography variant="caption" display="block" gutterBottom color={'red'}>
                  {errors.partnerId}
                </Typography>
              )}
            </Grid>
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              fullWidth
              margin="normal"
              label="Description"
              {...getFieldProps('description')}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Points"
              {...getFieldProps('points')}
              error={Boolean(touched.points && errors.points)}
              helperText={touched.points && errors.points}
            />

            <Grid item xs={12} md={6} marginLeft={1}>
              <Typography>Image</Typography>
              {(values?.imageLink || image) && (
                <Avatar
                  alt={'N'}
                  src={image ? URL.createObjectURL(image) : values?.imageLink}
                  sx={{ width: 100, height: 100 }}
                />
              )}
              <input type="file" accept="image/*" onChange={handleImageChange} />
              {image && (
                <>
                  {imageLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Button
                        onClick={uploadImage}
                        size="small"
                        variant="outlined"
                        startIcon={<Iconify icon="eva:save-outline" />}
                      >
                        upload Image
                      </Button>
                      <IconButton size="large" sx={{ ml: 2, color: colors.red[500] }} onClick={() => setImage(null)}>
                        <Iconify icon="eva:minus-square-fill" />
                      </IconButton>
                    </>
                  )}
                </>
              )}
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                setRow('');
                handleClose();
                formik.resetForm({
                  values: initialValues,
                });
              }}
            >
              Cancel
            </Button>

            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save Partner Reward
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};
export default AddEditModal;
