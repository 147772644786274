import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';

import { Box, Stack, CircularProgress } from '@mui/material';

const Page = forwardRef(({ children, title = '', meta, loading, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | Shotcaller`}</title>
      {meta}
    </Helmet>

    <Box ref={ref} {...other}>
      {loading ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        children
      )}
    </Box>
  </>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
