import React, { useState, useEffect } from 'react';
import { addEditApi, listApi } from 'src/apis/admin';
import Page from '../../components/Page';
import { APIs } from '../../apis';
import { handleRewardType } from '../../utils/helper';
import AsyncSelect from 'react-select/async';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Grid,
} from '@mui/material';
import { ROUTES } from 'src/router/routes';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import { UserListToolbar } from '../../sections/@dashboard/user';
import debounce from 'lodash/debounce';
import Scrollbar from '../../components/Scrollbar';
import MoreMenu from 'src/components/table/MoreMenu';
import { confirmAlert } from 'react-confirm-alert';
import { typedata } from 'src/contants/contants';
import Label from 'src/components/Label';
import ImageModal from '../openAI/questionBankList/imageModal';

const TABLE_HEAD = [
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'isActive', label: 'Reward Type Status', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'shortDescription', label: 'Short Description', alignRight: false },
  { id: 'partnerData', label: 'Partner Details', alignRight: false },
  { id: 'imageLink', label: 'Image Link', alignRight: false },
  { id: 'sponsorImageLink', label: 'Sponsor Image Link', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
];

export default function rewardtypes() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');
  const [typeFilterSelect, setTypeFilterSelect] = useState();
  const [refresh, setRefresh] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [partnerFilterSelect, setPartnerFilterSelect] = useState();
  const [imageSrc, setImageSrc] = useState(false);

  useEffect(() => {
    let data = {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      search: searchFilter,
    };

    if (typeFilterSelect) {
      data.type = typeFilterSelect?.value;
    }

    if(partnerFilterSelect) { 
      data.partnerId = partnerFilterSelect?.value;
    }

    setLoading(true);
    listApi(APIs.LIST_REWARD_TYPES, { ...data })
      .then((res) => {
        setUsers(res?.data);
        setTotalCount(res?.count);
      })
      .finally(() => setLoading(false));
  }, [rowsPerPage, searchFilter, page, typeFilterSelect, refresh, partnerFilterSelect]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  const cloneContest = (data) => {
    confirmAlert({
      title: 'Confirm to clone',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            delete data?.id;
            delete data?.createdAt;
            delete data?.updatedAt;
            addEditApi(APIs.ADD_EDIT_REWARD, data).then((result) => {
              navigate(`/reward-types/edit/${result.data?.id}`);
            });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  //Styles for filter //
  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 56,
        border: '1px solid #e0e0e0',
        // width: '240px',
        borderRadius: '8px',
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2,
      };
    },
  };

  /*Contet Filter Api  */
  const promiseTypeDataOptions = (inputValue, resolve) => {
    const filter = typedata.map((item) => ({
      label: item.reward,
      value: item.type,
    }));
    resolve(filter);
  };

  const promisePartnerDataOptions = (inputValue, resolve) => {
    listApi(APIs.LIST_PARTNERS, { search: inputValue, sendOnlyActive: true }).then((result) => {
      const filter = result?.data?.map((item) => ({
        label: (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={item?.name} src={item?.profileImage} />
            <Typography variant="subtitle2" noWrap>
              {item?.name}
            </Typography>
          </Stack>
        ),
        value: item?.id,
      }));

      resolve(filter);
    });
  };

  const loadPartnerData = debounce(promisePartnerDataOptions, 300);

  const loadTypeData = debounce(promiseTypeDataOptions, 300);

  const onRewardStatusChange = (row) => {
    const data = {
      id: row.id,
      type: 4,
      active: !row.isActive,
    };

    confirmAlert({
      title: 'Confirm for Status Change',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            addEditApi(APIs.ADD_EDIT_BLOCK, data).then((result) => setRefresh(!refresh));
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };

  return (
    <Page title="Dashboard: Reward Types">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Reward Types
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to={`${ROUTES.REWARDADD}`}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Reward
          </Button>
        </Stack>
        <Card>
          <Grid container spacing={2} padding={1}>
            <Grid>
              <UserListToolbar numSelected={selected.length} filterName={search} onFilterName={handleSearch} />
            </Grid>
            <Grid className="filterInput" xs={12} md={3.5}>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable
                placeholder="Filter by type"
                styles={customStyles}
                loadOptions={loadTypeData}
                value={typeFilterSelect}
                onChange={(data) => {
                  setTypeFilterSelect(data);
                }}
              />
            </Grid>
            <Grid className="filterInput" xs={10} md={3} style={{ marginLeft: '10px' }}>
              <AsyncSelect
                isClearable
                cacheOptions
                defaultOptions
                placeholder="Filter by partner"
                styles={customStyles}
                loadOptions={loadPartnerData}
                value={partnerFilterSelect}
                onChange={(data) => {
                  setPartnerFilterSelect(data);
                }}
              />
            </Grid>
          </Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {users.map((row, index) => {
                      const {
                        id,
                        partnerId,
                        type,
                        title,
                        shortDescription,
                        partnerData,
                        imageLink,
                        sponsorImageLink,
                        price,
                        isActive,
                      } = row;
                      const isItemSelected = selected.indexOf(title) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">{handleRewardType(type)}</TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={isActive ? 'success' : 'error'}>
                              {isActive ? 'Active' : 'InActive'}
                            </Label>
                          </TableCell>
                          <TableCell align="left">{title}</TableCell>
                          <TableCell align="left">{shortDescription}</TableCell>
                          <TableCell align="left">
                            {partnerId ? (
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Avatar alt={partnerData?.name} src={partnerData?.profileImage} />
                                <Stack>
                                  <Typography variant="subtitle2" noWrap>
                                    {partnerData?.name}
                                  </Typography>
                                </Stack>
                              </Stack>
                            ) : (
                              '-'
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar
                                alt={title}
                                src={imageLink}
                                onClick={() => showFullImage(imageLink)}
                                style={{ cursor: 'pointer' }}
                              />
                            </Stack>
                          </TableCell>

                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar
                                alt={title}
                                src={sponsorImageLink}
                                onClick={() => showFullImage(sponsorImageLink)}
                                style={{ cursor: 'pointer' }}
                              />
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{price}$</TableCell>
                          <TableCell align="right">
                            <MoreMenu
                              options={[
                                {
                                  label: 'Edit',
                                  icon: 'eva:edit-fill',
                                  onClick: () => navigate(`/reward-types/edit/${id}`),
                                },
                                { label: 'Clone', icon: 'eva:copy-outline', onClick: () => cloneContest(row) },
                                {
                                  label: isActive > 0 ? 'Inactive Reward Type' : 'Active Reward Type',
                                  icon: 'eva:edit-fill',
                                  onClick: () => onRewardStatusChange(row),
                                },
                              ]}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </Page>
  );
}
