import { LOGIN, SUCCESS, LOGOUT, REQUEST, FAIL, PARTNER_LOGIN, SET_NAV_CONFIG } from './actionTypes';
import { login, logout, partnerLogin } from '../../apis';
import { toast } from 'react-toastify';

export const handleLogin = (payload) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN[REQUEST],
    });
    login(payload)
      .then((res) => {
        toast.success('Login successful!');
        dispatch({
          type: LOGIN[SUCCESS],
          data: res?.data,
        });
      })
      .catch(() => {
        dispatch({
          type: LOGIN[FAIL],
        });
      });
  };
};
export const handlePartnerLogin = (payload) => {
  return (dispatch) => {
    dispatch({
      type: PARTNER_LOGIN[REQUEST],
    });
    partnerLogin(payload)
      .then((res) => {
        toast.success('Partner Login successful!');
        dispatch({
          type: PARTNER_LOGIN[SUCCESS],
          data: res?.data,
        });
      })
      .catch(() => {
        dispatch({
          type: PARTNER_LOGIN[FAIL],
        });
      });
  };
};

export const setNavConfig = (navConfig) => ({
  type: SET_NAV_CONFIG,
  payload: navConfig,
});

export const handleLogout = (auto) => {
  return (dispatch) => {
    if (auto) {
      dispatch({
        type: LOGOUT[SUCCESS],
      });
    } else {
      logout()
        .then((res) => {
          toast.success('Logout successful!');
          dispatch({
            type: LOGOUT[SUCCESS],
          });
        })
        .catch((err) =>
          dispatch({
            type: LOGOUT[FAIL],
          })
        );
    }
  };
};
