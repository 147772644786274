import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  IconButton,
  InputAdornment,
  Avatar,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import { UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import { useFormik, Form, FormikProvider } from 'formik';
import { addEditApi, listApi } from 'src/apis/admin';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { APIs } from '../../apis';
import { addEditAdminSchema } from 'src/utils/validationSchemas';
import MoreMenu from '../../components/table/MoreMenu';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { DATE_FORMAT, INITIAL_SUB_ADMIN_ACCESS } from '../../contants/contants';
import { useSelector } from 'react-redux';

const TABLE_HEAD = [
  { id: 'name', label: 'Username', alignRight: false },
  { id: 'emailId', label: 'Email', alignRight: false },
  { id: 'createdAt', label: 'Created at', alignRight: false },
  { id: 'active', label: 'Status', alignRight: false },
  { id: '' },
];

export default function Admins() {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [openModal, setModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [editData, setEditData] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [accessPermissions, setAccessPermissions] = useState(INITIAL_SUB_ADMIN_ACCESS);

  const user = useSelector((state) => state.auth);
  user && user.roles && user.roles.some((role) => role?.title === 'Admins' && role?.edit === true);

  const handleDeletePermission =
    user && user.roles && user.roles.some((role) => role?.title === 'Admins' && role?.delete === true);

  const isSuperAdmin = user?.isSuperAdmin;

  const ActionMenu = ({ row }) => {
    const handleEditPermission =
      user && user.roles && user.roles.some((role) => role?.title === 'Admins' && role?.edit === true);

    const handleDeletePermission =
      user && user.roles && user.roles.some((role) => role?.title === 'Admins' && role?.delete === true);

    const isSuperAdmin = user?.isSuperAdmin;

    const options = [];
    if (handleEditPermission || isSuperAdmin) {
      options.push({
        label: 'Edit',
        icon: 'eva:edit-fill',
        onClick: () => {
          openEditModal(row);
        },
      });
    }
    if (handleDeletePermission || isSuperAdmin) {
      options.push({
        label: 'Delete',
        icon: 'eva:trash-2-outline',
        onClick: () => deleteAdmin(row),
      });
    }
    return <TableCell align="right">{options.length > 0 && <MoreMenu options={options} />}</TableCell>;
  };

  useEffect(() => {
    if (!!isEditMode && editData?.roles?.length) {
      setAccessPermissions(editData?.roles);
    }
  }, [isEditMode, editData]);

  useEffect(() => {
    if (!isEditMode) {
      const newData = accessPermissions.map((access) => ({
        ...access,
        read: false,
        edit: false,
        delete: false,
      }));
      setAccessPermissions(newData);
    }
  }, [openModal, isEditMode]);

  useEffect(() => {
    listApi(APIs.LIST_ADMINS, { skip: page * rowsPerPage, limit: rowsPerPage, search: searchFilter }).then((res) => {
      setUsers(res?.data);
      setTotalCount(res?.count);
    });
  }, [refresh, rowsPerPage, searchFilter, page, success]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  const handleClose = (_, reason) => {
    if (reason !== 'backdropClick') {
      setModalOpen(false);
    }
  };

  const handleFormSubmit = (data, { resetForm }) => {
    setLoading(true);

    let params = {
      ...data,
      roles: accessPermissions,
    };
    if (editData?.id) {
      params.id = editData?.id;
    }
    addEditApi(APIs.ADD_EDIT_ADMIN, params)
      .then(() => {
        setModalOpen(false);
        setRefresh((prev) => !prev);
        resetForm();
      })
      .finally(() => {
        setEditData(null);
        setLoading(false);
        setIsEditMode(false);
      });
  };

  const deleteAdmin = (row) => {
    const data = { id: row.id, type: 1 };
    confirmAlert({
      title: 'Confirm for Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            addEditApi(APIs.DELETE_REQUEST, data).then((result) => {
              setSuccess((prev) => !prev);
              toast.success('Deleted Successfully');
            });
          },
        },
        { label: 'No' },
      ],
    });
  };

  const openEditModal = (row) => {
    setEditData(row);
    setModalOpen(true);
    setIsEditMode(true);
  };

  const formik = useFormik({
    initialValues: isEditMode
      ? editData
      : {
          emailId: '',
          password: '',
          name: '',
        },
    validationSchema: addEditAdminSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const handleReadChange = (name) => {
    setAccessPermissions((prev) => prev.map((item) => (item.name === name ? { ...item, read: !item.read } : item)));
  };

  const handleEditChange = (name) => {
    setAccessPermissions((prev) =>
      prev.map((item) => (item.name === name ? { ...item, edit: !item.edit, read: item.read || !item.read } : item))
    );
  };

  const handleDeleteChange = (name) => {
    setAccessPermissions((prev) =>
      prev.map((item) => (item.name === name ? { ...item, delete: !item.delete, read: item.read || !item.read } : item))
    );
  };

  const handleNameChange = (event) => {
    const newName = event.target.value;
    setFieldValue('name', newName);
    setAccessPermissions(INITIAL_SUB_ADMIN_ACCESS(newName));
  };
  return (
    <Page title="Admins">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Admins
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              setModalOpen(true);
              setIsEditMode(false);
            }}
          >
            New Admin
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={search} onFilterName={handleSearch} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                        <TableSortLabel hideSortIcon active={false} direction={'asc'}>
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {users.map((row, index) => {
                    const { id, name, emailId, createdAt, active } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{emailId}</TableCell>
                        <TableCell align="left">{moment(createdAt).format(DATE_FORMAT)}</TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={active !== 1 ? 'error' : 'success'}>
                            {active !== 1 ? 'InActive' : 'Active'}
                          </Label>
                        </TableCell>

                        <TableRow>
                          <ActionMenu row={row} />
                        </TableRow>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>

      <Dialog open={openModal} onClose={handleClose} fullWidth>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>{isEditMode ? 'Edit Admin' : 'Add Admin'}</DialogTitle>
            <DialogContent>
              <div>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Email address"
                  {...getFieldProps('emailId')}
                  error={Boolean(touched.emailId && errors.emailId)}
                  helperText={touched.emailId && errors.emailId}
                />
              </div>
              {!isEditMode && (
                <div>
                  <TextField
                    fullWidth
                    margin="normal"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    {...getFieldProps('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword((show) => !show)} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </div>
              )}
            </DialogContent>
            <div style={{ padding: '0px 24px' }}>
              <TableContainer>
                <Table sx={{ minWidth: 550 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ACCESS</TableCell>
                      <TableCell align="right">
                        <Checkbox
                          onChange={(e) => {
                            const newData = accessPermissions.map((access) => ({
                              ...access,
                              read: e.target.checked,
                            }));
                            setAccessPermissions(newData);
                          }}
                        />{' '}
                        READ
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          onChange={(e) => {
                            const newData = accessPermissions.map((data) => ({
                              ...data,
                              edit: e.target.checked,
                            }));
                            setAccessPermissions(newData);
                          }}
                        />
                        ADD/EDIT
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox
                          onChange={(e) => {
                            const newData = accessPermissions.map((select) => ({
                              ...select,
                              delete: e.target.checked,
                            }));
                            setAccessPermissions(newData);
                          }}
                        />
                        DELETE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {accessPermissions.map((access) => (
                    <TableRow key={access.id}>
                      <TableCell component="th" scope="row">
                        {access.title}
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox checked={access.read} onChange={() => handleReadChange(access.name)} />
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox checked={access.edit} onChange={() => handleEditChange(access.name)} />
                      </TableCell>
                      <TableCell align="right">
                        <Checkbox checked={access.delete} onChange={() => handleDeleteChange(access.name)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </TableContainer>
            </div>

            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>

              <LoadingButton type="submit" variant="contained" loading={loading}>
                Save Admin
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </Page>
  );
}
